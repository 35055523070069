import { LanguageEnum } from 'api/CailagateApi/api/client';
import mapObjIndexed from 'ramda/src/mapObjIndexed';

export const getUserLanguage = (userCountryIsoCode?: LanguageEnum): LanguageEnum => {
  if (userCountryIsoCode) {
    return userCountryIsoCode;
  }
  const savedLanguage = localStorage.getItem('USER_LANGUAGE')?.toUpperCase();
  if (Boolean(savedLanguage) && Object.values(LanguageEnum).includes(savedLanguage as LanguageEnum)) {
    return savedLanguage as LanguageEnum;
  }

  let lang = window.navigator.language.split('-')[0];

  switch (lang) {
    case 'ru': {
      return LanguageEnum.RU;
    }
    //TODO: add when these languages will be supported
    // case 'cn':
    // case 'zh': {
    //   return 'cn';
    // }
    default:
      return LanguageEnum.EN;
  }
};

export function generateUniqString(): string {
  return Math.random().toString(36).substr(2, 5);
}

export function provideRef(...to: ({ current: any } | ((ref: any) => void) | null | undefined)[]) {
  return function (ref: any) {
    to.forEach(toElement => {
      if (!toElement) return;
      if (isFunction(toElement)) {
        toElement(ref);
        return;
      }
      toElement.current = ref;
    });
  };
}

export function isFunction(arg: any): arg is Function {
  return typeof arg === 'function';
}

export function formatJSONSafe(text: string) {
  let payload = {};
  try {
    payload = JSON.parse(text);
  } catch (e) {
    return 'JSON parse error';
  }
  return objToJSON(payload);
}

export function objToJSON(obj: object, options?: { withFormatting: boolean }): string {
  const innerOptions = {
    withFormatting: true,
    ...options,
  };
  if (innerOptions.withFormatting) {
    return JSON.stringify(obj, null, 2);
  }
  return JSON.stringify(obj);
}

export function isDev(): boolean {
  return (window as any)?.isDev || process.env.NODE_ENV === 'development';
}
export function isProd(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isNumber(str: string) {
  return !isNaN(parseInt(str));
}

export function parseIntOrUndefined(str: string) {
  const result = parseInt(str);
  return !isNaN(result) ? result : undefined;
}

export function parseFloatOrUndefined(str: string) {
  const result = parseFloat(str);
  return !isNaN(result) ? result : undefined;
}

export const prettifyJSON = (jsonString: string): string => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
  return JSON.stringify(parsedJson, null, 2);
};

export function isNullOrUndefined(input: any) {
  return input === null || input === undefined;
}

export function parseError(error: any): string {
  let errorMessage = (error?.message as string) || 'Error!';
  if (error?.response?.data?.message) {
    errorMessage += '\nError message: ' + error.response.data.message;
  }
  return errorMessage;
}

export function isDevEnvironment() {
  return !window.location.hostname.includes('caila.io');
}

export function isImmersEnv() {
  return window.location.hostname.includes('caila.immers');
}

export function isMlpTovieEnv() {
  return window.location.hostname.includes('mlp.tovie.ai');
}

export function downloadFileFromUrl(url: string, fileName: string = 'newFile.json') {
  var a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadFile(file: BlobPart, fileName: string = 'newFile.json', type?: string) {
  const blob = new Blob([file], { type });
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
}

export function downloadBase64File(base64File: string, fileName: string = 'newFile.json', type?: string) {
  const buffer = decodeBase64ToArrayBuffer(base64File);
  const blob = new Blob([buffer], { type });
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
}

export function downloadFiles(files: BlobPart[] | BlobPart, fileName: string = 'newFile.json', type?: string) {
  const blob = new Blob(Array.isArray(files) ? files : [files], { type: type });
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
}

export function objectFieldsToString(item: { [key: string]: any }) {
  const toString = (value: any) => value.toString();
  return mapObjIndexed(toString, item);
}

export const getFilenameFromContentDisposition = (headers: any) =>
  (headers['content-disposition'] as string)?.split('filename=')?.[1]?.trim();

export function decodeBase64ToArrayBuffer(base64String: string): ArrayBuffer {
  const binaryString = window.atob(base64String);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export function encodeBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const base64String = fileReader.result as string;
      resolve(base64String.split(',')[1]);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(blob);
  });
}

export function stringToBoolean(value: string): boolean {
  return value.toLowerCase() === 'true';
}

export function objectToFile(
  value: object,
  filename: string = 'newFile.json',
  type: string = 'application/json'
): File | undefined {
  return stringToFile(JSON.stringify(value), filename, type);
}

export function stringToFile(
  text: string,
  filename: string = 'newFile.json',
  type: string = 'application/json'
): File | undefined {
  try {
    const blob = new Blob([text], { type });
    const file = new File([blob], filename, { type });

    return file;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
