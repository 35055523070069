import React from 'react';
import Container from 'typedi';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { Icon } from '@just-ai/just-ui/dist/Icon';

import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

import { useBalanceContext } from 'contexts/BalanceContext';
import { useAuthContext } from 'contexts/AuthContext';

import { FEATURES } from 'contexts/AuthContext/constants';
import { formatNumberWithPrecision, roundNumberWithPrecision } from 'utils/numbers';
import { tokens } from 'diConfig';
import { t } from 'localization';

import styles from './styles.module.scss';

const DECIMAL_PLACES = 2;
const MANAGER_MAIL_LINK = Container.get(tokens.MANAGER_MAIL_LINK);

const BalanceInfo = () => {
  const { balanceInfo, openBalanceRefillModal, isBillingEnabled, currencyType, refillByManager } = useBalanceContext();
  const { userHasFeature } = useAuthContext();
  const showFromIncomeBalance = userHasFeature(FEATURES.MLP_PUBLIC_MODEL_ADMIN);

  if (!isBillingEnabled || !balanceInfo) return null;

  const showJaicpBalance = roundNumberWithPrecision(balanceInfo.jaicpTariffBalanceCurrency || 0, DECIMAL_PLACES) > 0;
  const showNextRefillCurrency = roundNumberWithPrecision(balanceInfo.nextRefillCurrency || 0, DECIMAL_PLACES) > 0;

  return (
    <Dropdown
      labelClassName={styles.balance__chip}
      labelRender={() => (
        <>
          <Icon name='farCreditCard' />
          <span>
            {t(`Header:BalanceInfo:OverallBalance:${currencyType}`, {
              count: roundNumberWithPrecision(balanceInfo.overallBalanceCurrency || 0, DECIMAL_PLACES),
            })}
          </span>
        </>
      )}
      contentRender={() => (
        <div className={styles.balance__dropdownContent}>
          {refillByManager ? (
            <div className={cn(styles.balance__dropdownContentItem, styles.balance__managerMailLink)}>
              <span>
                {t('Header:BalanceInfo:Message:TopUpBalanceByManager') + ' '}
                <a
                  data-test-id='Header:BalanceInfoDropdown:ManagerMailLink'
                  className={styles.balance__mailLink}
                  href={`mailto:${MANAGER_MAIL_LINK}`}
                >
                  <b>{MANAGER_MAIL_LINK}</b>
                </a>
              </span>
            </div>
          ) : (
            <>
              <div className={styles.balance__dropdownContentItem}>
                <span>{t('Header:BalanceInfo:MainCailaBalance')}</span>
                <span>
                  <b>
                    {t(`Header:BalanceInfo:MainBalance:${currencyType}`, {
                      count: roundNumberWithPrecision(balanceInfo.overallBalanceCurrency || 0, DECIMAL_PLACES),
                    })}
                  </b>
                </span>
              </div>
              {(showJaicpBalance || (balanceInfo.nextRefillDate && showNextRefillCurrency)) && (
                <div className={styles['balance__dropdownContentItem-colored']}>
                  {showJaicpBalance && (
                    <>
                      <span>{t('Header:BalanceInfo:JaicpTariff')}</span>
                      <span>
                        <b>
                          {t(`Header:BalanceInfo:JaicpTariffBalance:${currencyType}`, {
                            count: roundNumberWithPrecision(
                              balanceInfo.jaicpTariffBalanceCurrency || 0,
                              DECIMAL_PLACES
                            ),
                          })}
                        </b>
                      </span>
                    </>
                  )}
                  {balanceInfo.nextRefillDate && showNextRefillCurrency ? (
                    <small>
                      <span>
                        {t(`Header:BalanceInfo:NextRefill:${currencyType}`, {
                          date: DateTime.fromJSDate(new Date(balanceInfo.nextRefillDate)).toFormat('dd.MM'),
                          count: balanceInfo.nextRefillCurrency,
                        })}
                      </span>
                    </small>
                  ) : null}
                </div>
              )}
              {showFromIncomeBalance && (
                <div className={styles.balance__dropdownContentItem}>
                  <span>{t('Header:BalanceInfo:IncomeBalance')}</span>
                  <span>
                    <b>{`${formatNumberWithPrecision(balanceInfo.incomeBalanceCurrency || 0, DECIMAL_PLACES)} ₽`}</b>
                  </span>
                </div>
              )}
              <Button
                data-test-id='Header:BalanceInfoDropdown:OpenBalanceRefillModal'
                color='primary'
                onClick={openBalanceRefillModal}
              >
                {t('Header:BalanceInfo:RefillBalance')}
              </Button>
            </>
          )}
        </div>
      )}
    />
  );
};

export default React.memo(BalanceInfo);
