import React, { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';
import BalanceInfoBanner from './components/BalanceInfoBanner';

import { useBalanceRefillModal } from './components/BalanceRefillModal';
import { useUserInfoBalance } from './hooks';
import useLongPolling from 'hooks/useLongPolling';

import { RefillFromCardResponse, ShortBalance } from 'api/CailaBillingApi/api/client';
import { useAppContext } from 'contexts/AppContext';
import { FrontendSettings } from 'api/CailagateApi/api/client';

const LONG_POLLING_INTERVAL = 5000;

//TODO: possible reuse useUserInfoBalance interface
export type BalanceContextType = {
  balanceInfo?: ShortBalance;
  getBalance: () => Promise<void>;
  refillFromCard: (amountCurrency: number, redirectUrl: string) => Promise<RefillFromCardResponse | undefined>;
  refillFromIncomeBalance: (amountCurrency: number) => Promise<void>;
  openBalanceRefillModal: () => void;
  checkIfUserIsBlocked: () => Promise<void>;
  isBillingEnabled: FrontendSettings['isBillingEnabled'];
  currencyType: FrontendSettings['currencyType'];
  refillByManager: FrontendSettings['refillByManager'];
  isUserBlocked: boolean;
  isUserBalanceBelowZero: boolean;
};

export const BalanceContext = createContext({} as BalanceContextType);

interface BalanceContextProviderProps {}

export const BalanceContextProviderComponent: FunctionComponent<BalanceContextProviderProps> = ({ children }) => {
  const {
    checkIfUserIsBlocked,
    getBalance,
    refillFromCard,
    refillFromIncomeBalance,
    state: { isUserBlocked, balanceInfo, isBillingEnabled, refillByManager, isUserBalanceBelowZero },
    isLoading,
  } = useUserInfoBalance();
  const { currencyType } = useAppContext();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    Promise.allSettled([checkIfUserIsBlocked(), getBalance()]).finally(() => {
      setIsInitialized(true);
    });
  }, [checkIfUserIsBlocked, getBalance, isBillingEnabled, setIsInitialized]);

  useLongPolling(getBalance, LONG_POLLING_INTERVAL);

  const [BalanceRefillModalNode, openBalanceRefillModal] = useBalanceRefillModal();

  const handleOpenBalanceRefillModal = useCallback(() => {
    isBillingEnabled && !refillByManager && openBalanceRefillModal();
  }, [isBillingEnabled, openBalanceRefillModal, refillByManager]);

  if (!isInitialized) return <Spinner show />;

  return (
    <BalanceContext.Provider
      value={{
        balanceInfo,
        getBalance,
        refillFromCard,
        refillFromIncomeBalance,
        openBalanceRefillModal: handleOpenBalanceRefillModal,
        checkIfUserIsBlocked,
        isBillingEnabled,
        currencyType,
        refillByManager,
        isUserBlocked,
        isUserBalanceBelowZero,
      }}
    >
      <Spinner show={isLoading} />
      {children}
      <BalanceInfoBanner />
      {isBillingEnabled && BalanceRefillModalNode}
    </BalanceContext.Provider>
  );
};

export const useBalanceContext = () => useContext(BalanceContext);
