import { useCallback, useEffect, useRef } from 'react';

interface LongPollingOptions {
  deps?: any[];
  shouldWatchCallback?: boolean;
  disabled?: boolean;
}

export const useLongPolling = (callback: () => Promise<any>, delay: number, options?: LongPollingOptions) => {
  const { deps = [], shouldWatchCallback = true, disabled = false } = options || {};

  const dependencies = shouldWatchCallback ? [...deps, callback] : deps;

  const requestRef = useRef<Promise<any> | undefined>();
  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearTimeoutRef = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  }, []);

  const getDebouncedCallback = useCallback(() => {
    let isActive = true;
    const cancel = () => {
      isActive = false;
    };

    const debouncedCallback = () => {
      timeoutIdRef.current = undefined;
      if (!isActive || requestRef.current) {
        return cancel;
      }
      requestRef.current = callback().finally(() => {
        requestRef.current = undefined;
        timeoutIdRef.current = setTimeout(() => {
          debouncedCallback();
        }, delay);
      });
      return cancel;
    };
    return debouncedCallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, delay]);

  useEffect(() => {
    let cancel: () => void;
    if (!disabled) {
      cancel = getDebouncedCallback()();

      return () => {
        clearTimeoutRef();
        requestRef.current = undefined;
        cancel?.();
      };
    }
    return () => {
      clearTimeoutRef();
      requestRef.current = undefined;
      cancel?.();
    };
  }, [clearTimeoutRef, disabled, getDebouncedCallback]);

  return requestRef.current;
};

export default useLongPolling;
