import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import './App.scss';
import { AppRouter } from './routes';
import { AppContextProviderComponent } from './contexts/AppContext';
import AuthContextProviderComponent from './contexts/AuthContext';

import Header from './modules/Header';
import { ModalsContextProviderComponent } from 'contexts/ModalsContext';
import { ServicesContextProviderComponent } from 'contexts/ServicesContext';
import { BalanceContextProviderComponent } from 'contexts/BalanceContext';
import { isDev } from 'utils';
import ErrorBoundary from 'components/ErrorBoundary';

export const history = createBrowserHistory();

const hostname = isDev() ? 'caila.io' : window.location.hostname;
const match = hostname?.match(/^mlp\.(.*?)\/?$/);
export const documentationLink = `${window.location.protocol}//docs.${match?.[1] || hostname}`;

export default function App() {
  return (
    <ErrorBoundary prefix='GlobalRoot'>
      <AppContextProviderComponent>
        <AuthContextProviderComponent>
          <BalanceContextProviderComponent>
            <ModalsContextProviderComponent>
              <ServicesContextProviderComponent>
                <Router history={history}>
                  <main className='page-container'>
                    <Header />
                    <AppRouter />
                  </main>
                </Router>
              </ServicesContextProviderComponent>
            </ModalsContextProviderComponent>
          </BalanceContextProviderComponent>
        </AuthContextProviderComponent>
      </AppContextProviderComponent>
    </ErrorBoundary>
  );
}
