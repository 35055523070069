// @ts-nocheck
// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessTokenData
 */
export interface AccessTokenData {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenData
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenData
     */
    creationDate: string;
}
/**
 * 
 * @export
 * @interface AccountConfigDump
 */
export interface AccountConfigDump {
    /**
     * 
     * @type {string}
     * @memberof AccountConfigDump
     */
    accountName: string;
    /**
     * 
     * @type {AccountLimitsData}
     * @memberof AccountConfigDump
     */
    accountLimits?: AccountLimitsData;
    /**
     * 
     * @type {string}
     * @memberof AccountConfigDump
     */
    language?: LanguageEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountConfigDump
     */
    tags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AccountConfigDump
     */
    accountDisplayName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LanguageEnum {
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface AccountDataDump
 */
export interface AccountDataDump {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDataDump
     */
    apiTokens?: Array<string>;
    /**
     * 
     * @type {Array<ModelGroupDump>}
     * @memberof AccountDataDump
     */
    modelGroups?: Array<ModelGroupDump>;
    /**
     * 
     * @type {Array<ImageDump>}
     * @memberof AccountDataDump
     */
    images?: Array<ImageDump>;
    /**
     * 
     * @type {Array<DataImageDump>}
     * @memberof AccountDataDump
     */
    dataImages?: Array<DataImageDump>;
    /**
     * 
     * @type {Array<ModelDump>}
     * @memberof AccountDataDump
     */
    models?: Array<ModelDump>;
    /**
     * 
     * @type {Array<ServerTemplateDump>}
     * @memberof AccountDataDump
     */
    serverTemplates?: Array<ServerTemplateDump>;
}
/**
 * 
 * @export
 * @interface AccountFeatureData
 */
export interface AccountFeatureData {
    /**
     * 
     * @type {Array<FeatureData>}
     * @memberof AccountFeatureData
     */
    features: Array<FeatureData>;
}
/**
 * 
 * @export
 * @interface AccountInfoData
 */
export interface AccountInfoData {
    /**
     * 
     * @type {number}
     * @memberof AccountInfoData
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoData
     */
    shortName: string;
    /**
     * 
     * @type {AccountLimitsData}
     * @memberof AccountInfoData
     */
    limits: AccountLimitsData;
    /**
     * 
     * @type {string}
     * @memberof AccountInfoData
     */
    language?: LanguageEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountInfoData
     */
    tags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AccountInfoData
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountInfoData
     */
    lastActivity?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LanguageEnum {
    RU = 'RU',
    EN = 'EN'
}

/**
 * 
 * @export
 * @interface AccountLimitsData
 */
export interface AccountLimitsData {
    /**
     * 
     * @type {number}
     * @memberof AccountLimitsData
     */
    baseModelInstanceLimit: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLimitsData
     */
    derivedModelInstanceLimit: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLimitsData
     */
    gpuModelInstanceLimit: number;
    /**
     * 
     * @type {string}
     * @memberof AccountLimitsData
     */
    cpuLimit: string;
    /**
     * 
     * @type {string}
     * @memberof AccountLimitsData
     */
    memoryLimit: string;
    /**
     * 
     * @type {string}
     * @memberof AccountLimitsData
     */
    ephemeralDiskLimit: string;
    /**
     * 
     * @type {number}
     * @memberof AccountLimitsData
     */
    predictPerSec: number;
    /**
     * 
     * @type {number}
     * @memberof AccountLimitsData
     */
    fitPerMinute: number;
}
/**
 * 
 * @export
 * @interface AliasData
 */
export interface AliasData {
    /**
     * 
     * @type {number}
     * @memberof AliasData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AliasData
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AudioFormatOptions
 */
export interface AudioFormatOptions {
    /**
     * 
     * @type {string}
     * @memberof AudioFormatOptions
     */
    audioEncoding?: AudioEncodingEnum;
    /**
     * 
     * @type {number}
     * @memberof AudioFormatOptions
     */
    sampleRateHertz?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioFormatOptions
     */
    chunkSizeKb?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AudioEncodingEnum {
    LINEAR16PCM = 'LINEAR16_PCM'
}

/**
 * 
 * @export
 * @interface CaptchaData
 */
export interface CaptchaData {
    /**
     * 
     * @type {boolean}
     * @memberof CaptchaData
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CaptchaData
     */
    siteKey: string;
}
/**
 * 
 * @export
 * @interface CatalogCategoryData
 */
export interface CatalogCategoryData {
    /**
     * 
     * @type {Array<TaskTypeToModelsCount>}
     * @memberof CatalogCategoryData
     */
    categories: Array<TaskTypeToModelsCount>;
}
/**
 * 
 * @export
 * @interface CheckResult
 */
export interface CheckResult {
    /**
     * 
     * @type {string}
     * @memberof CheckResult
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckResult
     */
    critical: boolean;
    /**
     * 
     * @type {Status}
     * @memberof CheckResult
     */
    status: Status;
}
/**
 * 
 * @export
 * @interface ClickHistoryData
 */
export interface ClickHistoryData {
    /**
     * 
     * @type {number}
     * @memberof ClickHistoryData
     */
    creationTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ClickHistoryData
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClickHistoryData
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClickHistoryData
     */
    entityType?: string;
    /**
     * 
     * @type {string}
     * @memberof ClickHistoryData
     */
    pageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ClickHistoryData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ClickHistoryData
     */
    eventType: string;
    /**
     * 
     * @type {number}
     * @memberof ClickHistoryData
     */
    elementId?: number;
}
/**
 * 
 * @export
 * @interface ConfigCreateUpdateData
 */
export interface ConfigCreateUpdateData {
    /**
     * 
     * @type {string}
     * @memberof ConfigCreateUpdateData
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigCreateUpdateData
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigCreateUpdateData
     */
    config: string;
}
/**
 * 
 * @export
 * @interface CopyResourceGroupServer
 */
export interface CopyResourceGroupServer {
    /**
     * 
     * @type {string}
     * @memberof CopyResourceGroupServer
     */
    serverName: string;
    /**
     * 
     * @type {number}
     * @memberof CopyResourceGroupServer
     */
    maxServerLifetimeMinutes?: number;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateDatasetInfoData
 */
export interface CreateOrUpdateDatasetInfoData {
    /**
     * 
     * @type {DatasetPK}
     * @memberof CreateOrUpdateDatasetInfoData
     */
    id?: DatasetPK;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateDatasetInfoData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateDatasetInfoData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateDatasetInfoData
     */
    dataType?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateDatasetInfoData
     */
    accessMode?: AccessModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface CreateResourceGroupData
 */
export interface CreateResourceGroupData {
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateResourceGroupData
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateResourceGroupData
     */
    enabledEviction?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupData
     */
    access?: AccessEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupData
     */
    resourceGroupType?: ResourceGroupTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateResourceGroupData
     */
    enabledAutoScaling?: boolean;
    /**
     * 
     * @type {ResourceGroupAutoScalingConfiguration}
     * @memberof CreateResourceGroupData
     */
    autoScalingConfiguration?: ResourceGroupAutoScalingConfiguration;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    SHAREDPOOL = 'SHARED_POOL'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourceGroupTypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}

/**
 * 
 * @export
 * @interface CreateResourceGroupQuota
 */
export interface CreateResourceGroupQuota {
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupQuota
     */
    cpuLimit: string;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupQuota
     */
    memoryLimit: string;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupQuota
     */
    ephemeralDiskLimit: string;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupQuota
     */
    gpuInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupQuota
     */
    baseInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupQuota
     */
    derivedInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupQuota
     */
    accessibleOnlyForUser?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateResourceGroupQuota
     */
    accessibleForEveryone: boolean;
}
/**
 * 
 * @export
 * @interface CreateResourceGroupServer
 */
export interface CreateResourceGroupServer {
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupServer
     */
    serverName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupServer
     */
    rawConfiguration: string;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupServer
     */
    tarifficationPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateResourceGroupServer
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateResourceGroupServer
     */
    maxServerLifetimeMinutes?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface CreateUpdateServerTemplateData
 */
export interface CreateUpdateServerTemplateData {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateServerTemplateData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateServerTemplateData
     */
    type: TypeEnum;
    /**
     * 
     * @type {ServerCapacityData}
     * @memberof CreateUpdateServerTemplateData
     */
    capacity?: ServerCapacityData;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateServerTemplateData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateServerTemplateData
     */
    rawConfiguration: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateServerTemplateData
     */
    tarifficationPrice: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateServerTemplateData
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}
/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface CrossValidationRequestData
 */
export interface CrossValidationRequestData {
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    baseModelAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    baseModelId?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    targetModelAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof CrossValidationRequestData
     */
    targetModelName?: string;
    /**
     * 
     * @type {object}
     * @memberof CrossValidationRequestData
     */
    fitConfig?: object;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    fitConfigId?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    datasetId: number;
    /**
     * 
     * @type {object}
     * @memberof CrossValidationRequestData
     */
    predictConfig?: object;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    predictConfigId?: number;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    parts: number;
    /**
     * 
     * @type {number}
     * @memberof CrossValidationRequestData
     */
    minIntentSizeToDivide: number;
}
/**
 * 
 * @export
 * @interface DataImageDump
 */
export interface DataImageDump {
    /**
     * 
     * @type {string}
     * @memberof DataImageDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageDump
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageDump
     */
    dataPath: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageDump
     */
    accessMode?: AccessModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface DataImageInfoData
 */
export interface DataImageInfoData {
    /**
     * 
     * @type {DataImageInfoPK}
     * @memberof DataImageInfoData
     */
    id?: DataImageInfoPK;
    /**
     * 
     * @type {string}
     * @memberof DataImageInfoData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageInfoData
     */
    imageAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageInfoData
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageInfoData
     */
    sourcePath: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageInfoData
     */
    accessMode?: AccessModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface DataImageInfoPK
 */
export interface DataImageInfoPK {
    /**
     * 
     * @type {number}
     * @memberof DataImageInfoPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof DataImageInfoPK
     */
    imageId: number;
}
/**
 * 
 * @export
 * @interface DataImageMountData
 */
export interface DataImageMountData {
    /**
     * 
     * @type {number}
     * @memberof DataImageMountData
     */
    dmId?: number;
    /**
     * 
     * @type {number}
     * @memberof DataImageMountData
     */
    dataImageAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof DataImageMountData
     */
    dataImageId: number;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountData
     */
    dataImage?: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountData
     */
    dataImageName?: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountData
     */
    sourcePath?: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountData
     */
    targetPath: string;
}
/**
 * 
 * @export
 * @interface DataImageMountDump
 */
export interface DataImageMountDump {
    /**
     * 
     * @type {string}
     * @memberof DataImageMountDump
     */
    imageAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountDump
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof DataImageMountDump
     */
    targetPath: string;
}
/**
 * 
 * @export
 * @interface DatasetInfoData
 */
export interface DatasetInfoData {
    /**
     * 
     * @type {DatasetPK}
     * @memberof DatasetInfoData
     */
    id?: DatasetPK;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    datasetAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    dataType?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    accessMode?: AccessModeEnum;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfoData
     */
    warning?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface DatasetPK
 */
export interface DatasetPK {
    /**
     * 
     * @type {number}
     * @memberof DatasetPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetPK
     */
    datasetId: number;
}
/**
 * 
 * @export
 * @interface DeprecatedDatasetInfoWithContentData
 */
export interface DeprecatedDatasetInfoWithContentData {
    /**
     * 
     * @type {string}
     * @memberof DeprecatedDatasetInfoWithContentData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedDatasetInfoWithContentData
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedDatasetInfoWithContentData
     */
    dataType?: string;
    /**
     * 
     * @type {string}
     * @memberof DeprecatedDatasetInfoWithContentData
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface DifferenceIAccountConfigDump
 */
export interface DifferenceIAccountConfigDump {
    /**
     * 
     * @type {Array<DifferenceIAccountDataDumpErrors>}
     * @memberof DifferenceIAccountConfigDump
     */
    errors: Array<DifferenceIAccountDataDumpErrors>;
    /**
     * 
     * @type {AccountConfigDump}
     * @memberof DifferenceIAccountConfigDump
     */
    before?: AccountConfigDump;
    /**
     * 
     * @type {AccountConfigDump}
     * @memberof DifferenceIAccountConfigDump
     */
    after?: AccountConfigDump;
}
/**
 * 
 * @export
 * @interface DifferenceIAccountDataDump
 */
export interface DifferenceIAccountDataDump {
    /**
     * 
     * @type {Array<DifferenceIAccountDataDumpErrors>}
     * @memberof DifferenceIAccountDataDump
     */
    errors: Array<DifferenceIAccountDataDumpErrors>;
    /**
     * 
     * @type {AccountDataDump}
     * @memberof DifferenceIAccountDataDump
     */
    before?: AccountDataDump;
    /**
     * 
     * @type {AccountDataDump}
     * @memberof DifferenceIAccountDataDump
     */
    after?: AccountDataDump;
}
/**
 * 
 * @export
 * @interface DifferenceIAccountDataDumpCause
 */
export interface DifferenceIAccountDataDumpCause {
    /**
     * 
     * @type {Array<DifferenceIAccountDataDumpCauseStackTrace>}
     * @memberof DifferenceIAccountDataDumpCause
     */
    stackTrace?: Array<DifferenceIAccountDataDumpCauseStackTrace>;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCause
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCause
     */
    localizedMessage?: string;
}
/**
 * 
 * @export
 * @interface DifferenceIAccountDataDumpCauseStackTrace
 */
export interface DifferenceIAccountDataDumpCauseStackTrace {
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    classLoaderName?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    moduleVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    methodName?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    lineNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    className?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DifferenceIAccountDataDumpCauseStackTrace
     */
    nativeMethod?: boolean;
}
/**
 * 
 * @export
 * @interface DifferenceIAccountDataDumpErrors
 */
export interface DifferenceIAccountDataDumpErrors {
    /**
     * 
     * @type {DifferenceIAccountDataDumpCause}
     * @memberof DifferenceIAccountDataDumpErrors
     */
    cause?: DifferenceIAccountDataDumpCause;
    /**
     * 
     * @type {Array<DifferenceIAccountDataDumpCauseStackTrace>}
     * @memberof DifferenceIAccountDataDumpErrors
     */
    stackTrace?: Array<DifferenceIAccountDataDumpCauseStackTrace>;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpErrors
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DifferenceIAccountDataDumpErrors
     */
    localizedMessage?: string;
}
/**
 * 
 * @export
 * @interface DocumentDump
 */
export interface DocumentDump {
    /**
     * 
     * @type {string}
     * @memberof DocumentDump
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDump
     */
    content: string;
}
/**
 * 
 * @export
 * @interface EmptyUsersResponse
 */
export interface EmptyUsersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmptyUsersResponse
     */
    empty: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmptyUsersResponse
     */
    withContent: Array<string>;
}
/**
 * 
 * @export
 * @interface EventData
 */
export interface EventData {
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    reason?: string;
    /**
     * 
     * @type {EventSource}
     * @memberof EventData
     */
    source?: EventSource;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    firstSeenTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    lastSeenTimestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof EventData
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    kind?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    fieldPath?: string;
    /**
     * 
     * @type {string}
     * @memberof EventData
     */
    namespace: string;
}
/**
 * 
 * @export
 * @interface EventSource
 */
export interface EventSource {
    /**
     * 
     * @type {string}
     * @memberof EventSource
     */
    component?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSource
     */
    host?: string;
}
/**
 * 
 * @export
 * @interface ExtendedRequestData
 */
export interface ExtendedRequestData {
    /**
     * 
     * @type {string}
     * @memberof ExtendedRequestData
     */
    methodName: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExtendedRequestData
     */
    params: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ExternalConnectionInfoData
 */
export interface ExternalConnectionInfoData {
    /**
     * 
     * @type {ModelInstancePK}
     * @memberof ExternalConnectionInfoData
     */
    id: ModelInstancePK;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalConnectionInfoData
     */
    connected: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionInfoData
     */
    dockerRunCommand?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionInfoData
     */
    dockerComposeContent?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExternalConnectionInfoData
     */
    envVariables: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionInfoData
     */
    hostname?: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalConnectionInfoData
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionInfoData
     */
    imageName?: string;
}
/**
 * 
 * @export
 * @interface FeatureData
 */
export interface FeatureData {
    /**
     * 
     * @type {string}
     * @memberof FeatureData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureData
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface Fit2RequestData
 */
export interface Fit2RequestData {
    /**
     * 
     * @type {string}
     * @memberof Fit2RequestData
     */
    config?: string;
    /**
     * 
     * @type {number}
     * @memberof Fit2RequestData
     */
    configId?: number;
    /**
     * 
     * @type {number}
     * @memberof Fit2RequestData
     */
    datasetId: number;
}
/**
 * 
 * @export
 * @interface FitConfigData
 */
export interface FitConfigData {
    /**
     * 
     * @type {FitConfigPK}
     * @memberof FitConfigData
     */
    id: FitConfigPK;
    /**
     * 
     * @type {string}
     * @memberof FitConfigData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FitConfigData
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FitConfigData
     */
    isManual: boolean;
    /**
     * 
     * @type {string}
     * @memberof FitConfigData
     */
    config: string;
}
/**
 * 
 * @export
 * @interface FitConfigDump
 */
export interface FitConfigDump {
    /**
     * 
     * @type {string}
     * @memberof FitConfigDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FitConfigDump
     */
    config: string;
    /**
     * 
     * @type {boolean}
     * @memberof FitConfigDump
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface FitConfigPK
 */
export interface FitConfigPK {
    /**
     * 
     * @type {number}
     * @memberof FitConfigPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof FitConfigPK
     */
    modelId: number;
    /**
     * 
     * @type {number}
     * @memberof FitConfigPK
     */
    configId: number;
}
/**
 * 
 * @export
 * @interface FitRequestData
 */
export interface FitRequestData {
    /**
     * 
     * @type {object}
     * @memberof FitRequestData
     */
    config?: object;
    /**
     * 
     * @type {number}
     * @memberof FitRequestData
     */
    configId?: number;
    /**
     * 
     * @type {number}
     * @memberof FitRequestData
     */
    datasetId: number;
    /**
     * 
     * @type {string}
     * @memberof FitRequestData
     */
    billingToken?: string;
}
/**
 * 
 * @export
 * @interface FrontendSettings
 */
export interface FrontendSettings {
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    isSystemAccount: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    isBillingEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    isArchiveEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    isExtendedLanding: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontendSettings
     */
    currencyType: CurrencyTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    englishOnly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendSettings
     */
    refillByManager: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CurrencyTypeEnum {
    RUBLE = 'RUBLE',
    TOKEN = 'TOKEN'
}

/**
 * 
 * @export
 * @interface HealthCheckHistoryResult
 */
export interface HealthCheckHistoryResult {
    /**
     * 
     * @type {Array<HealthInterval>}
     * @memberof HealthCheckHistoryResult
     */
    failedIntervals: Array<HealthInterval>;
}
/**
 * 
 * @export
 * @interface HealthCheckResult
 */
export interface HealthCheckResult {
    /**
     * 
     * @type {boolean}
     * @memberof HealthCheckResult
     */
    ok: boolean;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResult
     */
    status: string;
    /**
     * 
     * @type {Array<CheckResult>}
     * @memberof HealthCheckResult
     */
    checks: Array<CheckResult>;
}
/**
 * 
 * @export
 * @interface HealthInterval
 */
export interface HealthInterval {
    /**
     * 
     * @type {string}
     * @memberof HealthInterval
     */
    from: string;
    /**
     * 
     * @type {number}
     * @memberof HealthInterval
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof HealthInterval
     */
    to: string;
    /**
     * 
     * @type {Array<CheckResult>}
     * @memberof HealthInterval
     */
    firstErrors: Array<CheckResult>;
}
/**
 * 
 * @export
 * @interface HzClusterInfo
 */
export interface HzClusterInfo {
    /**
     * 
     * @type {number}
     * @memberof HzClusterInfo
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof HzClusterInfo
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof HzClusterInfo
     */
    state: string;
    /**
     * 
     * @type {Array<HzMemberInfo>}
     * @memberof HzClusterInfo
     */
    members: Array<HzMemberInfo>;
}
/**
 * 
 * @export
 * @interface HzMemberInfo
 */
export interface HzMemberInfo {
    /**
     * 
     * @type {string}
     * @memberof HzMemberInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HzMemberInfo
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof HzMemberInfo
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof HzMemberInfo
     */
    isLocal: boolean;
}
/**
 * 
 * @export
 * @interface ImageDump
 */
export interface ImageDump {
    /**
     * 
     * @type {string}
     * @memberof ImageDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImageDump
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof ImageDump
     */
    accessMode?: AccessModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface ImageInfoData
 */
export interface ImageInfoData {
    /**
     * 
     * @type {ImageInfoPK}
     * @memberof ImageInfoData
     */
    id?: ImageInfoPK;
    /**
     * 
     * @type {string}
     * @memberof ImageInfoData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImageInfoData
     */
    imageAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageInfoData
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof ImageInfoData
     */
    accessMode?: AccessModeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessModeEnum {
    Private = 'private',
    Public = 'public',
    Restricted = 'restricted'
}

/**
 * 
 * @export
 * @interface ImageInfoPK
 */
export interface ImageInfoPK {
    /**
     * 
     * @type {number}
     * @memberof ImageInfoPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof ImageInfoPK
     */
    imageId: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {any}
     * @memberof InlineObject
     */
    file: any;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {any}
     * @memberof InlineObject1
     */
    file?: any;
}
/**
 * 
 * @export
 * @interface InstanceEnvironmentData
 */
export interface InstanceEnvironmentData {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InstanceEnvironmentData
     */
    environment: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface InstanceEventData
 */
export interface InstanceEventData {
    /**
     * 
     * @type {Array<EventData>}
     * @memberof InstanceEventData
     */
    events: Array<EventData>;
}
/**
 * 
 * @export
 * @interface InstanceLastState
 */
export interface InstanceLastState {
    /**
     * 
     * @type {InstanceStateRunning}
     * @memberof InstanceLastState
     */
    running?: InstanceStateRunning;
    /**
     * 
     * @type {InstanceStateTerminated}
     * @memberof InstanceLastState
     */
    terminated?: InstanceStateTerminated;
    /**
     * 
     * @type {InstanceStateWaiting}
     * @memberof InstanceLastState
     */
    waiting?: InstanceStateWaiting;
}
/**
 * 
 * @export
 * @interface InstanceMetadata
 */
export interface InstanceMetadata {
    /**
     * 
     * @type {number}
     * @memberof InstanceMetadata
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceMetadata
     */
    hostname?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceMetadata
     */
    builtInImageName?: string;
}
/**
 * 
 * @export
 * @interface InstanceStateRunning
 */
export interface InstanceStateRunning {
    /**
     * 
     * @type {string}
     * @memberof InstanceStateRunning
     */
    startedAt?: string;
}
/**
 * 
 * @export
 * @interface InstanceStateTerminated
 */
export interface InstanceStateTerminated {
    /**
     * 
     * @type {string}
     * @memberof InstanceStateTerminated
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceStateTerminated
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceStateTerminated
     */
    exitCode?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceStateTerminated
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceStateTerminated
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InstanceStateWaiting
 */
export interface InstanceStateWaiting {
    /**
     * 
     * @type {string}
     * @memberof InstanceStateWaiting
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceStateWaiting
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InstancesStatusData
 */
export interface InstancesStatusData {
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    internalRequested: number;
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    internalActive: number;
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    internalInError: number;
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    hostingServerRequested: number;
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    hostingServerActive: number;
    /**
     * 
     * @type {number}
     * @memberof InstancesStatusData
     */
    externalRequested: number;
}
/**
 * 
 * @export
 * @interface JobStatusData
 */
export interface JobStatusData {
    /**
     * 
     * @type {number}
     * @memberof JobStatusData
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatusData
     */
    jobId: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusData
     */
    jobType: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatusData
     */
    state: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobStatusData
     */
    done: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobStatusData
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobStatusData
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface JobStatusDataV2
 */
export interface JobStatusDataV2 {
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    jobStatus: JobStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    endTime?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatusDataV2
     */
    parents: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    percentage?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    currentCommandName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    originalException?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    priorityName: string;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    modelId?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    instanceId?: number;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    groupOwnerId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobStatusDataV2
     */
    groupName?: string;
    /**
     * 
     * @type {number}
     * @memberof JobStatusDataV2
     */
    serverId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobStatusDataV2
     */
    waitFor?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum JobStatusEnum {
    IDLE = 'IDLE',
    RUN = 'RUN',
    REVERTING = 'REVERTING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    REVERTED = 'REVERTED'
}

/**
 * 
 * @export
 * @interface LastActivityPk
 */
export interface LastActivityPk {
    /**
     * 
     * @type {number}
     * @memberof LastActivityPk
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof LastActivityPk
     */
    modelId: number;
}
/**
 * 
 * @export
 * @interface ManagementRequestData
 */
export interface ManagementRequestData {
    /**
     * 
     * @type {string}
     * @memberof ManagementRequestData
     */
    contacts: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementRequestData
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface Measurement
 */
export interface Measurement {
    /**
     * 
     * @type {number}
     * @memberof Measurement
     */
    ts: number;
    /**
     * 
     * @type {number}
     * @memberof Measurement
     */
    v: number;
}
/**
 * 
 * @export
 * @interface MethodDescriptorData
 */
export interface MethodDescriptorData {
    /**
     * 
     * @type {string}
     * @memberof MethodDescriptorData
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: ParamTypeData; }}
     * @memberof MethodDescriptorData
     */
    params: { [key: string]: ParamTypeData; };
    /**
     * 
     * @type {ParamTypeData}
     * @memberof MethodDescriptorData
     */
    output: ParamTypeData;
    /**
     * 
     * @type {boolean}
     * @memberof MethodDescriptorData
     */
    fitted: boolean;
}
/**
 * 
 * @export
 * @interface ModelAliasData
 */
export interface ModelAliasData {
    /**
     * 
     * @type {ModelInfoPK}
     * @memberof ModelAliasData
     */
    id: ModelInfoPK;
    /**
     * 
     * @type {Array<AliasData>}
     * @memberof ModelAliasData
     */
    aliases: Array<AliasData>;
}
/**
 * 
 * @export
 * @interface ModelArchiveSettingsData
 */
export interface ModelArchiveSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelArchiveSettingsData
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelArchiveSettingsData
     */
    numberOfArchivedRequests: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelArchiveSettingsData
     */
    encryptionEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelArchiveSettingsData
     */
    rsaPemPublicKey?: string;
}
/**
 * 
 * @export
 * @interface ModelAutoScalingConfiguration
 */
export interface ModelAutoScalingConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    minInstanceCount: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    maxInstanceCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    cooldownDurationMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleUpRequestsPerMinuteThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleDownRequestsPerMinuteThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleUpLatencyThresholdMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleDownLatencyThresholdMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleUpCpuThresholdMilliCores?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelAutoScalingConfiguration
     */
    scaleDownCpuThresholdMilliCores?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelAutoScalingConfiguration
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface ModelBatchesData
 */
export interface ModelBatchesData {
    /**
     * 
     * @type {number}
     * @memberof ModelBatchesData
     */
    batchSize: number;
    /**
     * 
     * @type {number}
     * @memberof ModelBatchesData
     */
    timeWaitMs: number;
    /**
     * 
     * @type {number}
     * @memberof ModelBatchesData
     */
    maxLengthToSkip?: number;
}
/**
 * 
 * @export
 * @interface ModelBillingSettingsData
 */
export interface ModelBillingSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelBillingSettingsData
     */
    isBillingEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelBillingSettingsData
     */
    billingUnit?: BillingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelBillingSettingsData
     */
    billingUnitPriceInNanoToken?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelBillingSettingsData
     */
    billingUnitPriceInCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelBillingSettingsData
     */
    freeUnitQuota?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BillingUnitEnum {
    Calls = 'calls',
    Direct = 'direct',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface ModelCachingData
 */
export interface ModelCachingData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelCachingData
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelCachingData
     */
    mongoUri: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCachingData
     */
    collectionName: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCachingData
     */
    recordsLimit: number;
}
/**
 * 
 * @export
 * @interface ModelCreateUpdateData
 */
export interface ModelCreateUpdateData {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    modelName: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    imageAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    imageId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    trainingModelAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    trainingModelId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    trainingType?: TrainingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    trainingDatasetAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    trainingDatasetId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    trainingFitConfigId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    taskType?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    trainingDatasetType?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    fitTemplateModelId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCreateUpdateData
     */
    composite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCreateUpdateData
     */
    prototype?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCreateUpdateData
     */
    rejectRequestsIfInactive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    config?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    env?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelCreateUpdateData
     */
    additionalFlags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelCreateUpdateData
     */
    fittable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    hostingType?: HostingTypeEnum;
    /**
     * 
     * @type {Array<PersistentVolumeData>}
     * @memberof ModelCreateUpdateData
     */
    persistentVolumes?: Array<PersistentVolumeData>;
    /**
     * 
     * @type {Array<DataImageMountData>}
     * @memberof ModelCreateUpdateData
     */
    dataImageMounts?: Array<DataImageMountData>;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    resourceGroup?: string;
    /**
     * 
     * @type {ModelTimeoutsData}
     * @memberof ModelCreateUpdateData
     */
    timeouts?: ModelTimeoutsData;
    /**
     * 
     * @type {ModelLimitsData}
     * @memberof ModelCreateUpdateData
     */
    resourceLimits?: ModelLimitsData;
    /**
     * 
     * @type {ModelRetriesData}
     * @memberof ModelCreateUpdateData
     */
    retriesConfig?: ModelRetriesData;
    /**
     * 
     * @type {ModelBatchesData}
     * @memberof ModelCreateUpdateData
     */
    batchesConfig?: ModelBatchesData;
    /**
     * 
     * @type {ModelCachingData}
     * @memberof ModelCreateUpdateData
     */
    caching?: ModelCachingData;
    /**
     * 
     * @type {ModelPriorityQueueData}
     * @memberof ModelCreateUpdateData
     */
    priorityQueue?: ModelPriorityQueueData;
    /**
     * 
     * @type {ModelAutoScalingConfiguration}
     * @memberof ModelCreateUpdateData
     */
    autoScalingConfiguration?: ModelAutoScalingConfiguration;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateUpdateData
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelCreateUpdateData
     */
    languages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    minInstancesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCreateUpdateData
     */
    startTimeSec?: number;
    /**
     * 
     * @type {ModelHttpSettingsData}
     * @memberof ModelCreateUpdateData
     */
    httpSettings?: ModelHttpSettingsData;
    /**
     * 
     * @type {ModelArchiveSettingsData}
     * @memberof ModelCreateUpdateData
     */
    archiveSettings?: ModelArchiveSettingsData;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelCreateUpdateData
     */
    aliases?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainingTypeEnum {
    SingleContainer = 'singleContainer',
    MultipleFit = 'multipleFit'
}
/**
    * @export
    * @enum {string}
    */
export enum HostingTypeEnum {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
    AUTOMATIC = 'AUTOMATIC',
    HOSTINGSERVER = 'HOSTING_SERVER'
}

/**
 * 
 * @export
 * @interface ModelDefaults
 */
export interface ModelDefaults {
    /**
     * 
     * @type {ModelTimeoutsData}
     * @memberof ModelDefaults
     */
    timeouts: ModelTimeoutsData;
    /**
     * 
     * @type {ModelRetriesData}
     * @memberof ModelDefaults
     */
    retries: ModelRetriesData;
    /**
     * 
     * @type {ModelBatchesData}
     * @memberof ModelDefaults
     */
    batches: ModelBatchesData;
    /**
     * 
     * @type {ModelLimitsData}
     * @memberof ModelDefaults
     */
    limits: ModelLimitsData;
    /**
     * 
     * @type {string}
     * @memberof ModelDefaults
     */
    resourceGroup: string;
}
/**
 * 
 * @export
 * @interface ModelDump
 */
export interface ModelDump {
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    imageAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    modelGroup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    isPublic?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ModelDump
     */
    config?: object;
    /**
     * 
     * @type {object}
     * @memberof ModelDump
     */
    env?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelDump
     */
    additionalFlags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingModelAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingModelName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingDatasetAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingDatasetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingFitConfigName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    taskType?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingDatasetType?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    fitTemplateModelName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    composite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    prototype?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    rejectRequestsIfInactive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    fittable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    trainingType?: TrainingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    hostingType?: HostingTypeEnum;
    /**
     * 
     * @type {Array<DataImageMountDump>}
     * @memberof ModelDump
     */
    dataImageMounts?: Array<DataImageMountDump>;
    /**
     * 
     * @type {ModelTimeoutsData}
     * @memberof ModelDump
     */
    timeouts?: ModelTimeoutsData;
    /**
     * 
     * @type {ModelLimitsData}
     * @memberof ModelDump
     */
    limits?: ModelLimitsData;
    /**
     * 
     * @type {ModelRetriesData}
     * @memberof ModelDump
     */
    retries?: ModelRetriesData;
    /**
     * 
     * @type {ModelBatchesData}
     * @memberof ModelDump
     */
    batches?: ModelBatchesData;
    /**
     * 
     * @type {ModelCachingData}
     * @memberof ModelDump
     */
    caching?: ModelCachingData;
    /**
     * 
     * @type {ModelPriorityQueueData}
     * @memberof ModelDump
     */
    priorityQueue?: ModelPriorityQueueData;
    /**
     * 
     * @type {ModelAutoScalingConfiguration}
     * @memberof ModelDump
     */
    autoScalingConfiguration?: ModelAutoScalingConfiguration;
    /**
     * 
     * @type {Array<DocumentDump>}
     * @memberof ModelDump
     */
    docs: Array<DocumentDump>;
    /**
     * 
     * @type {Array<PredictConfigDump>}
     * @memberof ModelDump
     */
    predictConfigs: Array<PredictConfigDump>;
    /**
     * 
     * @type {Array<FitConfigDump>}
     * @memberof ModelDump
     */
    fitConfigs: Array<FitConfigDump>;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    resourceGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelDump
     */
    languages?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    availableInJaicp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    featured?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelDump
     */
    featuredListOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    publicTestingAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    isBillingEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    billingUnit?: BillingUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelDump
     */
    billingUnitPriceInNanoToken?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelDump
     */
    freeUnitQuota?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelDump
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    isHttpEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelDump
     */
    httpPort?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    mainPageEndpoint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    httpInterfaceOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    archiveEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelDump
     */
    numberOfArchivedRequests: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelDump
     */
    archiveEncryptionEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelDump
     */
    archiveEncryptionPublicKey?: string;
    /**
     * 
     * @type {ModelHttpSettingsData}
     * @memberof ModelDump
     */
    asHttpSettingsData: ModelHttpSettingsData;
    /**
     * 
     * @type {ModelPublicSettingsData}
     * @memberof ModelDump
     */
    asPublicSettingsData: ModelPublicSettingsData;
    /**
     * 
     * @type {ModelBillingSettingsData}
     * @memberof ModelDump
     */
    asBillingSettingsData: ModelBillingSettingsData;
    /**
     * 
     * @type {ModelArchiveSettingsData}
     * @memberof ModelDump
     */
    asArchiveSettingsData: ModelArchiveSettingsData;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainingTypeEnum {
    SingleContainer = 'singleContainer',
    MultipleFit = 'multipleFit'
}
/**
    * @export
    * @enum {string}
    */
export enum HostingTypeEnum {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
    AUTOMATIC = 'AUTOMATIC',
    HOSTINGSERVER = 'HOSTING_SERVER'
}
/**
    * @export
    * @enum {string}
    */
export enum BillingUnitEnum {
    Calls = 'calls',
    Direct = 'direct',
    Custom = 'custom'
}

/**
 * 
 * @export
 * @interface ModelGroupData
 */
export interface ModelGroupData {
    /**
     * 
     * @type {ModelGroupPK}
     * @memberof ModelGroupData
     */
    id?: ModelGroupPK;
    /**
     * 
     * @type {string}
     * @memberof ModelGroupData
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ModelGroupDump
 */
export interface ModelGroupDump {
    /**
     * 
     * @type {string}
     * @memberof ModelGroupDump
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ModelGroupPK
 */
export interface ModelGroupPK {
    /**
     * 
     * @type {number}
     * @memberof ModelGroupPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelGroupPK
     */
    groupId: number;
}
/**
 * 
 * @export
 * @interface ModelHttpSettingsData
 */
export interface ModelHttpSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelHttpSettingsData
     */
    isHttpEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelHttpSettingsData
     */
    httpPort?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelHttpSettingsData
     */
    mainPageEndpoint?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelHttpSettingsData
     */
    httpInterfaceOnly?: boolean;
}
/**
 * 
 * @export
 * @interface ModelInfoData
 */
export interface ModelInfoData {
    /**
     * 
     * @type {ModelInfoPK}
     * @memberof ModelInfoData
     */
    id: ModelInfoPK;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    modelAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    modelAccountDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    modelName: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    imageAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    imageId?: number;
    /**
     * 
     * @type {ImageInfoData}
     * @memberof ModelInfoData
     */
    image?: ImageInfoData;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    modelGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    modelGroupName?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    trainingDatasetAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    trainingDatasetId?: number;
    /**
     * 
     * @type {DatasetInfoData}
     * @memberof ModelInfoData
     */
    trainingDataset?: DatasetInfoData;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    trainingDatasetType?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    trainingFitConfigId?: number;
    /**
     * 
     * @type {FitConfigData}
     * @memberof ModelInfoData
     */
    trainingFitConfig?: FitConfigData;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    fitTemplateModelId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    composite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    prototype?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    rejectRequestsIfInactive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    taskType?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    trainingModelAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    trainingModelId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    trainingModelName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    trainingType?: TrainingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    config?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    env?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelInfoData
     */
    additionalFlags?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    fittable: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    hostingType: HostingTypeEnum;
    /**
     * 
     * @type {Array<PersistentVolumeData>}
     * @memberof ModelInfoData
     */
    persistentVolumes: Array<PersistentVolumeData>;
    /**
     * 
     * @type {Array<DataImageMountData>}
     * @memberof ModelInfoData
     */
    dataImageMounts: Array<DataImageMountData>;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    resourceGroup?: string;
    /**
     * 
     * @type {ModelTimeoutsData}
     * @memberof ModelInfoData
     */
    timeouts: ModelTimeoutsData;
    /**
     * 
     * @type {ModelLimitsData}
     * @memberof ModelInfoData
     */
    resourceLimits: ModelLimitsData;
    /**
     * 
     * @type {ModelRetriesData}
     * @memberof ModelInfoData
     */
    retriesConfig: ModelRetriesData;
    /**
     * 
     * @type {ModelBatchesData}
     * @memberof ModelInfoData
     */
    batchesConfig: ModelBatchesData;
    /**
     * 
     * @type {ModelCachingData}
     * @memberof ModelInfoData
     */
    caching: ModelCachingData;
    /**
     * 
     * @type {ModelPriorityQueueData}
     * @memberof ModelInfoData
     */
    priorityQueue: ModelPriorityQueueData;
    /**
     * 
     * @type {ModelAutoScalingConfiguration}
     * @memberof ModelInfoData
     */
    autoScalingConfiguration: ModelAutoScalingConfiguration;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelInfoData
     */
    languages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    minInstancesCount?: number;
    /**
     * 
     * @type {ModelPublicSettingsData}
     * @memberof ModelInfoData
     */
    publicSettings: ModelPublicSettingsData;
    /**
     * 
     * @type {ModelBillingSettingsData}
     * @memberof ModelInfoData
     */
    billingSettings: ModelBillingSettingsData;
    /**
     * 
     * @type {ModelHttpSettingsData}
     * @memberof ModelInfoData
     */
    httpSettings: ModelHttpSettingsData;
    /**
     * 
     * @type {ModelArchiveSettingsData}
     * @memberof ModelInfoData
     */
    archiveSettings: ModelArchiveSettingsData;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    restrictedImageAccess: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoData
     */
    lastActivity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoData
     */
    favorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoData
     */
    state?: StateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TrainingTypeEnum {
    SingleContainer = 'singleContainer',
    MultipleFit = 'multipleFit'
}
/**
    * @export
    * @enum {string}
    */
export enum HostingTypeEnum {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
    AUTOMATIC = 'AUTOMATIC',
    HOSTINGSERVER = 'HOSTING_SERVER'
}
/**
    * @export
    * @enum {string}
    */
export enum StateEnum {
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    INACTIVE = 'INACTIVE',
    SLEEPING = 'SLEEPING'
}

/**
 * 
 * @export
 * @interface ModelInfoPK
 */
export interface ModelInfoPK {
    /**
     * 
     * @type {number}
     * @memberof ModelInfoPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoPK
     */
    modelId: number;
}
/**
 * 
 * @export
 * @interface ModelInstance
 */
export interface ModelInstance {
    /**
     * 
     * @type {ModelInstancePK}
     * @memberof ModelInstance
     */
    id: ModelInstancePK;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    connectionToken: string;
    /**
     * 
     * @type {Date}
     * @memberof ModelInstance
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelInstance
     */
    lastHeartBeat: Date;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    type: TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    kubeType?: KubeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    resourceName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    customData?: string;
    /**
     * 
     * @type {Date}
     * @memberof ModelInstance
     */
    deleteTimestamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInstance
     */
    isEvictable: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    hostingServerId?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInstance
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    hostname?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInstance
     */
    serviceVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    builtInImageName?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInstance
     */
    externalDockerHttpPort?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    externalDockerHttpHost?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInstance
     */
    status: StatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeEnum {
    InitialCheck = 'initialCheck',
    SingleFit = 'singleFit',
    SingleFitPool = 'singleFitPool',
    MultipleFit = 'multipleFit',
    Predict = 'predict'
}
/**
    * @export
    * @enum {string}
    */
export enum KubeTypeEnum {
    Deployment = 'deployment',
    Pod = 'pod',
    Docker = 'docker',
    External = 'external',
    HostingServer = 'hostingServer'
}
/**
    * @export
    * @enum {string}
    */
export enum StatusEnum {
    IDLE = 'IDLE',
    SCHEDULED = 'SCHEDULED',
    STARTED = 'STARTED',
    REMOVING = 'REMOVING',
    DELETED = 'DELETED',
    FAILED = 'FAILED'
}

/**
 * 
 * @export
 * @interface ModelInstanceData
 */
export interface ModelInstanceData {
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceData
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceData
     */
    serverName?: string;
    /**
     * 
     * @type {StatusInfo}
     * @memberof ModelInstanceData
     */
    statusInfo: StatusInfo;
    /**
     * 
     * @type {number}
     * @memberof ModelInstanceData
     */
    restartCount: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceData
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceData
     */
    instanceHostingType?: InstanceHostingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelInstanceData
     */
    lastRestartTimestamp?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InstanceHostingTypeEnum {
    Deployment = 'deployment',
    Pod = 'pod',
    Docker = 'docker',
    External = 'external',
    HostingServer = 'hostingServer'
}

/**
 * 
 * @export
 * @interface ModelInstanceListData
 */
export interface ModelInstanceListData {
    /**
     * 
     * @type {Array<ModelInstanceData>}
     * @memberof ModelInstanceListData
     */
    instances: Array<ModelInstanceData>;
}
/**
 * 
 * @export
 * @interface ModelInstancePK
 */
export interface ModelInstancePK {
    /**
     * 
     * @type {number}
     * @memberof ModelInstancePK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInstancePK
     */
    modelId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelInstancePK
     */
    instanceId: number;
}
/**
 * 
 * @export
 * @interface ModelLimitsData
 */
export interface ModelLimitsData {
    /**
     * 
     * @type {string}
     * @memberof ModelLimitsData
     */
    cpuRequest: string;
    /**
     * 
     * @type {string}
     * @memberof ModelLimitsData
     */
    memoryLimit: string;
    /**
     * 
     * @type {string}
     * @memberof ModelLimitsData
     */
    ephemeralDiskLimit: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelLimitsData
     */
    gpuRequested: boolean;
}
/**
 * 
 * @export
 * @interface ModelListRequest
 */
export interface ModelListRequest {
    /**
     * 
     * @type {Array<ModelStringId>}
     * @memberof ModelListRequest
     */
    ids: Array<ModelStringId>;
}
/**
 * 
 * @export
 * @interface ModelMetricsData
 */
export interface ModelMetricsData {
    /**
     * 
     * @type {number}
     * @memberof ModelMetricsData
     */
    modelId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMetricsData
     */
    totalSuccess: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMetricsData
     */
    totalFail: number;
    /**
     * 
     * @type {number}
     * @memberof ModelMetricsData
     */
    failPercentage: number;
}
/**
 * 
 * @export
 * @interface ModelPriorityQueueData
 */
export interface ModelPriorityQueueData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelPriorityQueueData
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelPriorityQueueData
     */
    concurrencyLevel: number;
}
/**
 * 
 * @export
 * @interface ModelPublicSettingsData
 */
export interface ModelPublicSettingsData {
    /**
     * 
     * @type {boolean}
     * @memberof ModelPublicSettingsData
     */
    isPublic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelPublicSettingsData
     */
    availableInJaicp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelPublicSettingsData
     */
    featured?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelPublicSettingsData
     */
    featuredListOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelPublicSettingsData
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelPublicSettingsData
     */
    publicTestingAllowed?: boolean;
}
/**
 * 
 * @export
 * @interface ModelRetriesData
 */
export interface ModelRetriesData {
    /**
     * 
     * @type {number}
     * @memberof ModelRetriesData
     */
    maxRetries: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ModelRetriesData
     */
    timeoutsMs: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ModelRetriesData
     */
    maxRetriesPerInstance?: number;
}
/**
 * 
 * @export
 * @interface ModelShortStatusData
 */
export interface ModelShortStatusData {
    /**
     * 
     * @type {string}
     * @memberof ModelShortStatusData
     */
    state: StateEnum;
    /**
     * 
     * @type {InstancesStatusData}
     * @memberof ModelShortStatusData
     */
    instances: InstancesStatusData;
    /**
     * 
     * @type {JobStatusData}
     * @memberof ModelShortStatusData
     */
    lastJob?: JobStatusData;
    /**
     * 
     * @type {number}
     * @memberof ModelShortStatusData
     */
    startTimeSeconds?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StateEnum {
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    INACTIVE = 'INACTIVE',
    SLEEPING = 'SLEEPING'
}

/**
 * 
 * @export
 * @interface ModelStartTimeData
 */
export interface ModelStartTimeData {
    /**
     * 
     * @type {number}
     * @memberof ModelStartTimeData
     */
    modelId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelStartTimeData
     */
    instanceId: number;
    /**
     * 
     * @type {number}
     * @memberof ModelStartTimeData
     */
    modelAverageStartTimeSec?: number;
    /**
     * 
     * @type {Date}
     * @memberof ModelStartTimeData
     */
    instanceStartTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelStartTimeData
     */
    instanceStartedTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelStartTimeData
     */
    instanceErrorTime?: Date;
}
/**
 * 
 * @export
 * @interface ModelStringId
 */
export interface ModelStringId {
    /**
     * 
     * @type {string}
     * @memberof ModelStringId
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof ModelStringId
     */
    model: string;
}
/**
 * 
 * @export
 * @interface ModelTimeoutsData
 */
export interface ModelTimeoutsData {
    /**
     * 
     * @type {number}
     * @memberof ModelTimeoutsData
     */
    podStartTimeoutSec: number;
    /**
     * 
     * @type {number}
     * @memberof ModelTimeoutsData
     */
    predictTimeoutSec: number;
    /**
     * 
     * @type {number}
     * @memberof ModelTimeoutsData
     */
    fitTimeoutSec?: number;
}
/**
 * 
 * @export
 * @interface OverallMetricsData
 */
export interface OverallMetricsData {
    /**
     * 
     * @type {number}
     * @memberof OverallMetricsData
     */
    totalSuccessCount: number;
    /**
     * 
     * @type {number}
     * @memberof OverallMetricsData
     */
    totalFailCount: number;
    /**
     * 
     * @type {number}
     * @memberof OverallMetricsData
     */
    totalFailPercentage: number;
    /**
     * 
     * @type {Array<ModelMetricsData>}
     * @memberof OverallMetricsData
     */
    modelMetrics: Array<ModelMetricsData>;
}
/**
 * 
 * @export
 * @interface PageStatLogData
 */
export interface PageStatLogData {
    /**
     * 
     * @type {number}
     * @memberof PageStatLogData
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStatLogData
     */
    totalPages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageStatLogData
     */
    first?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageStatLogData
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageStatLogData
     */
    size?: number;
    /**
     * 
     * @type {Array<StatLogData>}
     * @memberof PageStatLogData
     */
    content?: Array<StatLogData>;
    /**
     * 
     * @type {number}
     * @memberof PageStatLogData
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageStatLogData
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageStatLogData
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageStatLogData
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStatLogData
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    offset?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    sort?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PagedDataImageInfoData
 */
export interface PagedDataImageInfoData {
    /**
     * 
     * @type {Pagination}
     * @memberof PagedDataImageInfoData
     */
    paging: Pagination;
    /**
     * 
     * @type {Array<DataImageInfoData>}
     * @memberof PagedDataImageInfoData
     */
    records: Array<DataImageInfoData>;
}
/**
 * 
 * @export
 * @interface PagedImageInfoData
 */
export interface PagedImageInfoData {
    /**
     * 
     * @type {Pagination}
     * @memberof PagedImageInfoData
     */
    paging: Pagination;
    /**
     * 
     * @type {Array<ImageInfoData>}
     * @memberof PagedImageInfoData
     */
    records: Array<ImageInfoData>;
}
/**
 * 
 * @export
 * @interface PagedModelInfoData
 */
export interface PagedModelInfoData {
    /**
     * 
     * @type {Pagination}
     * @memberof PagedModelInfoData
     */
    paging: Pagination;
    /**
     * 
     * @type {Array<ModelInfoData>}
     * @memberof PagedModelInfoData
     */
    records: Array<ModelInfoData>;
}
/**
 * 
 * @export
 * @interface Pagination
 */
export interface Pagination {
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    pageNumber: number;
    /**
     * 
     * @type {number}
     * @memberof Pagination
     */
    pageSize: number;
}
/**
 * 
 * @export
 * @interface ParamTypeData
 */
export interface ParamTypeData {
    /**
     * 
     * @type {string}
     * @memberof ParamTypeData
     */
    type: string;
}
/**
 * 
 * @export
 * @interface ParaphrasingStatus
 */
export interface ParaphrasingStatus {
    /**
     * 
     * @type {Array<TaskSuiteStatus>}
     * @memberof ParaphrasingStatus
     */
    statuses: Array<TaskSuiteStatus>;
}
/**
 * 
 * @export
 * @interface PersistentVolumeData
 */
export interface PersistentVolumeData {
    /**
     * 
     * @type {number}
     * @memberof PersistentVolumeData
     */
    pvId?: number;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeData
     */
    mountPath: string;
    /**
     * 
     * @type {number}
     * @memberof PersistentVolumeData
     */
    sizeInGb: number;
    /**
     * 
     * @type {string}
     * @memberof PersistentVolumeData
     */
    storageClass: string;
}
/**
 * 
 * @export
 * @interface Predict2RequestData
 */
export interface Predict2RequestData {
    /**
     * 
     * @type {string}
     * @memberof Predict2RequestData
     */
    config?: string;
    /**
     * 
     * @type {string}
     * @memberof Predict2RequestData
     */
    data: string;
    /**
     * 
     * @type {string}
     * @memberof Predict2RequestData
     */
    dataType?: string;
}
/**
 * 
 * @export
 * @interface PredictConfigData
 */
export interface PredictConfigData {
    /**
     * 
     * @type {PredictConfigPK}
     * @memberof PredictConfigData
     */
    id: PredictConfigPK;
    /**
     * 
     * @type {string}
     * @memberof PredictConfigData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PredictConfigData
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof PredictConfigData
     */
    config: string;
}
/**
 * 
 * @export
 * @interface PredictConfigDump
 */
export interface PredictConfigDump {
    /**
     * 
     * @type {string}
     * @memberof PredictConfigDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PredictConfigDump
     */
    config: string;
    /**
     * 
     * @type {boolean}
     * @memberof PredictConfigDump
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface PredictConfigPK
 */
export interface PredictConfigPK {
    /**
     * 
     * @type {number}
     * @memberof PredictConfigPK
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof PredictConfigPK
     */
    modelId: number;
    /**
     * 
     * @type {number}
     * @memberof PredictConfigPK
     */
    configId: number;
}
/**
 * 
 * @export
 * @interface PredictRequestData
 */
export interface PredictRequestData {
    /**
     * 
     * @type {object}
     * @memberof PredictRequestData
     */
    config?: object;
    /**
     * 
     * @type {object}
     * @memberof PredictRequestData
     */
    data: object;
    /**
     * 
     * @type {string}
     * @memberof PredictRequestData
     */
    dataType?: string;
}
/**
 * 
 * @export
 * @interface RequestDetailsData
 */
export interface RequestDetailsData {
    /**
     * 
     * @type {number}
     * @memberof RequestDetailsData
     */
    modelAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof RequestDetailsData
     */
    modelId: number;
    /**
     * 
     * @type {string}
     * @memberof RequestDetailsData
     */
    callerAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDetailsData
     */
    date: string;
}
/**
 * 
 * @export
 * @interface RequestMetricsData
 */
export interface RequestMetricsData {
    /**
     * 
     * @type {RequestDetailsData}
     * @memberof RequestMetricsData
     */
    requestDetailsData: RequestDetailsData;
    /**
     * 
     * @type {string}
     * @memberof RequestMetricsData
     */
    requestTypeData: RequestTypeDataEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RequestMetricsData
     */
    isSuccess: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequestMetricsData
     */
    count: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RequestTypeDataEnum {
    FIT = 'FIT',
    PREDICT = 'PREDICT'
}

/**
 * 
 * @export
 * @interface ResourceGroupAutoScalingConfiguration
 */
export interface ResourceGroupAutoScalingConfiguration {
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    serverConfiguration?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    fallbackServersIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    minServersCount: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    maxServersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    cooldownPeriodMinutes?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupAutoScalingConfiguration
     */
    reserveResourceGroupName?: string;
}
/**
 * 
 * @export
 * @interface ResourceGroupCapacity
 */
export interface ResourceGroupCapacity {
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    milliCpu: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    ramMb: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    diskMb: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    gpu: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceGroupCapacity
     */
    gpuModels: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    vRamMb?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceGroupCapacity
     */
    nodes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupCapacity
     */
    vramMb: number;
}
/**
 * 
 * @export
 * @interface ResourceGroupData
 */
export interface ResourceGroupData {
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupData
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupData
     */
    accountName?: string;
    /**
     * 
     * @type {object}
     * @memberof ResourceGroupData
     */
    tolerations: object;
    /**
     * 
     * @type {object}
     * @memberof ResourceGroupData
     */
    affinity: object;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupData
     */
    ownerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupData
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupData
     */
    enabledAutoScaling?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupData
     */
    enabledEviction?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupData
     */
    resourceGroupType?: ResourceGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupData
     */
    access?: AccessEnum;
    /**
     * 
     * @type {ResourceGroupAutoScalingConfiguration}
     * @memberof ResourceGroupData
     */
    autoScalingConfiguration?: ResourceGroupAutoScalingConfiguration;
}

/**
    * @export
    * @enum {string}
    */
export enum ResourceGroupTypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}
/**
    * @export
    * @enum {string}
    */
export enum AccessEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    SHAREDPOOL = 'SHARED_POOL'
}

/**
 * 
 * @export
 * @interface ResourceGroupEssentialData
 */
export interface ResourceGroupEssentialData {
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupEssentialData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupEssentialData
     */
    ownerId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupEssentialData
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupEssentialData
     */
    access: AccessEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupEssentialData
     */
    enabledEviction: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupEssentialData
     */
    resourceGroupType: ResourceGroupTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupEssentialData
     */
    enabledAutoScaling: boolean;
    /**
     * 
     * @type {ResourceGroupAutoScalingConfiguration}
     * @memberof ResourceGroupEssentialData
     */
    autoScalingConfiguration?: ResourceGroupAutoScalingConfiguration;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    SHAREDPOOL = 'SHARED_POOL'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourceGroupTypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}

/**
 * 
 * @export
 * @interface ResourceGroupServerData
 */
export interface ResourceGroupServerData {
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupServerData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    serverIp?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    jumpHostIp?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupServerData
     */
    isAutoCreated: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    rawConfiguration: string;
    /**
     * 
     * @type {ServerCapacityData}
     * @memberof ResourceGroupServerData
     */
    templateCapacity?: ServerCapacityData;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupServerData
     */
    tarifficationPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerData
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface ResourceGroupServerDataWithStatus
 */
export interface ResourceGroupServerDataWithStatus {
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupServerDataWithStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    status: StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    serverIp?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    jumpHostIp?: string;
    /**
     * 
     * @type {ServerCapacityData}
     * @memberof ResourceGroupServerDataWithStatus
     */
    resources: ServerCapacityData;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupServerDataWithStatus
     */
    isAutoCreated: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    rawConfiguration: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupServerDataWithStatus
     */
    tarifficationPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupServerDataWithStatus
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StatusEnum {
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    STOPPED = 'STOPPED',
    UNAVAILABLE = 'UNAVAILABLE',
    DELETING = 'DELETING'
}
/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface ResourceGroupServicesData
 */
export interface ResourceGroupServicesData {
    /**
     * 
     * @type {Array<ServiceData>}
     * @memberof ResourceGroupServicesData
     */
    services: Array<ServiceData>;
}
/**
 * 
 * @export
 * @interface ResourceGroupServicesShortData
 */
export interface ResourceGroupServicesShortData {
    /**
     * 
     * @type {Array<ServiceShortData>}
     * @memberof ResourceGroupServicesShortData
     */
    services: Array<ServiceShortData>;
}
/**
 * 
 * @export
 * @interface ResourceGroupShortStatusData
 */
export interface ResourceGroupShortStatusData {
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupShortStatusData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupShortStatusData
     */
    ownerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupShortStatusData
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupShortStatusData
     */
    enabledEviction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceGroupShortStatusData
     */
    enabledAutoScaling: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupShortStatusData
     */
    resourceGroupType: ResourceGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ResourceGroupShortStatusData
     */
    access: AccessEnum;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupShortStatusData
     */
    serversCount: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceGroupShortStatusData
     */
    servicesCount: number;
    /**
     * 
     * @type {ResourceGroupAutoScalingConfiguration}
     * @memberof ResourceGroupShortStatusData
     */
    autoScalingConfiguration?: ResourceGroupAutoScalingConfiguration;
}

/**
    * @export
    * @enum {string}
    */
export enum ResourceGroupTypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}
/**
    * @export
    * @enum {string}
    */
export enum AccessEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    SHAREDPOOL = 'SHARED_POOL'
}

/**
 * 
 * @export
 * @interface ResourceGroupsData
 */
export interface ResourceGroupsData {
    /**
     * 
     * @type {Array<ResourceGroupData>}
     * @memberof ResourceGroupsData
     */
    groups: Array<ResourceGroupData>;
}
/**
 * 
 * @export
 * @interface ResponseBodyEmitter
 */
export interface ResponseBodyEmitter {
    /**
     * 
     * @type {number}
     * @memberof ResponseBodyEmitter
     */
    timeout?: number;
}
/**
 * 
 * @export
 * @interface S3CredentialsData
 */
export interface S3CredentialsData {
    /**
     * 
     * @type {string}
     * @memberof S3CredentialsData
     */
    s3Url: string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialsData
     */
    accessKey: string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialsData
     */
    secretKey: string;
    /**
     * 
     * @type {string}
     * @memberof S3CredentialsData
     */
    bucketName: string;
}
/**
 * 
 * @export
 * @interface SchemaFileData
 */
export interface SchemaFileData {
    /**
     * 
     * @type {string}
     * @memberof SchemaFileData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaFileData
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ServerCapacityData
 */
export interface ServerCapacityData {
    /**
     * 
     * @type {string}
     * @memberof ServerCapacityData
     */
    cpu: string;
    /**
     * 
     * @type {string}
     * @memberof ServerCapacityData
     */
    memory: string;
    /**
     * 
     * @type {string}
     * @memberof ServerCapacityData
     */
    disk: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerCapacityData
     */
    gpuModels: Array<string>;
}
/**
 * 
 * @export
 * @interface ServerTemplateData
 */
export interface ServerTemplateData {
    /**
     * 
     * @type {number}
     * @memberof ServerTemplateData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateData
     */
    type: TypeEnum;
    /**
     * 
     * @type {ServerCapacityData}
     * @memberof ServerTemplateData
     */
    capacity?: ServerCapacityData;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateData
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateData
     */
    rawConfiguration: string;
    /**
     * 
     * @type {number}
     * @memberof ServerTemplateData
     */
    tarifficationPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateData
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}
/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface ServerTemplateDump
 */
export interface ServerTemplateDump {
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateDump
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateDump
     */
    type: TypeEnum;
    /**
     * 
     * @type {ServerCapacityData}
     * @memberof ServerTemplateDump
     */
    capacity?: ServerCapacityData;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateDump
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateDump
     */
    configuration: string;
    /**
     * 
     * @type {number}
     * @memberof ServerTemplateDump
     */
    tarifficationPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ServerTemplateDump
     */
    tarifficationPeriod?: TarifficationPeriodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}
/**
    * @export
    * @enum {string}
    */
export enum TarifficationPeriodEnum {
    SECOND = 'SECOND',
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

/**
 * 
 * @export
 * @interface ServiceData
 */
export interface ServiceData {
    /**
     * 
     * @type {ModelInfoPK}
     * @memberof ServiceData
     */
    id: ModelInfoPK;
    /**
     * 
     * @type {string}
     * @memberof ServiceData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceData
     */
    state: StateEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceData
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceData
     */
    requested: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StateEnum {
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    INACTIVE = 'INACTIVE',
    SLEEPING = 'SLEEPING'
}

/**
 * 
 * @export
 * @interface ServiceDescriptorData
 */
export interface ServiceDescriptorData {
    /**
     * 
     * @type {string}
     * @memberof ServiceDescriptorData
     */
    name: string;
    /**
     * 
     * @type {Array<SchemaFileData>}
     * @memberof ServiceDescriptorData
     */
    schemaFiles: Array<SchemaFileData>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceDescriptorData
     */
    fittable: boolean;
    /**
     * 
     * @type {{ [key: string]: MethodDescriptorData; }}
     * @memberof ServiceDescriptorData
     */
    methods: { [key: string]: MethodDescriptorData; };
}
/**
 * 
 * @export
 * @interface ServiceInfoAtTime
 */
export interface ServiceInfoAtTime {
    /**
     * 
     * @type {number}
     * @memberof ServiceInfoAtTime
     */
    modelId: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceInfoAtTime
     */
    modelNameAtTime: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInfoAtTime
     */
    actualModelName?: string;
}
/**
 * 
 * @export
 * @interface ServiceShortData
 */
export interface ServiceShortData {
    /**
     * 
     * @type {ModelInfoPK}
     * @memberof ServiceShortData
     */
    id: ModelInfoPK;
    /**
     * 
     * @type {string}
     * @memberof ServiceShortData
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SharedPoolQuota
 */
export interface SharedPoolQuota {
    /**
     * 
     * @type {number}
     * @memberof SharedPoolQuota
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SharedPoolQuota
     */
    ownerId: number;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    ownerName: string;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    groupName: string;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    groupType: GroupTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof SharedPoolQuota
     */
    accessPolicy: object;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    grantedAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    cpuLimit: string;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    memoryLimit: string;
    /**
     * 
     * @type {string}
     * @memberof SharedPoolQuota
     */
    ephemeralDiskLimit: string;
    /**
     * 
     * @type {number}
     * @memberof SharedPoolQuota
     */
    gpuInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof SharedPoolQuota
     */
    baseInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof SharedPoolQuota
     */
    derivedInstancesLimit: number;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupTypeEnum {
    DOCKER = 'DOCKER',
    KUBERNETES = 'KUBERNETES',
    HOSTINGSERVER = 'HOSTING_SERVER',
    SHAREDRESOURCEQUOTA = 'SHARED_RESOURCE_QUOTA'
}

/**
 * 
 * @export
 * @interface ShortJobView
 */
export interface ShortJobView {
    /**
     * 
     * @type {string}
     * @memberof ShortJobView
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof ShortJobView
     */
    title: string;
    /**
     * 
     * @type {Array<ShortJobView>}
     * @memberof ShortJobView
     */
    children: Array<ShortJobView>;
    /**
     * 
     * @type {number}
     * @memberof ShortJobView
     */
    percentage: number;
    /**
     * 
     * @type {string}
     * @memberof ShortJobView
     */
    viewStatus: ViewStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortJobView
     */
    currentStep: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ViewStatusEnum {
    IDLE = 'IDLE',
    RUN = 'RUN',
    REVERTING = 'REVERTING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    REVERTED = 'REVERTED'
}

/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface StatLogData
 */
export interface StatLogData {
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    recordId: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    node?: string;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    imageId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    modelId?: number;
    /**
     * 
     * @type {number}
     * @memberof StatLogData
     */
    jobId?: number;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    resourceGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    level: LevelEnum;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    code: string;
    /**
     * 
     * @type {object}
     * @memberof StatLogData
     */
    args: object;
    /**
     * 
     * @type {string}
     * @memberof StatLogData
     */
    message: string;
}

/**
    * @export
    * @enum {string}
    */
export enum LevelEnum {
    INFO = 'INFO',
    ERROR = 'ERROR'
}

/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {boolean}
     * @memberof Status
     */
    ok: boolean;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    message: string;
}
/**
 * 
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
    /**
     * 
     * @type {string}
     * @memberof StatusInfo
     */
    totalStatus: TotalStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StatusInfo
     */
    isDeleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatusInfo
     */
    isConnectedToAllGates: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatusInfo
     */
    podStatus?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TotalStatusEnum {
    RUNNING = 'RUNNING',
    WAITING = 'WAITING',
    SLEEPING = 'SLEEPING',
    FAILED = 'FAILED',
    DELETED = 'DELETED'
}

/**
 * 
 * @export
 * @interface TaskSuiteStatus
 */
export interface TaskSuiteStatus {
    /**
     * 
     * @type {number}
     * @memberof TaskSuiteStatus
     */
    accountId: number;
    /**
     * 
     * @type {number}
     * @memberof TaskSuiteStatus
     */
    originalDatasetId: number;
    /**
     * 
     * @type {number}
     * @memberof TaskSuiteStatus
     */
    newDatasetId: number;
    /**
     * 
     * @type {Date}
     * @memberof TaskSuiteStatus
     */
    created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TaskSuiteStatus
     */
    completed: boolean;
}
/**
 * 
 * @export
 * @interface TaskTypeData
 */
export interface TaskTypeData {
    /**
     * 
     * @type {string}
     * @memberof TaskTypeData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTypeData
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTypeData
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTypeData
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface TaskTypeToModelsCount
 */
export interface TaskTypeToModelsCount {
    /**
     * 
     * @type {string}
     * @memberof TaskTypeToModelsCount
     */
    taskTypeName: string;
    /**
     * 
     * @type {number}
     * @memberof TaskTypeToModelsCount
     */
    modelsCount: number;
}
/**
 * 
 * @export
 * @interface TtsRequestData
 */
export interface TtsRequestData {
    /**
     * 
     * @type {string}
     * @memberof TtsRequestData
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof TtsRequestData
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof TtsRequestData
     */
    voice?: string;
    /**
     * 
     * @type {AudioFormatOptions}
     * @memberof TtsRequestData
     */
    outputAudioSpec?: AudioFormatOptions;
}
/**
 * 
 * @export
 * @interface UpdateResourceGroupData
 */
export interface UpdateResourceGroupData {
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceGroupData
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResourceGroupData
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResourceGroupData
     */
    enabledEviction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResourceGroupData
     */
    enabledAutoScaling?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceGroupData
     */
    access?: AccessEnum;
    /**
     * 
     * @type {ResourceGroupAutoScalingConfiguration}
     * @memberof UpdateResourceGroupData
     */
    autoScalingConfiguration?: ResourceGroupAutoScalingConfiguration;
}

/**
    * @export
    * @enum {string}
    */
export enum AccessEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    SHAREDPOOL = 'SHARED_POOL'
}

/**
 * 
 * @export
 * @interface UpdateResourceGroupQuota
 */
export interface UpdateResourceGroupQuota {
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceGroupQuota
     */
    cpuLimit: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceGroupQuota
     */
    memoryLimit: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceGroupQuota
     */
    ephemeralDiskLimit: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateResourceGroupQuota
     */
    gpuInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateResourceGroupQuota
     */
    baseInstancesLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateResourceGroupQuota
     */
    derivedInstancesLimit: number;
}

/**
 * AccessTokenEndpointApi - axios parameter creator
 * @export
 */
export const AccessTokenEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToken(account: string, token: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling checkAccessToken.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling checkAccessToken.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/token/{token}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(account: string, name?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createAccessToken.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/token`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessToken(account: string, token: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteAccessToken.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteAccessToken.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/token/{token}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessTokens(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listAccessTokens.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/token`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessTokenEndpointApi - functional programming interface
 * @export
 */
export const AccessTokenEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenData> {
            const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).checkAccessToken(account, token, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(account: string, name?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenData> {
            const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).createAccessToken(account, name, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).deleteAccessToken(account, token, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessTokens(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccessTokenData>> {
            const localVarAxiosArgs = AccessTokenEndpointApiAxiosParamCreator(configuration).listAccessTokens(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccessTokenEndpointApi - factory interface
 * @export
 */
export const AccessTokenEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccessTokenData> {
            return AccessTokenEndpointApiFp(configuration).checkAccessToken(account, token, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccessToken(account: string, name?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccessTokenData> {
            return AccessTokenEndpointApiFp(configuration).createAccessToken(account, name, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} token 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AccessTokenEndpointApiFp(configuration).deleteAccessToken(account, token, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccessTokens(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<AccessTokenData>> {
            return AccessTokenEndpointApiFp(configuration).listAccessTokens(account, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * AccessTokenEndpointApi - object-oriented interface
 * @export
 * @class AccessTokenEndpointApi
 * @extends {BaseAPI}
 */
export class AccessTokenEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} token 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenEndpointApi
     */
    public checkAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any) {
        return AccessTokenEndpointApiFp(this.configuration).checkAccessToken(account, token, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [name] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenEndpointApi
     */
    public createAccessToken(account: string, name?: string, MLP_API_KEY?: string, options?: any) {
        return AccessTokenEndpointApiFp(this.configuration).createAccessToken(account, name, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} token 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenEndpointApi
     */
    public deleteAccessToken(account: string, token: string, MLP_API_KEY?: string, options?: any) {
        return AccessTokenEndpointApiFp(this.configuration).deleteAccessToken(account, token, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessTokenEndpointApi
     */
    public listAccessTokens(account: string, MLP_API_KEY?: string, options?: any) {
        return AccessTokenEndpointApiFp(this.configuration).listAccessTokens(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * AccountEndpointApi - axios parameter creator
 * @export
 */
export const AccountEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dumpConfig(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling dumpConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dump`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccount(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccountWithId(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ensureAccountWithId.');
            }
            const localVarPath = `/api/mlpcore/account/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPublicName(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountPublicName.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/publicName`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Credentials(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getS3Credentials.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/s3`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreConfig(account: string, body: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling restoreConfig.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling restoreConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dump`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountInfoData} accountInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountData(accountInfoData: AccountInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountInfoData' is not null or undefined
            if (accountInfoData === null || accountInfoData === undefined) {
                throw new RequiredError('accountInfoData','Required parameter accountInfoData was null or undefined when calling updateAccountData.');
            }
            const localVarPath = `/api/mlpcore/account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountInfoData !== undefined ? accountInfoData : {}) : (accountInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity1(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateLastActivity1.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/activity`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountEndpointApi - functional programming interface
 * @export
 */
export const AccountEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dumpConfig(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).dumpConfig(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccount(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).ensureAccount(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccountWithId(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).ensureAccountWithId(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPublicName(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).getAccountPublicName(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Credentials(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3CredentialsData> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).getS3Credentials(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreConfig(account: string, body: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).restoreConfig(account, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountInfoData} accountInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountData(accountInfoData: AccountInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).updateAccountData(accountInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity1(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountEndpointApiAxiosParamCreator(configuration).updateLastActivity1(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountEndpointApi - factory interface
 * @export
 */
export const AccountEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dumpConfig(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return AccountEndpointApiFp(configuration).dumpConfig(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccount(MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return AccountEndpointApiFp(configuration).ensureAccount(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureAccountWithId(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return AccountEndpointApiFp(configuration).ensureAccountWithId(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPublicName(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return AccountEndpointApiFp(configuration).getAccountPublicName(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Credentials(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<S3CredentialsData> {
            return AccountEndpointApiFp(configuration).getS3Credentials(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreConfig(account: string, body: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AccountEndpointApiFp(configuration).restoreConfig(account, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {AccountInfoData} accountInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountData(accountInfoData: AccountInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return AccountEndpointApiFp(configuration).updateAccountData(accountInfoData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity1(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AccountEndpointApiFp(configuration).updateLastActivity1(account, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * AccountEndpointApi - object-oriented interface
 * @export
 * @class AccountEndpointApi
 * @extends {BaseAPI}
 */
export class AccountEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public dumpConfig(account: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).dumpConfig(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public ensureAccount(MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).ensureAccount(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public ensureAccountWithId(account: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).ensureAccountWithId(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public getAccountPublicName(account: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).getAccountPublicName(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public getS3Credentials(account: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).getS3Credentials(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public restoreConfig(account: string, body: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).restoreConfig(account, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {AccountInfoData} accountInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public updateAccountData(accountInfoData: AccountInfoData, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).updateAccountData(accountInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEndpointApi
     */
    public updateLastActivity1(account: string, MLP_API_KEY?: string, options?: any) {
        return AccountEndpointApiFp(this.configuration).updateLastActivity1(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * AdminEndpointApi - axios parameter creator
 * @export
 */
export const AdminEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} count 
         * @param {boolean} dryRun 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmptyMinioUsers(count: number, dryRun: boolean, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'count' is not null or undefined
            if (count === null || count === undefined) {
                throw new RequiredError('count','Required parameter count was null or undefined when calling deleteEmptyMinioUsers.');
            }
            // verify required parameter 'dryRun' is not null or undefined
            if (dryRun === null || dryRun === undefined) {
                throw new RequiredError('dryRun','Required parameter dryRun was null or undefined when calling deleteEmptyMinioUsers.');
            }
            const localVarPath = `/api/mlpcore/admin/minio/delete-empty-users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccount.');
            }
            const localVarPath = `/api/mlpcore/admin/account/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountConfigDump(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountConfigDump.');
            }
            const localVarPath = `/api/mlpcore/admin/system-config/account/{account}/config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDataDump(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountDataDump.');
            }
            const localVarPath = `/api/mlpcore/admin/system-config/account/{account}/data`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountFeatures.');
            }
            const localVarPath = `/api/mlpcore/admin/account/{account}/features`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(name?: string, limit?: number, skip?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/admin/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [account] Account id or account name
         * @param {string} [fromDate] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(account?: string, fromDate?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/admin/request-metrics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/admin/resource-groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sourceGroup 
         * @param {string} [targetGroup] 
         * @param {string} [targetGroupRegex] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAllServicesToResourceGroup(sourceGroup: string, targetGroup?: string, targetGroupRegex?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'sourceGroup' is not null or undefined
            if (sourceGroup === null || sourceGroup === undefined) {
                throw new RequiredError('sourceGroup','Required parameter sourceGroup was null or undefined when calling migrateAllServicesToResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/admin/models/resource-group/migrate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceGroup !== undefined) {
                localVarQueryParameter['sourceGroup'] = sourceGroup;
            }

            if (targetGroup !== undefined) {
                localVarQueryParameter['targetGroup'] = targetGroup;
            }

            if (targetGroupRegex !== undefined) {
                localVarQueryParameter['targetGroupRegex'] = targetGroupRegex;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {string} account Account id or account name
         * @param {AccountConfigDump} accountConfigDump 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountConfigDump(dryRun: boolean, account: string, accountConfigDump: AccountConfigDump, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dryRun' is not null or undefined
            if (dryRun === null || dryRun === undefined) {
                throw new RequiredError('dryRun','Required parameter dryRun was null or undefined when calling restoreAccountConfigDump.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling restoreAccountConfigDump.');
            }
            // verify required parameter 'accountConfigDump' is not null or undefined
            if (accountConfigDump === null || accountConfigDump === undefined) {
                throw new RequiredError('accountConfigDump','Required parameter accountConfigDump was null or undefined when calling restoreAccountConfigDump.');
            }
            const localVarPath = `/api/mlpcore/admin/system-config/account/{account}/config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountConfigDump !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountConfigDump !== undefined ? accountConfigDump : {}) : (accountConfigDump || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {boolean} noErrors 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountDataDump(dryRun: boolean, noErrors: boolean, account: string, body: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'dryRun' is not null or undefined
            if (dryRun === null || dryRun === undefined) {
                throw new RequiredError('dryRun','Required parameter dryRun was null or undefined when calling restoreAccountDataDump.');
            }
            // verify required parameter 'noErrors' is not null or undefined
            if (noErrors === null || noErrors === undefined) {
                throw new RequiredError('noErrors','Required parameter noErrors was null or undefined when calling restoreAccountDataDump.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling restoreAccountDataDump.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling restoreAccountDataDump.');
            }
            const localVarPath = `/api/mlpcore/admin/system-config/account/{account}/data`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (noErrors !== undefined) {
                localVarQueryParameter['noErrors'] = noErrors;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain;charset=UTF-8';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyK8s] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollingUpdateAllInstances(onlyK8s?: boolean, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/admin/instances/rolling-update`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyK8s !== undefined) {
                localVarQueryParameter['onlyK8s'] = onlyK8s;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<RequestMetricsData>} requestMetricsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetrics(requestMetricsData: Array<RequestMetricsData>, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'requestMetricsData' is not null or undefined
            if (requestMetricsData === null || requestMetricsData === undefined) {
                throw new RequiredError('requestMetricsData','Required parameter requestMetricsData was null or undefined when calling saveMetrics.');
            }
            const localVarPath = `/api/mlpcore/admin/request-metrics/save`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestMetricsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestMetricsData !== undefined ? requestMetricsData : {}) : (requestMetricsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateFeatures.');
            }
            // verify required parameter 'accountFeatureData' is not null or undefined
            if (accountFeatureData === null || accountFeatureData === undefined) {
                throw new RequiredError('accountFeatureData','Required parameter accountFeatureData was null or undefined when calling updateFeatures.');
            }
            const localVarPath = `/api/mlpcore/admin/account/{account}/features`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountFeatureData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountFeatureData !== undefined ? accountFeatureData : {}) : (accountFeatureData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<LastActivityPk>} lastActivityPk 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivities(lastActivityPk: Array<LastActivityPk>, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'lastActivityPk' is not null or undefined
            if (lastActivityPk === null || lastActivityPk === undefined) {
                throw new RequiredError('lastActivityPk','Required parameter lastActivityPk was null or undefined when calling updateLastActivities.');
            }
            const localVarPath = `/api/mlpcore/admin/update-activities`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof lastActivityPk !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(lastActivityPk !== undefined ? lastActivityPk : {}) : (lastActivityPk || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateLimits.');
            }
            // verify required parameter 'accountLimitsData' is not null or undefined
            if (accountLimitsData === null || accountLimitsData === undefined) {
                throw new RequiredError('accountLimitsData','Required parameter accountLimitsData was null or undefined when calling updateLimits.');
            }
            const localVarPath = `/api/mlpcore/admin/account/{account}/limits`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountLimitsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountLimitsData !== undefined ? accountLimitsData : {}) : (accountLimitsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'resourceGroupsData' is not null or undefined
            if (resourceGroupsData === null || resourceGroupsData === undefined) {
                throw new RequiredError('resourceGroupsData','Required parameter resourceGroupsData was null or undefined when calling updateResourceGroups.');
            }
            const localVarPath = `/api/mlpcore/admin/resource-groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof resourceGroupsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resourceGroupsData !== undefined ? resourceGroupsData : {}) : (resourceGroupsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEndpointApi - functional programming interface
 * @export
 */
export const AdminEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} count 
         * @param {boolean} dryRun 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmptyMinioUsers(count: number, dryRun: boolean, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmptyUsersResponse> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).deleteEmptyMinioUsers(count, dryRun, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccount(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountConfigDump(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountConfigDump> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccountConfigDump(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDataDump(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDataDump> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccountDataDump(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFeatureData> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccountFeatures(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(name?: string, limit?: number, skip?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountInfoData>> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getAccounts(name, limit, skip, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [account] Account id or account name
         * @param {string} [fromDate] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(account?: string, fromDate?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverallMetricsData> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getMetrics(account, fromDate, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupsData> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).getResourceGroups(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} sourceGroup 
         * @param {string} [targetGroup] 
         * @param {string} [targetGroupRegex] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAllServicesToResourceGroup(sourceGroup: string, targetGroup?: string, targetGroupRegex?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).migrateAllServicesToResourceGroup(sourceGroup, targetGroup, targetGroupRegex, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {string} account Account id or account name
         * @param {AccountConfigDump} accountConfigDump 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountConfigDump(dryRun: boolean, account: string, accountConfigDump: AccountConfigDump, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DifferenceIAccountConfigDump> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).restoreAccountConfigDump(dryRun, account, accountConfigDump, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {boolean} noErrors 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountDataDump(dryRun: boolean, noErrors: boolean, account: string, body: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DifferenceIAccountDataDump> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).restoreAccountDataDump(dryRun, noErrors, account, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [onlyK8s] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollingUpdateAllInstances(onlyK8s?: boolean, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).rollingUpdateAllInstances(onlyK8s, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<RequestMetricsData>} requestMetricsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetrics(requestMetricsData: Array<RequestMetricsData>, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).saveMetrics(requestMetricsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateFeatures(account, accountFeatureData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<LastActivityPk>} lastActivityPk 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivities(lastActivityPk: Array<LastActivityPk>, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateLastActivities(lastActivityPk, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateLimits(account, accountLimitsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = AdminEndpointApiAxiosParamCreator(configuration).updateResourceGroups(resourceGroupsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminEndpointApi - factory interface
 * @export
 */
export const AdminEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} count 
         * @param {boolean} dryRun 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmptyMinioUsers(count: number, dryRun: boolean, MLP_API_KEY?: string, options?: any): AxiosPromise<EmptyUsersResponse> {
            return AdminEndpointApiFp(configuration).deleteEmptyMinioUsers(count, dryRun, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return AdminEndpointApiFp(configuration).getAccount(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountConfigDump(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountConfigDump> {
            return AdminEndpointApiFp(configuration).getAccountConfigDump(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDataDump(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountDataDump> {
            return AdminEndpointApiFp(configuration).getAccountDataDump(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountFeatureData> {
            return AdminEndpointApiFp(configuration).getAccountFeatures(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(name?: string, limit?: number, skip?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<AccountInfoData>> {
            return AdminEndpointApiFp(configuration).getAccounts(name, limit, skip, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [account] Account id or account name
         * @param {string} [fromDate] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(account?: string, fromDate?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<OverallMetricsData> {
            return AdminEndpointApiFp(configuration).getMetrics(account, fromDate, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups(MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupsData> {
            return AdminEndpointApiFp(configuration).getResourceGroups(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} sourceGroup 
         * @param {string} [targetGroup] 
         * @param {string} [targetGroupRegex] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAllServicesToResourceGroup(sourceGroup: string, targetGroup?: string, targetGroupRegex?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return AdminEndpointApiFp(configuration).migrateAllServicesToResourceGroup(sourceGroup, targetGroup, targetGroupRegex, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {string} account Account id or account name
         * @param {AccountConfigDump} accountConfigDump 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountConfigDump(dryRun: boolean, account: string, accountConfigDump: AccountConfigDump, MLP_API_KEY?: string, options?: any): AxiosPromise<DifferenceIAccountConfigDump> {
            return AdminEndpointApiFp(configuration).restoreAccountConfigDump(dryRun, account, accountConfigDump, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} dryRun 
         * @param {boolean} noErrors 
         * @param {string} account Account id or account name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccountDataDump(dryRun: boolean, noErrors: boolean, account: string, body: string, MLP_API_KEY?: string, options?: any): AxiosPromise<DifferenceIAccountDataDump> {
            return AdminEndpointApiFp(configuration).restoreAccountDataDump(dryRun, noErrors, account, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [onlyK8s] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollingUpdateAllInstances(onlyK8s?: boolean, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return AdminEndpointApiFp(configuration).rollingUpdateAllInstances(onlyK8s, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<RequestMetricsData>} requestMetricsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetrics(requestMetricsData: Array<RequestMetricsData>, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AdminEndpointApiFp(configuration).saveMetrics(requestMetricsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AdminEndpointApiFp(configuration).updateFeatures(account, accountFeatureData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<LastActivityPk>} lastActivityPk 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivities(lastActivityPk: Array<LastActivityPk>, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return AdminEndpointApiFp(configuration).updateLastActivities(lastActivityPk, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return AdminEndpointApiFp(configuration).updateLimits(account, accountLimitsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any): AxiosPromise<object> {
            return AdminEndpointApiFp(configuration).updateResourceGroups(resourceGroupsData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * AdminEndpointApi - object-oriented interface
 * @export
 * @class AdminEndpointApi
 * @extends {BaseAPI}
 */
export class AdminEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} count 
     * @param {boolean} dryRun 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public deleteEmptyMinioUsers(count: number, dryRun: boolean, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).deleteEmptyMinioUsers(count, dryRun, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getAccount(account: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getAccount(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getAccountConfigDump(account: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getAccountConfigDump(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getAccountDataDump(account: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getAccountDataDump(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getAccountFeatures(account: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getAccountFeatures(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getAccounts(name?: string, limit?: number, skip?: number, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getAccounts(name, limit, skip, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [account] Account id or account name
     * @param {string} [fromDate] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getMetrics(account?: string, fromDate?: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getMetrics(account, fromDate, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public getResourceGroups(MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).getResourceGroups(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} sourceGroup 
     * @param {string} [targetGroup] 
     * @param {string} [targetGroupRegex] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public migrateAllServicesToResourceGroup(sourceGroup: string, targetGroup?: string, targetGroupRegex?: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).migrateAllServicesToResourceGroup(sourceGroup, targetGroup, targetGroupRegex, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} dryRun 
     * @param {string} account Account id or account name
     * @param {AccountConfigDump} accountConfigDump 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public restoreAccountConfigDump(dryRun: boolean, account: string, accountConfigDump: AccountConfigDump, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).restoreAccountConfigDump(dryRun, account, accountConfigDump, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} dryRun 
     * @param {boolean} noErrors 
     * @param {string} account Account id or account name
     * @param {string} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public restoreAccountDataDump(dryRun: boolean, noErrors: boolean, account: string, body: string, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).restoreAccountDataDump(dryRun, noErrors, account, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [onlyK8s] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public rollingUpdateAllInstances(onlyK8s?: boolean, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).rollingUpdateAllInstances(onlyK8s, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<RequestMetricsData>} requestMetricsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public saveMetrics(requestMetricsData: Array<RequestMetricsData>, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).saveMetrics(requestMetricsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {AccountFeatureData} accountFeatureData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public updateFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).updateFeatures(account, accountFeatureData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<LastActivityPk>} lastActivityPk 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public updateLastActivities(lastActivityPk: Array<LastActivityPk>, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).updateLastActivities(lastActivityPk, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {AccountLimitsData} accountLimitsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public updateLimits(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).updateLimits(account, accountLimitsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ResourceGroupsData} resourceGroupsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEndpointApi
     */
    public updateResourceGroups(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any) {
        return AdminEndpointApiFp(this.configuration).updateResourceGroups(resourceGroupsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ApplicationEndpointApi - axios parameter creator
 * @export
 */
export const ApplicationEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth(account: string, application: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling auth.');
            }
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling auth.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/application/{application}/auth`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAppIsAlive(account: string, application: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling checkAppIsAlive.');
            }
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling checkAppIsAlive.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/application/{application}/health`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureApplicationToken(account: string, application: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ensureApplicationToken.');
            }
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling ensureApplicationToken.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/application/{application}/token`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setApplicationCookies(account: string, application: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling setApplicationCookies.');
            }
            // verify required parameter 'application' is not null or undefined
            if (application === null || application === undefined) {
                throw new RequiredError('application','Required parameter application was null or undefined when calling setApplicationCookies.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/application/{application}/set-cookie`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"application"}}`, encodeURIComponent(String(application)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationEndpointApi - functional programming interface
 * @export
 */
export const ApplicationEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth(account: string, application: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = ApplicationEndpointApiAxiosParamCreator(configuration).auth(account, application, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAppIsAlive(account: string, application: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = ApplicationEndpointApiAxiosParamCreator(configuration).checkAppIsAlive(account, application, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureApplicationToken(account: string, application: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ApplicationEndpointApiAxiosParamCreator(configuration).ensureApplicationToken(account, application, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setApplicationCookies(account: string, application: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ApplicationEndpointApiAxiosParamCreator(configuration).setApplicationCookies(account, application, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationEndpointApi - factory interface
 * @export
 */
export const ApplicationEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth(account: string, application: string, MLP_API_KEY?: string, options?: any): AxiosPromise<object> {
            return ApplicationEndpointApiFp(configuration).auth(account, application, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAppIsAlive(account: string, application: string, MLP_API_KEY?: string, options?: any): AxiosPromise<object> {
            return ApplicationEndpointApiFp(configuration).checkAppIsAlive(account, application, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureApplicationToken(account: string, application: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ApplicationEndpointApiFp(configuration).ensureApplicationToken(account, application, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} application Application id or application name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setApplicationCookies(account: string, application: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ApplicationEndpointApiFp(configuration).setApplicationCookies(account, application, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ApplicationEndpointApi - object-oriented interface
 * @export
 * @class ApplicationEndpointApi
 * @extends {BaseAPI}
 */
export class ApplicationEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} application Application id or application name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationEndpointApi
     */
    public auth(account: string, application: string, MLP_API_KEY?: string, options?: any) {
        return ApplicationEndpointApiFp(this.configuration).auth(account, application, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} application Application id or application name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationEndpointApi
     */
    public checkAppIsAlive(account: string, application: string, MLP_API_KEY?: string, options?: any) {
        return ApplicationEndpointApiFp(this.configuration).checkAppIsAlive(account, application, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} application Application id or application name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationEndpointApi
     */
    public ensureApplicationToken(account: string, application: string, MLP_API_KEY?: string, options?: any) {
        return ApplicationEndpointApiFp(this.configuration).ensureApplicationToken(account, application, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} application Application id or application name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationEndpointApi
     */
    public setApplicationCookies(account: string, application: string, MLP_API_KEY?: string, options?: any) {
        return ApplicationEndpointApiFp(this.configuration).setApplicationCookies(account, application, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ClickHistoryEndpointApi - axios parameter creator
 * @export
 */
export const ClickHistoryEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ClickHistoryData>} clickHistoryData 
         * @param {string} [visitorId] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveClick(clickHistoryData: Array<ClickHistoryData>, visitorId?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'clickHistoryData' is not null or undefined
            if (clickHistoryData === null || clickHistoryData === undefined) {
                throw new RequiredError('clickHistoryData','Required parameter clickHistoryData was null or undefined when calling saveClick.');
            }
            const localVarPath = `/api/mlpcore/clicks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof clickHistoryData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clickHistoryData !== undefined ? clickHistoryData : {}) : (clickHistoryData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClickHistoryEndpointApi - functional programming interface
 * @export
 */
export const ClickHistoryEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ClickHistoryData>} clickHistoryData 
         * @param {string} [visitorId] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveClick(clickHistoryData: Array<ClickHistoryData>, visitorId?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ClickHistoryEndpointApiAxiosParamCreator(configuration).saveClick(clickHistoryData, visitorId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClickHistoryEndpointApi - factory interface
 * @export
 */
export const ClickHistoryEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<ClickHistoryData>} clickHistoryData 
         * @param {string} [visitorId] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveClick(clickHistoryData: Array<ClickHistoryData>, visitorId?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ClickHistoryEndpointApiFp(configuration).saveClick(clickHistoryData, visitorId, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ClickHistoryEndpointApi - object-oriented interface
 * @export
 * @class ClickHistoryEndpointApi
 * @extends {BaseAPI}
 */
export class ClickHistoryEndpointApi extends BaseAPI {
    /**
     * 
     * @param {Array<ClickHistoryData>} clickHistoryData 
     * @param {string} [visitorId] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClickHistoryEndpointApi
     */
    public saveClick(clickHistoryData: Array<ClickHistoryData>, visitorId?: string, MLP_API_KEY?: string, options?: any) {
        return ClickHistoryEndpointApiFp(this.configuration).saveClick(clickHistoryData, visitorId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * DataImageEndpointApi - axios parameter creator
 * @export
 */
export const DataImageEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataImage(account: string, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createDataImage.');
            }
            // verify required parameter 'dataImageInfoData' is not null or undefined
            if (dataImageInfoData === null || dataImageInfoData === undefined) {
                throw new RequiredError('dataImageInfoData','Required parameter dataImageInfoData was null or undefined when calling createDataImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/data-image`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof dataImageInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dataImageInfoData !== undefined ? dataImageInfoData : {}) : (dataImageInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataImage(account: string, imageId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteDataImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling deleteDataImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/data-image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataImage(account: string, imageId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getDataImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling getDataImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/data-image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedDataImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPagedDataImages.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/data-image`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (onlyMy !== undefined) {
                localVarQueryParameter['onlyMy'] = onlyMy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataImage(account: string, imageId: number, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateDataImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling updateDataImage.');
            }
            // verify required parameter 'dataImageInfoData' is not null or undefined
            if (dataImageInfoData === null || dataImageInfoData === undefined) {
                throw new RequiredError('dataImageInfoData','Required parameter dataImageInfoData was null or undefined when calling updateDataImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/data-image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof dataImageInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dataImageInfoData !== undefined ? dataImageInfoData : {}) : (dataImageInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataImageEndpointApi - functional programming interface
 * @export
 */
export const DataImageEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataImage(account: string, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
            const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).createDataImage(account, dataImageInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).deleteDataImage(account, imageId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
            const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).getDataImage(account, imageId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedDataImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDataImageInfoData> {
            const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).getPagedDataImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataImage(account: string, imageId: number, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataImageInfoData> {
            const localVarAxiosArgs = DataImageEndpointApiAxiosParamCreator(configuration).updateDataImage(account, imageId, dataImageInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DataImageEndpointApi - factory interface
 * @export
 */
export const DataImageEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataImage(account: string, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<DataImageInfoData> {
            return DataImageEndpointApiFp(configuration).createDataImage(account, dataImageInfoData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return DataImageEndpointApiFp(configuration).deleteDataImage(account, imageId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<DataImageInfoData> {
            return DataImageEndpointApiFp(configuration).getDataImage(account, imageId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedDataImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PagedDataImageInfoData> {
            return DataImageEndpointApiFp(configuration).getPagedDataImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {DataImageInfoData} dataImageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataImage(account: string, imageId: number, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<DataImageInfoData> {
            return DataImageEndpointApiFp(configuration).updateDataImage(account, imageId, dataImageInfoData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * DataImageEndpointApi - object-oriented interface
 * @export
 * @class DataImageEndpointApi
 * @extends {BaseAPI}
 */
export class DataImageEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {DataImageInfoData} dataImageInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImageEndpointApi
     */
    public createDataImage(account: string, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any) {
        return DataImageEndpointApiFp(this.configuration).createDataImage(account, dataImageInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImageEndpointApi
     */
    public deleteDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any) {
        return DataImageEndpointApiFp(this.configuration).deleteDataImage(account, imageId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImageEndpointApi
     */
    public getDataImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any) {
        return DataImageEndpointApiFp(this.configuration).getDataImage(account, imageId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [name] 
     * @param {boolean} [onlyMy] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImageEndpointApi
     */
    public getPagedDataImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return DataImageEndpointApiFp(this.configuration).getPagedDataImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {DataImageInfoData} dataImageInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImageEndpointApi
     */
    public updateDataImage(account: string, imageId: number, dataImageInfoData: DataImageInfoData, MLP_API_KEY?: string, options?: any) {
        return DataImageEndpointApiFp(this.configuration).updateDataImage(account, imageId, dataImageInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * DatasetEndpointApi - axios parameter creator
 * @export
 */
export const DatasetEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} name 
         * @param {string} [description] 
         * @param {string} [dataType] 
         * @param {'private' | 'public' | 'restricted'} [accessMode] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset(account: string, name: string, description?: string, dataType?: string, accessMode?: 'private' | 'public' | 'restricted', MLP_API_KEY?: string, file?: any, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createDataset.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/raw`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }

            if (accessMode !== undefined) {
                localVarQueryParameter['accessMode'] = accessMode;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyDataset(account: string, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createEmptyDataset.');
            }
            // verify required parameter 'createOrUpdateDatasetInfoData' is not null or undefined
            if (createOrUpdateDatasetInfoData === null || createOrUpdateDatasetInfoData === undefined) {
                throw new RequiredError('createOrUpdateDatasetInfoData','Required parameter createOrUpdateDatasetInfoData was null or undefined when calling createEmptyDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/empty`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createOrUpdateDatasetInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateDatasetInfoData !== undefined ? createOrUpdateDatasetInfoData : {}) : (createOrUpdateDatasetInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset(account: string, datasetId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteDataset.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling deleteDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedCreateDataset(account: string, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deprecatedCreateDataset.');
            }
            // verify required parameter 'deprecatedDatasetInfoWithContentData' is not null or undefined
            if (deprecatedDatasetInfoWithContentData === null || deprecatedDatasetInfoWithContentData === undefined) {
                throw new RequiredError('deprecatedDatasetInfoWithContentData','Required parameter deprecatedDatasetInfoWithContentData was null or undefined when calling deprecatedCreateDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof deprecatedDatasetInfoWithContentData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deprecatedDatasetInfoWithContentData !== undefined ? deprecatedDatasetInfoWithContentData : {}) : (deprecatedDatasetInfoWithContentData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedUpdateDataset(account: string, datasetId: number, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deprecatedUpdateDataset.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling deprecatedUpdateDataset.');
            }
            // verify required parameter 'deprecatedDatasetInfoWithContentData' is not null or undefined
            if (deprecatedDatasetInfoWithContentData === null || deprecatedDatasetInfoWithContentData === undefined) {
                throw new RequiredError('deprecatedDatasetInfoWithContentData','Required parameter deprecatedDatasetInfoWithContentData was null or undefined when calling deprecatedUpdateDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof deprecatedDatasetInfoWithContentData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(deprecatedDatasetInfoWithContentData !== undefined ? deprecatedDatasetInfoWithContentData : {}) : (deprecatedDatasetInfoWithContentData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [type] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDatasetRawContent(account: string, datasetId: number, type?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling downloadDatasetRawContent.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling downloadDatasetRawContent.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}/content`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDataTypesForFit(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAllowedDataTypesForFit.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getAllowedDataTypesForFit.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/dataset/allowed-types`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDatasetsForFit(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAllowedDatasetsForFit.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getAllowedDatasetsForFit.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/dataset/allowed`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetInfo(account: string, datasetId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getDatasetInfo.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling getDatasetInfo.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} originalDatasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParaphrasedDatasetStatus(account: string, originalDatasetId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getParaphrasedDatasetStatus.');
            }
            // verify required parameter 'originalDatasetId' is not null or undefined
            if (originalDatasetId === null || originalDatasetId === undefined) {
                throw new RequiredError('originalDatasetId','Required parameter originalDatasetId was null or undefined when calling getParaphrasedDatasetStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{originalDatasetId}/paraphrase`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"originalDatasetId"}}`, encodeURIComponent(String(originalDatasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatasets(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listDatasets.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paraphraseDataset(account: string, datasetId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling paraphraseDataset.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling paraphraseDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}/paraphrase`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataset(account: string, datasetId: number, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateDataset.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling updateDataset.');
            }
            // verify required parameter 'createOrUpdateDatasetInfoData' is not null or undefined
            if (createOrUpdateDatasetInfoData === null || createOrUpdateDatasetInfoData === undefined) {
                throw new RequiredError('createOrUpdateDatasetInfoData','Required parameter createOrUpdateDatasetInfoData was null or undefined when calling updateDataset.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}/raw`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createOrUpdateDatasetInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateDatasetInfoData !== undefined ? createOrUpdateDatasetInfoData : {}) : (createOrUpdateDatasetInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {any} file 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDatasetContent(account: string, datasetId: number, file: any, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling uploadDatasetContent.');
            }
            // verify required parameter 'datasetId' is not null or undefined
            if (datasetId === null || datasetId === undefined) {
                throw new RequiredError('datasetId','Required parameter datasetId was null or undefined when calling uploadDatasetContent.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadDatasetContent.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/dataset/{datasetId}/content`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"datasetId"}}`, encodeURIComponent(String(datasetId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetEndpointApi - functional programming interface
 * @export
 */
export const DatasetEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} name 
         * @param {string} [description] 
         * @param {string} [dataType] 
         * @param {'private' | 'public' | 'restricted'} [accessMode] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset(account: string, name: string, description?: string, dataType?: string, accessMode?: 'private' | 'public' | 'restricted', MLP_API_KEY?: string, file?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).createDataset(account, name, description, dataType, accessMode, MLP_API_KEY, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyDataset(account: string, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).createEmptyDataset(account, createOrUpdateDatasetInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).deleteDataset(account, datasetId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedCreateDataset(account: string, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).deprecatedCreateDataset(account, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedUpdateDataset(account: string, datasetId: number, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).deprecatedUpdateDataset(account, datasetId, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [type] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDatasetRawContent(account: string, datasetId: number, type?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).downloadDatasetRawContent(account, datasetId, type, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDataTypesForFit(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).getAllowedDataTypesForFit(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDatasetsForFit(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetInfoData>> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).getAllowedDatasetsForFit(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetInfo(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).getDatasetInfo(account, datasetId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} originalDatasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParaphrasedDatasetStatus(account: string, originalDatasetId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParaphrasingStatus> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).getParaphrasedDatasetStatus(account, originalDatasetId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatasets(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetInfoData>> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).listDatasets(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paraphraseDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).paraphraseDataset(account, datasetId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataset(account: string, datasetId: number, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).updateDataset(account, datasetId, createOrUpdateDatasetInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {any} file 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDatasetContent(account: string, datasetId: number, file: any, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfoData> {
            const localVarAxiosArgs = DatasetEndpointApiAxiosParamCreator(configuration).uploadDatasetContent(account, datasetId, file, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DatasetEndpointApi - factory interface
 * @export
 */
export const DatasetEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} name 
         * @param {string} [description] 
         * @param {string} [dataType] 
         * @param {'private' | 'public' | 'restricted'} [accessMode] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset(account: string, name: string, description?: string, dataType?: string, accessMode?: 'private' | 'public' | 'restricted', MLP_API_KEY?: string, file?: any, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).createDataset(account, name, description, dataType, accessMode, MLP_API_KEY, file, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmptyDataset(account: string, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).createEmptyDataset(account, createOrUpdateDatasetInfoData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return DatasetEndpointApiFp(configuration).deleteDataset(account, datasetId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedCreateDataset(account: string, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).deprecatedCreateDataset(account, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedUpdateDataset(account: string, datasetId: number, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).deprecatedUpdateDataset(account, datasetId, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [type] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDatasetRawContent(account: string, datasetId: number, type?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<any> {
            return DatasetEndpointApiFp(configuration).downloadDatasetRawContent(account, datasetId, type, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDataTypesForFit(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return DatasetEndpointApiFp(configuration).getAllowedDataTypesForFit(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllowedDatasetsForFit(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<DatasetInfoData>> {
            return DatasetEndpointApiFp(configuration).getAllowedDatasetsForFit(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetInfo(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).getDatasetInfo(account, datasetId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} originalDatasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParaphrasedDatasetStatus(account: string, originalDatasetId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ParaphrasingStatus> {
            return DatasetEndpointApiFp(configuration).getParaphrasedDatasetStatus(account, originalDatasetId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDatasets(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<DatasetInfoData>> {
            return DatasetEndpointApiFp(configuration).listDatasets(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paraphraseDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).paraphraseDataset(account, datasetId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataset(account: string, datasetId: number, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).updateDataset(account, datasetId, createOrUpdateDatasetInfoData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} datasetId 
         * @param {any} file 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDatasetContent(account: string, datasetId: number, file: any, MLP_API_KEY?: string, options?: any): AxiosPromise<DatasetInfoData> {
            return DatasetEndpointApiFp(configuration).uploadDatasetContent(account, datasetId, file, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * DatasetEndpointApi - object-oriented interface
 * @export
 * @class DatasetEndpointApi
 * @extends {BaseAPI}
 */
export class DatasetEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} name 
     * @param {string} [description] 
     * @param {string} [dataType] 
     * @param {'private' | 'public' | 'restricted'} [accessMode] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public createDataset(account: string, name: string, description?: string, dataType?: string, accessMode?: 'private' | 'public' | 'restricted', MLP_API_KEY?: string, file?: any, options?: any) {
        return DatasetEndpointApiFp(this.configuration).createDataset(account, name, description, dataType, accessMode, MLP_API_KEY, file, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public createEmptyDataset(account: string, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).createEmptyDataset(account, createOrUpdateDatasetInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public deleteDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).deleteDataset(account, datasetId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public deprecatedCreateDataset(account: string, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).deprecatedCreateDataset(account, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {DeprecatedDatasetInfoWithContentData} deprecatedDatasetInfoWithContentData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public deprecatedUpdateDataset(account: string, datasetId: number, deprecatedDatasetInfoWithContentData: DeprecatedDatasetInfoWithContentData, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).deprecatedUpdateDataset(account, datasetId, deprecatedDatasetInfoWithContentData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {string} [type] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public downloadDatasetRawContent(account: string, datasetId: number, type?: string, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).downloadDatasetRawContent(account, datasetId, type, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public getAllowedDataTypesForFit(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).getAllowedDataTypesForFit(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public getAllowedDatasetsForFit(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).getAllowedDatasetsForFit(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public getDatasetInfo(account: string, datasetId: number, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).getDatasetInfo(account, datasetId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} originalDatasetId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public getParaphrasedDatasetStatus(account: string, originalDatasetId: number, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).getParaphrasedDatasetStatus(account, originalDatasetId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public listDatasets(account: string, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).listDatasets(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public paraphraseDataset(account: string, datasetId: number, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).paraphraseDataset(account, datasetId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {CreateOrUpdateDatasetInfoData} createOrUpdateDatasetInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public updateDataset(account: string, datasetId: number, createOrUpdateDatasetInfoData: CreateOrUpdateDatasetInfoData, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).updateDataset(account, datasetId, createOrUpdateDatasetInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} datasetId 
     * @param {any} file 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetEndpointApi
     */
    public uploadDatasetContent(account: string, datasetId: number, file: any, MLP_API_KEY?: string, options?: any) {
        return DatasetEndpointApiFp(this.configuration).uploadDatasetContent(account, datasetId, file, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * FitConfigEndpointApi - axios parameter creator
 * @export
 */
export const FitConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFitConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createFitConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createFitConfig.');
            }
            // verify required parameter 'configCreateUpdateData' is not null or undefined
            if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
                throw new RequiredError('configCreateUpdateData','Required parameter configCreateUpdateData was null or undefined when calling createFitConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof configCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {}) : (configCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteFitConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deleteFitConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling deleteFitConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getFitConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getFitConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling getFitConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableFitConfigs(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listAvailableFitConfigs.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling listAvailableFitConfigs.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/available-fit-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFitConfigs(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listFitConfigs.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling listFitConfigs.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFitConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateFitConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateFitConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling updateFitConfig.');
            }
            // verify required parameter 'configCreateUpdateData' is not null or undefined
            if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
                throw new RequiredError('configCreateUpdateData','Required parameter configCreateUpdateData was null or undefined when calling updateFitConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof configCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {}) : (configCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FitConfigEndpointApi - functional programming interface
 * @export
 */
export const FitConfigEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFitConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).createFitConfig(account, model, configCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).deleteFitConfig(account, model, configId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).getFitConfig(account, model, configId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FitConfigData>> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).listAvailableFitConfigs(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FitConfigData>> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).listFitConfigs(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFitConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FitConfigData> {
            const localVarAxiosArgs = FitConfigEndpointApiAxiosParamCreator(configuration).updateFitConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FitConfigEndpointApi - factory interface
 * @export
 */
export const FitConfigEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFitConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<FitConfigData> {
            return FitConfigEndpointApiFp(configuration).createFitConfig(account, model, configCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return FitConfigEndpointApiFp(configuration).deleteFitConfig(account, model, configId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<FitConfigData> {
            return FitConfigEndpointApiFp(configuration).getFitConfig(account, model, configId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<FitConfigData>> {
            return FitConfigEndpointApiFp(configuration).listAvailableFitConfigs(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<FitConfigData>> {
            return FitConfigEndpointApiFp(configuration).listFitConfigs(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFitConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<FitConfigData> {
            return FitConfigEndpointApiFp(configuration).updateFitConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * FitConfigEndpointApi - object-oriented interface
 * @export
 * @class FitConfigEndpointApi
 * @extends {BaseAPI}
 */
export class FitConfigEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ConfigCreateUpdateData} configCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public createFitConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).createFitConfig(account, model, configCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public deleteFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).deleteFitConfig(account, model, configId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public getFitConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).getFitConfig(account, model, configId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public listAvailableFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).listAvailableFitConfigs(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public listFitConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).listFitConfigs(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {ConfigCreateUpdateData} configCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FitConfigEndpointApi
     */
    public updateFitConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return FitConfigEndpointApiFp(this.configuration).updateFitConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ImageEndpointApi - axios parameter creator
 * @export
 */
export const ImageEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(account: string, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createImage.');
            }
            // verify required parameter 'imageInfoData' is not null or undefined
            if (imageInfoData === null || imageInfoData === undefined) {
                throw new RequiredError('imageInfoData','Required parameter imageInfoData was null or undefined when calling createImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof imageInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(imageInfoData !== undefined ? imageInfoData : {}) : (imageInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(account: string, imageId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling deleteImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(account: string, imageId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling getImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByImage(account: string, imageId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getLogsByImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling getLogsByImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image/{imageId}/logs`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPagedImages.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (onlyMy !== undefined) {
                localVarQueryParameter['onlyMy'] = onlyMy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE'} [belonging] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImagesV2(account: string, name?: string, belonging?: 'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE', page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPagedImagesV2.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image-v2`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (belonging !== undefined) {
                localVarQueryParameter['belonging'] = belonging;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImage(account: string, imageId: number, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateImage.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling updateImage.');
            }
            // verify required parameter 'imageInfoData' is not null or undefined
            if (imageInfoData === null || imageInfoData === undefined) {
                throw new RequiredError('imageInfoData','Required parameter imageInfoData was null or undefined when calling updateImage.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/image/{imageId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof imageInfoData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(imageInfoData !== undefined ? imageInfoData : {}) : (imageInfoData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageEndpointApi - functional programming interface
 * @export
 */
export const ImageEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(account: string, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).createImage(account, imageInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).deleteImage(account, imageId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getImage(account, imageId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getLogsByImage(account, imageId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedImageInfoData> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getPagedImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE'} [belonging] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImagesV2(account: string, name?: string, belonging?: 'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE', page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedImageInfoData> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).getPagedImagesV2(account, name, belonging, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImage(account: string, imageId: number, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageInfoData> {
            const localVarAxiosArgs = ImageEndpointApiAxiosParamCreator(configuration).updateImage(account, imageId, imageInfoData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImageEndpointApi - factory interface
 * @export
 */
export const ImageEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImage(account: string, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<ImageInfoData> {
            return ImageEndpointApiFp(configuration).createImage(account, imageInfoData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ImageEndpointApiFp(configuration).deleteImage(account, imageId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ImageInfoData> {
            return ImageEndpointApiFp(configuration).getImage(account, imageId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
            return ImageEndpointApiFp(configuration).getLogsByImage(account, imageId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {boolean} [onlyMy] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PagedImageInfoData> {
            return ImageEndpointApiFp(configuration).getPagedImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [name] 
         * @param {'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE'} [belonging] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedImagesV2(account: string, name?: string, belonging?: 'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE', page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PagedImageInfoData> {
            return ImageEndpointApiFp(configuration).getPagedImagesV2(account, name, belonging, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} imageId 
         * @param {ImageInfoData} imageInfoData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImage(account: string, imageId: number, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any): AxiosPromise<ImageInfoData> {
            return ImageEndpointApiFp(configuration).updateImage(account, imageId, imageInfoData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ImageEndpointApi - object-oriented interface
 * @export
 * @class ImageEndpointApi
 * @extends {BaseAPI}
 */
export class ImageEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {ImageInfoData} imageInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public createImage(account: string, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).createImage(account, imageInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public deleteImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).deleteImage(account, imageId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public getImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).getImage(account, imageId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public getLogsByImage(account: string, imageId: number, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).getLogsByImage(account, imageId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [name] 
     * @param {boolean} [onlyMy] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public getPagedImages(account: string, name?: string, onlyMy?: boolean, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).getPagedImages(account, name, onlyMy, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [name] 
     * @param {'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE'} [belonging] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public getPagedImagesV2(account: string, name?: string, belonging?: 'ALL' | 'MINE' | 'MINE_PUBLIC' | 'MINE_RESTRICTED' | 'MINE_PRIVATE' | 'NOT_MINE', page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).getPagedImagesV2(account, name, belonging, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} imageId 
     * @param {ImageInfoData} imageInfoData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageEndpointApi
     */
    public updateImage(account: string, imageId: number, imageInfoData: ImageInfoData, MLP_API_KEY?: string, options?: any) {
        return ImageEndpointApiFp(this.configuration).updateImage(account, imageId, imageInfoData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * InternalEndpointApi - axios parameter creator
 * @export
 */
export const InternalEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling addBucketAccess.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling addBucketAccess.');
            }
            // verify required parameter 'foreignAccountId' is not null or undefined
            if (foreignAccountId === null || foreignAccountId === undefined) {
                throw new RequiredError('foreignAccountId','Required parameter foreignAccountId was null or undefined when calling addBucketAccess.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/bucket/access`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (foreignAccountId !== undefined) {
                localVarQueryParameter['foreignAccountId'] = foreignAccountId;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestServer(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling addTestServer.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling addTestServer.');
            }
            // verify required parameter 'createResourceGroupServer' is not null or undefined
            if (createResourceGroupServer === null || createResourceGroupServer === undefined) {
                throw new RequiredError('createResourceGroupServer','Required parameter createResourceGroupServer was null or undefined when calling addTestServer.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/resource-groups/{groupName}/test-server`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createResourceGroupServer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createResourceGroupServer !== undefined ? createResourceGroupServer : {}) : (createResourceGroupServer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAccount(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/clear-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllCaches(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/clear-cache`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllInstances(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/clear-instances`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPublicForAll(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/clear-public-for-all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestAccount(testName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'testName' is not null or undefined
            if (testName === null || testName === undefined) {
                throw new RequiredError('testName','Required parameter testName was null or undefined when calling createTestAccount.');
            }
            const localVarPath = `/api/mlpcore-internal/account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (testName !== undefined) {
                localVarQueryParameter['testName'] = testName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createTestResourceGroup.');
            }
            // verify required parameter 'createResourceGroupData' is not null or undefined
            if (createResourceGroupData === null || createResourceGroupData === undefined) {
                throw new RequiredError('createResourceGroupData','Required parameter createResourceGroupData was null or undefined when calling createTestResourceGroup.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/resource-groups/test`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createResourceGroupData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createResourceGroupData !== undefined ? createResourceGroupData : {}) : (createResourceGroupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccount(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/accounts/deactivate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccountInstances(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deactivateAccountInstances.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deactivateAccountInstances.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/model/{model}/instance/deactivate`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarkedInstances(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/instances/deleteByTimestamp`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestServer(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteTestServer.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteTestServer.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling deleteTestServer.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/resource-groups/{groupName}/server/{serverId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureNamespace(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ensureNamespace.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/namespace/ensure`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures1(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountFeatures1.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/features`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInstancesWithDeleted(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountInstancesWithDeleted.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getAccountInstancesWithDeleted.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/model/{model}/instances`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClicksByAccountId(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getClicksByAccountId.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/clicks`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMockTasks(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/tasks/mock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups1(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/resource-groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllConnectedActions(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/actions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectedActions(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listConnectedActions.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling listConnectedActions.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/model/{model}/actions`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegisteredServices(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/services`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling postgresTest.');
            }
            const localVarPath = `/api/mlpcore-internal/pg-test/{account}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest1(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/empty-test`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} userId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushNotification(account: string, userId: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling pushNotification.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling pushNotification.');
            }
            const localVarPath = `/api/mlpcore-internal/notify/{account}/user/{userId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling removeBucketAccess.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling removeBucketAccess.');
            }
            // verify required parameter 'foreignAccountId' is not null or undefined
            if (foreignAccountId === null || foreignAccountId === undefined) {
                throw new RequiredError('foreignAccountId','Required parameter foreignAccountId was null or undefined when calling removeBucketAccess.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/bucket/deny`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (foreignAccountId !== undefined) {
                localVarQueryParameter['foreignAccountId'] = foreignAccountId;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEMail(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/test-mail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublicForAll(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/set-public-for-all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOneInstance(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore-internal/start-one-instance`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateAccountFeatures.');
            }
            // verify required parameter 'accountFeatureData' is not null or undefined
            if (accountFeatureData === null || accountFeatureData === undefined) {
                throw new RequiredError('accountFeatureData','Required parameter accountFeatureData was null or undefined when calling updateAccountFeatures.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/features`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountFeatureData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountFeatureData !== undefined ? accountFeatureData : {}) : (accountFeatureData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits1(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateLimits1.');
            }
            // verify required parameter 'accountLimitsData' is not null or undefined
            if (accountLimitsData === null || accountLimitsData === undefined) {
                throw new RequiredError('accountLimitsData','Required parameter accountLimitsData was null or undefined when calling updateLimits1.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/update-limits`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof accountLimitsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountLimitsData !== undefined ? accountLimitsData : {}) : (accountLimitsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelDir(account: string, model: string, body: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModelDir.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModelDir.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateModelDir.');
            }
            const localVarPath = `/api/mlpcore-internal/account/{account}/model/{model}/path`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups1(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'resourceGroupsData' is not null or undefined
            if (resourceGroupsData === null || resourceGroupsData === undefined) {
                throw new RequiredError('resourceGroupsData','Required parameter resourceGroupsData was null or undefined when calling updateResourceGroups1.');
            }
            const localVarPath = `/api/mlpcore-internal/resource-groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof resourceGroupsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resourceGroupsData !== undefined ? resourceGroupsData : {}) : (resourceGroupsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalEndpointApi - functional programming interface
 * @export
 */
export const InternalEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).addBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestServer(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServerData> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).addTestServer(account, groupName, createResourceGroupServer, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAccount(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).clearAccount(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllCaches(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).clearAllCaches(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllInstances(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).clearAllInstances(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPublicForAll(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).clearPublicForAll(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} testName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestAccount(testName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).createTestAccount(testName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupEssentialData> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).createTestResourceGroup(account, createResourceGroupData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccount(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deactivateAccount(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccountInstances(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deactivateAccountInstances(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarkedInstances(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deleteMarkedInstances(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestServer(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).deleteTestServer(account, groupName, serverId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureNamespace(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).ensureNamespace(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures1(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFeatureData> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getAccountFeatures1(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInstancesWithDeleted(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInstance>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getAccountInstancesWithDeleted(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClicksByAccountId(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClickHistoryData>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getClicksByAccountId(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMockTasks(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortJobView>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getMockTasks(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups1(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupData>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).getResourceGroups1(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllConnectedActions(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listAllConnectedActions(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectedActions(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listConnectedActions(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegisteredServices(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInstance>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).listRegisteredServices(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoData> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).postgresTest(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest1(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).postgresTest1(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} userId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushNotification(account: string, userId: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).pushNotification(account, userId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).removeBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEMail(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).sendTestEMail(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublicForAll(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).setPublicForAll(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOneInstance(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).startOneInstance(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateAccountFeatures(account, accountFeatureData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits1(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateLimits1(account, accountLimitsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelDir(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateModelDir(account, model, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups1(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = InternalEndpointApiAxiosParamCreator(configuration).updateResourceGroups1(resourceGroupsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InternalEndpointApi - factory interface
 * @export
 */
export const InternalEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).addBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestServer(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServerData> {
            return InternalEndpointApiFp(configuration).addTestServer(account, groupName, createResourceGroupServer, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAccount(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).clearAccount(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllCaches(MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).clearAllCaches(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearAllInstances(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).clearAllInstances(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPublicForAll(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).clearPublicForAll(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} testName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestAccount(testName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return InternalEndpointApiFp(configuration).createTestAccount(testName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupEssentialData> {
            return InternalEndpointApiFp(configuration).createTestResourceGroup(account, createResourceGroupData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccount(MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).deactivateAccount(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateAccountInstances(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).deactivateAccountInstances(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarkedInstances(MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).deleteMarkedInstances(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestServer(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).deleteTestServer(account, groupName, serverId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ensureNamespace(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).ensureNamespace(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountFeatures1(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountFeatureData> {
            return InternalEndpointApiFp(configuration).getAccountFeatures1(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInstancesWithDeleted(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ModelInstance>> {
            return InternalEndpointApiFp(configuration).getAccountInstancesWithDeleted(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClicksByAccountId(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ClickHistoryData>> {
            return InternalEndpointApiFp(configuration).getClicksByAccountId(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMockTasks(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ShortJobView>> {
            return InternalEndpointApiFp(configuration).getMockTasks(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroups1(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupData>> {
            return InternalEndpointApiFp(configuration).getResourceGroups1(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllConnectedActions(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).listAllConnectedActions(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConnectedActions(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).listConnectedActions(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegisteredServices(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ModelInstance>> {
            return InternalEndpointApiFp(configuration).listRegisteredServices(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AccountInfoData> {
            return InternalEndpointApiFp(configuration).postgresTest(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postgresTest1(MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).postgresTest1(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} userId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pushNotification(account: string, userId: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).pushNotification(account, userId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} path 
         * @param {number} foreignAccountId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).removeBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEMail(MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).sendTestEMail(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublicForAll(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).setPublicForAll(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startOneInstance(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return InternalEndpointApiFp(configuration).startOneInstance(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountFeatureData} accountFeatureData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).updateAccountFeatures(account, accountFeatureData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {AccountLimitsData} accountLimitsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLimits1(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).updateLimits1(account, accountLimitsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelDir(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return InternalEndpointApiFp(configuration).updateModelDir(account, model, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {ResourceGroupsData} resourceGroupsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroups1(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any): AxiosPromise<object> {
            return InternalEndpointApiFp(configuration).updateResourceGroups1(resourceGroupsData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * InternalEndpointApi - object-oriented interface
 * @export
 * @class InternalEndpointApi
 * @extends {BaseAPI}
 */
export class InternalEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} path 
     * @param {number} foreignAccountId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public addBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).addBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {CreateResourceGroupServer} createResourceGroupServer 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public addTestServer(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).addTestServer(account, groupName, createResourceGroupServer, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public clearAccount(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).clearAccount(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public clearAllCaches(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).clearAllCaches(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public clearAllInstances(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).clearAllInstances(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public clearPublicForAll(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).clearPublicForAll(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} testName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public createTestAccount(testName: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).createTestAccount(testName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {CreateResourceGroupData} createResourceGroupData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public createTestResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).createTestResourceGroup(account, createResourceGroupData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public deactivateAccount(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).deactivateAccount(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public deactivateAccountInstances(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).deactivateAccountInstances(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public deleteMarkedInstances(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).deleteMarkedInstances(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} serverId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public deleteTestServer(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).deleteTestServer(account, groupName, serverId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public ensureNamespace(account: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).ensureNamespace(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getAccountFeatures1(account: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getAccountFeatures1(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getAccountInstancesWithDeleted(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getAccountInstancesWithDeleted(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getClicksByAccountId(account: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getClicksByAccountId(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getMockTasks(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getMockTasks(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public getResourceGroups1(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).getResourceGroups1(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public listAllConnectedActions(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).listAllConnectedActions(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public listConnectedActions(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).listConnectedActions(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public listRegisteredServices(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).listRegisteredServices(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public postgresTest(account: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).postgresTest(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public postgresTest1(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).postgresTest1(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} userId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public pushNotification(account: string, userId: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).pushNotification(account, userId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} path 
     * @param {number} foreignAccountId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public removeBucketAccess(account: string, path: string, foreignAccountId: number, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).removeBucketAccess(account, path, foreignAccountId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public sendTestEMail(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).sendTestEMail(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public setPublicForAll(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).setPublicForAll(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public startOneInstance(MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).startOneInstance(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {AccountFeatureData} accountFeatureData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public updateAccountFeatures(account: string, accountFeatureData: AccountFeatureData, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).updateAccountFeatures(account, accountFeatureData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {AccountLimitsData} accountLimitsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public updateLimits1(account: string, accountLimitsData: AccountLimitsData, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).updateLimits1(account, accountLimitsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public updateModelDir(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).updateModelDir(account, model, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ResourceGroupsData} resourceGroupsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalEndpointApi
     */
    public updateResourceGroups1(resourceGroupsData: ResourceGroupsData, MLP_API_KEY?: string, options?: any) {
        return InternalEndpointApiFp(this.configuration).updateResourceGroups1(resourceGroupsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * JobEndpointApi - axios parameter creator
 * @export
 */
export const JobEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} jobId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobStatus(account: string, jobId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling jobStatus.');
            }
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling jobStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/job/{jobId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs2(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listJobs2.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/job`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobEndpointApi - functional programming interface
 * @export
 */
export const JobEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} jobId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobStatus(account: string, jobId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
            const localVarAxiosArgs = JobEndpointApiAxiosParamCreator(configuration).jobStatus(account, jobId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs2(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobStatusData>> {
            const localVarAxiosArgs = JobEndpointApiAxiosParamCreator(configuration).listJobs2(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JobEndpointApi - factory interface
 * @export
 */
export const JobEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} jobId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobStatus(account: string, jobId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
            return JobEndpointApiFp(configuration).jobStatus(account, jobId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs2(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<JobStatusData>> {
            return JobEndpointApiFp(configuration).listJobs2(account, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * JobEndpointApi - object-oriented interface
 * @export
 * @class JobEndpointApi
 * @extends {BaseAPI}
 */
export class JobEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} jobId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobEndpointApi
     */
    public jobStatus(account: string, jobId: number, MLP_API_KEY?: string, options?: any) {
        return JobEndpointApiFp(this.configuration).jobStatus(account, jobId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobEndpointApi
     */
    public listJobs2(account: string, MLP_API_KEY?: string, options?: any) {
        return JobEndpointApiFp(this.configuration).listJobs2(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ManualManagementEndpointApi - axios parameter creator
 * @export
 */
export const ManualManagementEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} featureName 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFeature(account: string, featureName: string, managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling requestFeature.');
            }
            // verify required parameter 'featureName' is not null or undefined
            if (featureName === null || featureName === undefined) {
                throw new RequiredError('featureName','Required parameter featureName was null or undefined when calling requestFeature.');
            }
            // verify required parameter 'managementRequestData' is not null or undefined
            if (managementRequestData === null || managementRequestData === undefined) {
                throw new RequiredError('managementRequestData','Required parameter managementRequestData was null or undefined when calling requestFeature.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/management/{featureName}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"featureName"}}`, encodeURIComponent(String(featureName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof managementRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(managementRequestData !== undefined ? managementRequestData : {}) : (managementRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'managementRequestData' is not null or undefined
            if (managementRequestData === null || managementRequestData === undefined) {
                throw new RequiredError('managementRequestData','Required parameter managementRequestData was null or undefined when calling sendEmail.');
            }
            const localVarPath = `/api/mlpcore/sendContactRequest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof managementRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(managementRequestData !== undefined ? managementRequestData : {}) : (managementRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManualManagementEndpointApi - functional programming interface
 * @export
 */
export const ManualManagementEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} featureName 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFeature(account: string, featureName: string, managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ManualManagementEndpointApiAxiosParamCreator(configuration).requestFeature(account, featureName, managementRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ManualManagementEndpointApiAxiosParamCreator(configuration).sendEmail(managementRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ManualManagementEndpointApi - factory interface
 * @export
 */
export const ManualManagementEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} featureName 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFeature(account: string, featureName: string, managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ManualManagementEndpointApiFp(configuration).requestFeature(account, featureName, managementRequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {ManagementRequestData} managementRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ManualManagementEndpointApiFp(configuration).sendEmail(managementRequestData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ManualManagementEndpointApi - object-oriented interface
 * @export
 * @class ManualManagementEndpointApi
 * @extends {BaseAPI}
 */
export class ManualManagementEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} featureName 
     * @param {ManagementRequestData} managementRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualManagementEndpointApi
     */
    public requestFeature(account: string, featureName: string, managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any) {
        return ManualManagementEndpointApiFp(this.configuration).requestFeature(account, featureName, managementRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {ManagementRequestData} managementRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManualManagementEndpointApi
     */
    public sendEmail(managementRequestData: ManagementRequestData, MLP_API_KEY?: string, options?: any) {
        return ManualManagementEndpointApiFp(this.configuration).sendEmail(managementRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * MetricEndpointApi - axios parameter creator
 * @export
 */
export const MetricEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetric(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountMetric.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getAccountMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/metric`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetricRange(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountMetricRange.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getAccountMetricRange.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getAccountMetricRange.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/metric-range`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfAccountMetric(account: string, metric: 'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED', MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getEarliestTimestampOfAccountMetric.');
            }
            // verify required parameter 'metric' is not null or undefined
            if (metric === null || metric === undefined) {
                throw new RequiredError('metric','Required parameter metric was null or undefined when calling getEarliestTimestampOfAccountMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/metric/start-time`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfInstanceMetric(account: string, model: string, instanceId: number, metric: 'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT', MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getEarliestTimestampOfInstanceMetric.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getEarliestTimestampOfInstanceMetric.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getEarliestTimestampOfInstanceMetric.');
            }
            // verify required parameter 'metric' is not null or undefined
            if (metric === null || metric === undefined) {
                throw new RequiredError('metric','Required parameter metric was null or undefined when calling getEarliestTimestampOfInstanceMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/metric/start-time`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfModelMetric(account: string, model: string, metric: 'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME', MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getEarliestTimestampOfModelMetric.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getEarliestTimestampOfModelMetric.');
            }
            // verify required parameter 'metric' is not null or undefined
            if (metric === null || metric === undefined) {
                throw new RequiredError('metric','Required parameter metric was null or undefined when calling getEarliestTimestampOfModelMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/metric/start-time`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'} metric 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfResourceGroupMetric(account: string, groupName: string, metric: 'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT', nodeName?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getEarliestTimestampOfResourceGroupMetric.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getEarliestTimestampOfResourceGroupMetric.');
            }
            // verify required parameter 'metric' is not null or undefined
            if (metric === null || metric === undefined) {
                throw new RequiredError('metric','Required parameter metric was null or undefined when calling getEarliestTimestampOfResourceGroupMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/metric/start-time`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metric !== undefined) {
                localVarQueryParameter['metric'] = metric;
            }

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetric(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstanceMetric.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstanceMetric.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceMetric.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getInstanceMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/metric`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetricRange(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstanceMetricRange.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstanceMetricRange.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceMetricRange.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getInstanceMetricRange.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getInstanceMetricRange.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/metric-range`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetric(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, time?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelMetric.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelMetric.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getModelMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/metric`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetricRange(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelMetricRange.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelMetricRange.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getModelMetricRange.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getModelMetricRange.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/metric-range`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<string>} models 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetric(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, models: Array<string>, time?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelsMetric.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getModelsMetric.');
            }
            // verify required parameter 'models' is not null or undefined
            if (models === null || models === undefined) {
                throw new RequiredError('models','Required parameter models was null or undefined when calling getModelsMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/models/metric`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (models) {
                localVarQueryParameter['models'] = models;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<number>} modelsIdes 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetricsById(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, modelsIdes: Array<number>, time?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelsMetricsById.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getModelsMetricsById.');
            }
            // verify required parameter 'modelsIdes' is not null or undefined
            if (modelsIdes === null || modelsIdes === undefined) {
                throw new RequiredError('modelsIdes','Required parameter modelsIdes was null or undefined when calling getModelsMetricsById.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/models/metrics`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (modelsIdes) {
                localVarQueryParameter['modelsIdes'] = modelsIdes;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} [time] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupMetric(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, time?: number, nodeName?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupMetric.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupMetric.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getResourceGroupMetric.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/metric`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupServices.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupServices.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/services`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServicesInfo(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupServicesInfo.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupServicesInfo.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/services-info`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceMetricRange(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, minutes: number, stepSeconds?: number, nodeName?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceMetricRange.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceMetricRange.');
            }
            // verify required parameter 'metrics' is not null or undefined
            if (metrics === null || metrics === undefined) {
                throw new RequiredError('metrics','Required parameter metrics was null or undefined when calling getResourceMetricRange.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getResourceMetricRange.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/metric-range`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metrics) {
                localVarQueryParameter['metrics'] = metrics;
            }

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (nodeName !== undefined) {
                localVarQueryParameter['nodeName'] = nodeName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricEndpointApi - functional programming interface
 * @export
 */
export const MetricEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetric(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getAccountMetric(account, metrics, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetricRange(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getAccountMetricRange(account, metrics, minutes, stepSeconds, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfAccountMetric(account: string, metric: 'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED', MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getEarliestTimestampOfAccountMetric(account, metric, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfInstanceMetric(account: string, model: string, instanceId: number, metric: 'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT', MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getEarliestTimestampOfInstanceMetric(account, model, instanceId, metric, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfModelMetric(account: string, model: string, metric: 'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME', MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getEarliestTimestampOfModelMetric(account, model, metric, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'} metric 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfResourceGroupMetric(account: string, groupName: string, metric: 'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT', nodeName?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getEarliestTimestampOfResourceGroupMetric(account, groupName, metric, nodeName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetric(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getInstanceMetric(account, model, instanceId, metrics, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetricRange(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getInstanceMetricRange(account, model, instanceId, metrics, minutes, stepSeconds, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetric(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, time?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelMetric(account, model, metrics, time, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetricRange(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelMetricRange(account, model, metrics, minutes, stepSeconds, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<string>} models 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetric(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, models: Array<string>, time?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: number; }; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelsMetric(account, metrics, models, time, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<number>} modelsIdes 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetricsById(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, modelsIdes: Array<number>, time?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: number; }; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getModelsMetricsById(account, metrics, modelsIdes, time, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} [time] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupMetric(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, time?: number, nodeName?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getResourceGroupMetric(account, groupName, metrics, time, nodeName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getResourceGroupServices(account, groupName, time, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServicesInfo(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceInfoAtTime>> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getResourceGroupServicesInfo(account, groupName, time, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceMetricRange(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, minutes: number, stepSeconds?: number, nodeName?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            const localVarAxiosArgs = MetricEndpointApiAxiosParamCreator(configuration).getResourceMetricRange(account, groupName, metrics, minutes, stepSeconds, nodeName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MetricEndpointApi - factory interface
 * @export
 */
export const MetricEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetric(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return MetricEndpointApiFp(configuration).getAccountMetric(account, metrics, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMetricRange(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            return MetricEndpointApiFp(configuration).getAccountMetricRange(account, metrics, minutes, stepSeconds, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfAccountMetric(account: string, metric: 'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED', MLP_API_KEY?: string, options?: any): AxiosPromise<number> {
            return MetricEndpointApiFp(configuration).getEarliestTimestampOfAccountMetric(account, metric, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfInstanceMetric(account: string, model: string, instanceId: number, metric: 'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT', MLP_API_KEY?: string, options?: any): AxiosPromise<number> {
            return MetricEndpointApiFp(configuration).getEarliestTimestampOfInstanceMetric(account, model, instanceId, metric, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'} metric 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfModelMetric(account: string, model: string, metric: 'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME', MLP_API_KEY?: string, options?: any): AxiosPromise<number> {
            return MetricEndpointApiFp(configuration).getEarliestTimestampOfModelMetric(account, model, metric, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'} metric 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEarliestTimestampOfResourceGroupMetric(account: string, groupName: string, metric: 'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT', nodeName?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<number> {
            return MetricEndpointApiFp(configuration).getEarliestTimestampOfResourceGroupMetric(account, groupName, metric, nodeName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetric(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return MetricEndpointApiFp(configuration).getInstanceMetric(account, model, instanceId, metrics, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceMetricRange(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            return MetricEndpointApiFp(configuration).getInstanceMetricRange(account, model, instanceId, metrics, minutes, stepSeconds, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetric(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, time?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return MetricEndpointApiFp(configuration).getModelMetric(account, model, metrics, time, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelMetricRange(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            return MetricEndpointApiFp(configuration).getModelMetricRange(account, model, metrics, minutes, stepSeconds, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<string>} models 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetric(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, models: Array<string>, time?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: number; }; }> {
            return MetricEndpointApiFp(configuration).getModelsMetric(account, metrics, models, time, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
         * @param {Array<number>} modelsIdes 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsMetricsById(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, modelsIdes: Array<number>, time?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: number; }; }> {
            return MetricEndpointApiFp(configuration).getModelsMetricsById(account, metrics, modelsIdes, time, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} [time] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupMetric(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, time?: number, nodeName?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return MetricEndpointApiFp(configuration).getResourceGroupMetric(account, groupName, metrics, time, nodeName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return MetricEndpointApiFp(configuration).getResourceGroupServices(account, groupName, time, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} [time] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServicesInfo(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ServiceInfoAtTime>> {
            return MetricEndpointApiFp(configuration).getResourceGroupServicesInfo(account, groupName, time, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
         * @param {number} minutes 
         * @param {number} [stepSeconds] 
         * @param {string} [nodeName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceMetricRange(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, minutes: number, stepSeconds?: number, nodeName?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<{ [key: string]: Array<Measurement>; }> {
            return MetricEndpointApiFp(configuration).getResourceMetricRange(account, groupName, metrics, minutes, stepSeconds, nodeName, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * MetricEndpointApi - object-oriented interface
 * @export
 * @class MetricEndpointApi
 * @extends {BaseAPI}
 */
export class MetricEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getAccountMetric(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getAccountMetric(account, metrics, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>} metrics 
     * @param {number} minutes 
     * @param {number} [stepSeconds] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getAccountMetricRange(account: string, metrics: Array<'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getAccountMetricRange(account, metrics, minutes, stepSeconds, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED'} metric 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getEarliestTimestampOfAccountMetric(account: string, metric: 'ACCOUNT_CPU_USAGE' | 'ACCOUNT_CPU_REQUESTED' | 'ACCOUNT_CPU_LIMIT' | 'ACCOUNT_RAM_USAGE' | 'ACCOUNT_RAM_REQUESTED' | 'ACCOUNT_RAM_LIMIT' | 'ACCOUNT_DISK_REQUESTED' | 'ACCOUNT_DISK_LIMIT' | 'ACCOUNT_GPU_REQUESTED' | 'ACCOUNT_GPU_LIMIT' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_PREDICTS_COUNT_PER_MINUTE_EXCEEDED' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_TOTAL' | 'ACCOUNT_FITS_COUNT_PER_MINUTE_EXCEEDED', MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getEarliestTimestampOfAccountMetric(account, metric, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'} metric 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getEarliestTimestampOfInstanceMetric(account: string, model: string, instanceId: number, metric: 'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT', MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getEarliestTimestampOfInstanceMetric(account, model, instanceId, metric, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'} metric 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getEarliestTimestampOfModelMetric(account: string, model: string, metric: 'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME', MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getEarliestTimestampOfModelMetric(account, model, metric, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'} metric 
     * @param {string} [nodeName] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getEarliestTimestampOfResourceGroupMetric(account: string, groupName: string, metric: 'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT', nodeName?: string, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getEarliestTimestampOfResourceGroupMetric(account, groupName, metric, nodeName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getInstanceMetric(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getInstanceMetric(account, model, instanceId, metrics, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>} metrics 
     * @param {number} minutes 
     * @param {number} [stepSeconds] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getInstanceMetricRange(account: string, model: string, instanceId: number, metrics: Array<'INSTANCE_CPU_USAGE' | 'INSTANCE_CPU_REQUESTED' | 'INSTANCE_CPU_LIMIT' | 'INSTANCE_RAM_USAGE' | 'INSTANCE_RAM_REQUESTED' | 'INSTANCE_RAM_LIMIT' | 'INSTANCE_RECONNECTS_COUNT'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getInstanceMetricRange(account, model, instanceId, metrics, minutes, stepSeconds, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
     * @param {number} [time] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getModelMetric(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, time?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getModelMetric(account, model, metrics, time, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
     * @param {number} minutes 
     * @param {number} [stepSeconds] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getModelMetricRange(account: string, model: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, minutes: number, stepSeconds?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getModelMetricRange(account, model, metrics, minutes, stepSeconds, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
     * @param {Array<string>} models 
     * @param {number} [time] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getModelsMetric(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, models: Array<string>, time?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getModelsMetric(account, metrics, models, time, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>} metrics 
     * @param {Array<number>} modelsIdes 
     * @param {number} [time] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getModelsMetricsById(account: string, metrics: Array<'MODEL_CPU_USAGE' | 'MODEL_CPU_REQUESTED' | 'MODEL_CPU_LIMIT' | 'MODEL_RAM_USAGE' | 'MODEL_RAM_REQUESTED' | 'MODEL_RAM_LIMIT' | 'MODEL_RECONNECTS_COUNT' | 'MODEL_INSTANCES_COUNT' | 'MODEL_GPU_REQUESTED' | 'MODEL_EPHEMERAL_DISK_REQUESTED' | 'MODEL_SUCCESS_REQUESTS_COUNT' | 'MODEL_FAILED_REQUESTS_COUNT' | 'MODEL_ACTIVE_REQUESTS_COUNT' | 'MODEL_AVERAGE_REQUEST_TIME' | 'MODEL_MIN_REQUEST_TIME' | 'MODEL_MAX_REQUEST_TIME' | 'MODEL_INSTANT_REQUEST_TIME'>, modelsIdes: Array<number>, time?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getModelsMetricsById(account, metrics, modelsIdes, time, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
     * @param {number} [time] 
     * @param {string} [nodeName] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getResourceGroupMetric(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, time?: number, nodeName?: string, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getResourceGroupMetric(account, groupName, metrics, time, nodeName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} [time] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getResourceGroupServices(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getResourceGroupServices(account, groupName, time, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} [time] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getResourceGroupServicesInfo(account: string, groupName: string, time?: number, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getResourceGroupServicesInfo(account, groupName, time, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>} metrics 
     * @param {number} minutes 
     * @param {number} [stepSeconds] 
     * @param {string} [nodeName] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricEndpointApi
     */
    public getResourceMetricRange(account: string, groupName: string, metrics: Array<'NODE_CPU_USAGE' | 'NODE_CPU_REQUESTED' | 'NODE_CPU_CAPACITY' | 'NODE_CPU_ALLOCATABLE_CAPACITY' | 'NODE_RAM_USAGE' | 'NODE_RAM_REQUESTED' | 'NODE_RAM_CAPACITY' | 'NODE_RAM_ALLOCATABLE_CAPACITY' | 'NODE_DISK_USAGE' | 'NODE_EPHEMERAL_DISK_REQUESTED' | 'NODE_EPHEMERAL_DISK_CAPACITY' | 'NODE_EPHEMERAL_DISK_ALLOCATABLE_CAPACITY' | 'NODE_GPU_PODS_COUNT' | 'NODE_GPU_PODS_LIMIT' | 'NODE_GPU_USAGE' | 'NODE_GPU_LIMIT' | 'NODE_VRAM_USAGE' | 'NODE_VRAM_LIMIT'>, minutes: number, stepSeconds?: number, nodeName?: string, MLP_API_KEY?: string, options?: any) {
        return MetricEndpointApiFp(this.configuration).getResourceMetricRange(account, groupName, metrics, minutes, stepSeconds, nodeName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ModelAliasEndpointApi - axios parameter creator
 * @export
 */
export const ModelAliasEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlias(account: string, model: string, name: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createAlias.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createAlias.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createAlias.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/alias`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} aliasId 
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlias(account: string, aliasId: number, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteAlias.');
            }
            // verify required parameter 'aliasId' is not null or undefined
            if (aliasId === null || aliasId === undefined) {
                throw new RequiredError('aliasId','Required parameter aliasId was null or undefined when calling deleteAlias.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deleteAlias.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/alias/{aliasId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"aliasId"}}`, encodeURIComponent(String(aliasId)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAliases(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelAliases.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelAliases.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/alias`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelAliasEndpointApi - functional programming interface
 * @export
 */
export const ModelAliasEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlias(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AliasData> {
            const localVarAxiosArgs = ModelAliasEndpointApiAxiosParamCreator(configuration).createAlias(account, model, name, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} aliasId 
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlias(account: string, aliasId: number, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelAliasEndpointApiAxiosParamCreator(configuration).deleteAlias(account, aliasId, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAliases(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAliasData> {
            const localVarAxiosArgs = ModelAliasEndpointApiAxiosParamCreator(configuration).getModelAliases(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ModelAliasEndpointApi - factory interface
 * @export
 */
export const ModelAliasEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlias(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any): AxiosPromise<AliasData> {
            return ModelAliasEndpointApiFp(configuration).createAlias(account, model, name, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} aliasId 
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlias(account: string, aliasId: number, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelAliasEndpointApiFp(configuration).deleteAlias(account, aliasId, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelAliases(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelAliasData> {
            return ModelAliasEndpointApiFp(configuration).getModelAliases(account, model, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ModelAliasEndpointApi - object-oriented interface
 * @export
 * @class ModelAliasEndpointApi
 * @extends {BaseAPI}
 */
export class ModelAliasEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} name 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAliasEndpointApi
     */
    public createAlias(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any) {
        return ModelAliasEndpointApiFp(this.configuration).createAlias(account, model, name, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} aliasId 
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAliasEndpointApi
     */
    public deleteAlias(account: string, aliasId: number, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelAliasEndpointApiFp(this.configuration).deleteAlias(account, aliasId, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelAliasEndpointApi
     */
    public getModelAliases(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelAliasEndpointApiFp(this.configuration).getModelAliases(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ModelEndpointApi - axios parameter creator
 * @export
 */
export const ModelEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCustomData(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling clearCustomData.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling clearCustomData.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/custom-data`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClonedModel(account: string, model: string, name: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createClonedModel.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createClonedModel.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createClonedModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/clone`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {boolean} [hidden] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDerivedModel(account: string, model: string, name: string, hidden?: boolean, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createDerivedModel.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createDerivedModel.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createDerivedModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/derived`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalApplicationConnection(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createExternalApplicationConnection.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createExternalApplicationConnection.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/external-application`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [httpHost] 
         * @param {number} [httpPort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalConnection(account: string, model: string, httpHost?: string, httpPort?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createExternalConnection.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createExternalConnection.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/external`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (httpHost !== undefined) {
                localVarQueryParameter['httpHost'] = httpHost;
            }

            if (httpPort !== undefined) {
                localVarQueryParameter['httpPort'] = httpPort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModel(account: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createModel.');
            }
            // verify required parameter 'modelCreateUpdateData' is not null or undefined
            if (modelCreateUpdateData === null || modelCreateUpdateData === undefined) {
                throw new RequiredError('modelCreateUpdateData','Required parameter modelCreateUpdateData was null or undefined when calling createModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelCreateUpdateData !== undefined ? modelCreateUpdateData : {}) : (modelCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalConnection(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteExternalConnection.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deleteExternalConnection.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling deleteExternalConnection.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/external/{instanceId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteModel.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deleteModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupTypeIgnoreAccess(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling findGroupTypeIgnoreAccess.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling findGroupTypeIgnoreAccess.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/group`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogCategories(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/catalog-category`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConnectionsInfo(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getExternalConnectionsInfo.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getExternalConnectionsInfo.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/external`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedModels(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/models/featured`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesStatus(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstancesStatus.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstancesStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instances`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByModel(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getLogsByModel.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getLogsByModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/logs`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelBillingSettings(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelBillingSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelBillingSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/billing`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfo(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelInfo.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelInfo.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelListRequest} modelListRequest 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelList(account: string, modelListRequest: ModelListRequest, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelList.');
            }
            // verify required parameter 'modelListRequest' is not null or undefined
            if (modelListRequest === null || modelListRequest === undefined) {
                throw new RequiredError('modelListRequest','Required parameter modelListRequest was null or undefined when calling getModelList.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model-list`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelListRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelListRequest !== undefined ? modelListRequest : {}) : (modelListRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelPublicSettings(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelPublicSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelPublicSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/public-settings`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedModels(account: string, onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPagedModels.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyMy !== undefined) {
                localVarQueryParameter['onlyMy'] = onlyMy;
            }

            if (withFavorites !== undefined) {
                localVarQueryParameter['withFavorites'] = withFavorites;
            }

            if (accountField !== undefined) {
                localVarQueryParameter['accountField'] = accountField;
            }

            if (modelField !== undefined) {
                localVarQueryParameter['modelField'] = modelField;
            }

            if (onlyPublic !== undefined) {
                localVarQueryParameter['onlyPublic'] = onlyPublic;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (taskType !== undefined) {
                localVarQueryParameter['taskType'] = taskType;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (imageId !== undefined) {
                localVarQueryParameter['imageId'] = imageId;
            }

            if (dataImageId !== undefined) {
                localVarQueryParameter['dataImageId'] = dataImageId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicModels(onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/models`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyMy !== undefined) {
                localVarQueryParameter['onlyMy'] = onlyMy;
            }

            if (withFavorites !== undefined) {
                localVarQueryParameter['withFavorites'] = withFavorites;
            }

            if (accountField !== undefined) {
                localVarQueryParameter['accountField'] = accountField;
            }

            if (modelField !== undefined) {
                localVarQueryParameter['modelField'] = modelField;
            }

            if (onlyPublic !== undefined) {
                localVarQueryParameter['onlyPublic'] = onlyPublic;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (taskType !== undefined) {
                localVarQueryParameter['taskType'] = taskType;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (imageId !== undefined) {
                localVarQueryParameter['imageId'] = imageId;
            }

            if (dataImageId !== undefined) {
                localVarQueryParameter['dataImageId'] = dataImageId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDescriptor(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getServiceDescriptor.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getServiceDescriptor.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/action-descriptor`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleDoc(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getSimpleDoc.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getSimpleDoc.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/simple-doc`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastJob(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling lastJob.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling lastJob.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/last-job`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {boolean} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFavorite(account: string, model: string, body: boolean, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling setFavorite.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling setFavorite.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setFavorite.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/favorite`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRequestedInstancesCount(account: string, model: string, body: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling setRequestedInstancesCount.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling setRequestedInstancesCount.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setRequestedInstancesCount.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instances`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ServiceDescriptorData} serviceDescriptorData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setServiceDescriptorDoc(account: string, model: string, serviceDescriptorData: ServiceDescriptorData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling setServiceDescriptorDoc.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling setServiceDescriptorDoc.');
            }
            // verify required parameter 'serviceDescriptorData' is not null or undefined
            if (serviceDescriptorData === null || serviceDescriptorData === undefined) {
                throw new RequiredError('serviceDescriptorData','Required parameter serviceDescriptorData was null or undefined when calling setServiceDescriptorDoc.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/service-descriptor`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof serviceDescriptorData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serviceDescriptorData !== undefined ? serviceDescriptorData : {}) : (serviceDescriptorData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSimpleDoc(account: string, model: string, body: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling setSimpleDoc.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling setSimpleDoc.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setSimpleDoc.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/simple-doc`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain;charset=UTF-8';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortModelStatus(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling shortModelStatus.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling shortModelStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/status`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNewInstance(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling startNewInstance.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling startNewInstance.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/start`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSingleInstance(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling startSingleInstance.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling startSingleInstance.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instances/single`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateLastActivity.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateLastActivity.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/activity`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModel(account: string, model: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModel.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModel.');
            }
            // verify required parameter 'modelCreateUpdateData' is not null or undefined
            if (modelCreateUpdateData === null || modelCreateUpdateData === undefined) {
                throw new RequiredError('modelCreateUpdateData','Required parameter modelCreateUpdateData was null or undefined when calling updateModel.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelCreateUpdateData !== undefined ? modelCreateUpdateData : {}) : (modelCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelArchiveSettingsData} modelArchiveSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelArchiveSettings(account: string, model: string, modelArchiveSettingsData: ModelArchiveSettingsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModelArchiveSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModelArchiveSettings.');
            }
            // verify required parameter 'modelArchiveSettingsData' is not null or undefined
            if (modelArchiveSettingsData === null || modelArchiveSettingsData === undefined) {
                throw new RequiredError('modelArchiveSettingsData','Required parameter modelArchiveSettingsData was null or undefined when calling updateModelArchiveSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/archive`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelArchiveSettingsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelArchiveSettingsData !== undefined ? modelArchiveSettingsData : {}) : (modelArchiveSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelBillingSettingsData} modelBillingSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelBillingSettings(account: string, model: string, modelBillingSettingsData: ModelBillingSettingsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModelBillingSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModelBillingSettings.');
            }
            // verify required parameter 'modelBillingSettingsData' is not null or undefined
            if (modelBillingSettingsData === null || modelBillingSettingsData === undefined) {
                throw new RequiredError('modelBillingSettingsData','Required parameter modelBillingSettingsData was null or undefined when calling updateModelBillingSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/billing`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelBillingSettingsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelBillingSettingsData !== undefined ? modelBillingSettingsData : {}) : (modelBillingSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelHttpSettingsData} modelHttpSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelHttpSettings(account: string, model: string, modelHttpSettingsData: ModelHttpSettingsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModelHttpSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModelHttpSettings.');
            }
            // verify required parameter 'modelHttpSettingsData' is not null or undefined
            if (modelHttpSettingsData === null || modelHttpSettingsData === undefined) {
                throw new RequiredError('modelHttpSettingsData','Required parameter modelHttpSettingsData was null or undefined when calling updateModelHttpSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/http`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelHttpSettingsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelHttpSettingsData !== undefined ? modelHttpSettingsData : {}) : (modelHttpSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelPublicSettingsData} modelPublicSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelPublicSettings(account: string, model: string, modelPublicSettingsData: ModelPublicSettingsData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateModelPublicSettings.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateModelPublicSettings.');
            }
            // verify required parameter 'modelPublicSettingsData' is not null or undefined
            if (modelPublicSettingsData === null || modelPublicSettingsData === undefined) {
                throw new RequiredError('modelPublicSettingsData','Required parameter modelPublicSettingsData was null or undefined when calling updateModelPublicSettings.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/public-settings`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelPublicSettingsData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelPublicSettingsData !== undefined ? modelPublicSettingsData : {}) : (modelPublicSettingsData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelEndpointApi - functional programming interface
 * @export
 */
export const ModelEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCustomData(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).clearCustomData(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClonedModel(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createClonedModel(account, model, name, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {boolean} [hidden] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDerivedModel(account: string, model: string, name: string, hidden?: boolean, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createDerivedModel(account, model, name, hidden, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalApplicationConnection(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalConnectionInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createExternalApplicationConnection(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [httpHost] 
         * @param {number} [httpPort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalConnection(account: string, model: string, httpHost?: string, httpPort?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalConnectionInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createExternalConnection(account, model, httpHost, httpPort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModel(account: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).createModel(account, modelCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalConnection(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalConnectionInfoData>> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).deleteExternalConnection(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).deleteModel(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupTypeIgnoreAccess(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).findGroupTypeIgnoreAccess(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogCategories(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogCategoryData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getCatalogCategories(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConnectionsInfo(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalConnectionInfoData>> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getExternalConnectionsInfo(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedModels(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInfoData>> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getFeaturedModels(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesStatus(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstancesStatusData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getInstancesStatus(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByModel(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getLogsByModel(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelBillingSettings(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelBillingSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getModelBillingSettings(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfo(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getModelInfo(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelListRequest} modelListRequest 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelList(account: string, modelListRequest: ModelListRequest, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelInfoData>> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getModelList(account, modelListRequest, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelPublicSettings(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelPublicSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getModelPublicSettings(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedModels(account: string, onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getPagedModels(account, onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicModels(onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getPublicModels(onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDescriptor(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceDescriptorData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getServiceDescriptor(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleDoc(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).getSimpleDoc(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastJob(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).lastJob(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {boolean} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFavorite(account: string, model: string, body: boolean, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setFavorite(account, model, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRequestedInstancesCount(account: string, model: string, body: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setRequestedInstancesCount(account, model, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ServiceDescriptorData} serviceDescriptorData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setServiceDescriptorDoc(account: string, model: string, serviceDescriptorData: ServiceDescriptorData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setServiceDescriptorDoc(account, model, serviceDescriptorData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSimpleDoc(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).setSimpleDoc(account, model, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortModelStatus(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelShortStatusData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).shortModelStatus(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNewInstance(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).startNewInstance(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSingleInstance(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).startSingleInstance(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateLastActivity(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModel(account: string, model: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModel(account, model, modelCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelArchiveSettingsData} modelArchiveSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelArchiveSettings(account: string, model: string, modelArchiveSettingsData: ModelArchiveSettingsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelArchiveSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModelArchiveSettings(account, model, modelArchiveSettingsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelBillingSettingsData} modelBillingSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelBillingSettings(account: string, model: string, modelBillingSettingsData: ModelBillingSettingsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelBillingSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModelBillingSettings(account, model, modelBillingSettingsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelHttpSettingsData} modelHttpSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelHttpSettings(account: string, model: string, modelHttpSettingsData: ModelHttpSettingsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelHttpSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModelHttpSettings(account, model, modelHttpSettingsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelPublicSettingsData} modelPublicSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelPublicSettings(account: string, model: string, modelPublicSettingsData: ModelPublicSettingsData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelPublicSettingsData> {
            const localVarAxiosArgs = ModelEndpointApiAxiosParamCreator(configuration).updateModelPublicSettings(account, model, modelPublicSettingsData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ModelEndpointApi - factory interface
 * @export
 */
export const ModelEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCustomData(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<object> {
            return ModelEndpointApiFp(configuration).clearCustomData(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClonedModel(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ModelEndpointApiFp(configuration).createClonedModel(account, model, name, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} name 
         * @param {boolean} [hidden] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDerivedModel(account: string, model: string, name: string, hidden?: boolean, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ModelEndpointApiFp(configuration).createDerivedModel(account, model, name, hidden, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalApplicationConnection(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ExternalConnectionInfoData> {
            return ModelEndpointApiFp(configuration).createExternalApplicationConnection(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [httpHost] 
         * @param {number} [httpPort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExternalConnection(account: string, model: string, httpHost?: string, httpPort?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ExternalConnectionInfoData> {
            return ModelEndpointApiFp(configuration).createExternalConnection(account, model, httpHost, httpPort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createModel(account: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ModelEndpointApiFp(configuration).createModel(account, modelCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExternalConnection(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ExternalConnectionInfoData>> {
            return ModelEndpointApiFp(configuration).deleteExternalConnection(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).deleteModel(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGroupTypeIgnoreAccess(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ModelEndpointApiFp(configuration).findGroupTypeIgnoreAccess(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCatalogCategories(MLP_API_KEY?: string, options?: any): AxiosPromise<CatalogCategoryData> {
            return ModelEndpointApiFp(configuration).getCatalogCategories(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConnectionsInfo(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ExternalConnectionInfoData>> {
            return ModelEndpointApiFp(configuration).getExternalConnectionsInfo(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedModels(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ModelInfoData>> {
            return ModelEndpointApiFp(configuration).getFeaturedModels(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesStatus(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<InstancesStatusData> {
            return ModelEndpointApiFp(configuration).getInstancesStatus(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByModel(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
            return ModelEndpointApiFp(configuration).getLogsByModel(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelBillingSettings(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelBillingSettingsData> {
            return ModelEndpointApiFp(configuration).getModelBillingSettings(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfo(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ModelEndpointApiFp(configuration).getModelInfo(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelListRequest} modelListRequest 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelList(account: string, modelListRequest: ModelListRequest, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ModelInfoData>> {
            return ModelEndpointApiFp(configuration).getModelList(account, modelListRequest, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelPublicSettings(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelPublicSettingsData> {
            return ModelEndpointApiFp(configuration).getModelPublicSettings(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagedModels(account: string, onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PagedModelInfoData> {
            return ModelEndpointApiFp(configuration).getPagedModels(account, onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {boolean} [onlyMy] 
         * @param {boolean} [withFavorites] 
         * @param {string} [accountField] 
         * @param {string} [modelField] 
         * @param {boolean} [onlyPublic] 
         * @param {number} [groupId] 
         * @param {string} [taskType] 
         * @param {string} [language] 
         * @param {number} [imageId] 
         * @param {number} [dataImageId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicModels(onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PagedModelInfoData> {
            return ModelEndpointApiFp(configuration).getPublicModels(onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceDescriptor(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ServiceDescriptorData> {
            return ModelEndpointApiFp(configuration).getServiceDescriptor(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleDoc(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ModelEndpointApiFp(configuration).getSimpleDoc(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastJob(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
            return ModelEndpointApiFp(configuration).lastJob(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {boolean} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFavorite(account: string, model: string, body: boolean, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).setFavorite(account, model, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRequestedInstancesCount(account: string, model: string, body: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).setRequestedInstancesCount(account, model, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ServiceDescriptorData} serviceDescriptorData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setServiceDescriptorDoc(account: string, model: string, serviceDescriptorData: ServiceDescriptorData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).setServiceDescriptorDoc(account, model, serviceDescriptorData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSimpleDoc(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ModelEndpointApiFp(configuration).setSimpleDoc(account, model, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortModelStatus(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelShortStatusData> {
            return ModelEndpointApiFp(configuration).shortModelStatus(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startNewInstance(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).startNewInstance(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSingleInstance(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).startSingleInstance(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastActivity(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelEndpointApiFp(configuration).updateLastActivity(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelCreateUpdateData} modelCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModel(account: string, model: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ModelEndpointApiFp(configuration).updateModel(account, model, modelCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelArchiveSettingsData} modelArchiveSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelArchiveSettings(account: string, model: string, modelArchiveSettingsData: ModelArchiveSettingsData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelArchiveSettingsData> {
            return ModelEndpointApiFp(configuration).updateModelArchiveSettings(account, model, modelArchiveSettingsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelBillingSettingsData} modelBillingSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelBillingSettings(account: string, model: string, modelBillingSettingsData: ModelBillingSettingsData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelBillingSettingsData> {
            return ModelEndpointApiFp(configuration).updateModelBillingSettings(account, model, modelBillingSettingsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelHttpSettingsData} modelHttpSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelHttpSettings(account: string, model: string, modelHttpSettingsData: ModelHttpSettingsData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelHttpSettingsData> {
            return ModelEndpointApiFp(configuration).updateModelHttpSettings(account, model, modelHttpSettingsData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ModelPublicSettingsData} modelPublicSettingsData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateModelPublicSettings(account: string, model: string, modelPublicSettingsData: ModelPublicSettingsData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelPublicSettingsData> {
            return ModelEndpointApiFp(configuration).updateModelPublicSettings(account, model, modelPublicSettingsData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ModelEndpointApi - object-oriented interface
 * @export
 * @class ModelEndpointApi
 * @extends {BaseAPI}
 */
export class ModelEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public clearCustomData(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).clearCustomData(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} name 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public createClonedModel(account: string, model: string, name: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).createClonedModel(account, model, name, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} name 
     * @param {boolean} [hidden] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public createDerivedModel(account: string, model: string, name: string, hidden?: boolean, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).createDerivedModel(account, model, name, hidden, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public createExternalApplicationConnection(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).createExternalApplicationConnection(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [httpHost] 
     * @param {number} [httpPort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public createExternalConnection(account: string, model: string, httpHost?: string, httpPort?: number, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).createExternalConnection(account, model, httpHost, httpPort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {ModelCreateUpdateData} modelCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public createModel(account: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).createModel(account, modelCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public deleteExternalConnection(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).deleteExternalConnection(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public deleteModel(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).deleteModel(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public findGroupTypeIgnoreAccess(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).findGroupTypeIgnoreAccess(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getCatalogCategories(MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getCatalogCategories(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getExternalConnectionsInfo(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getExternalConnectionsInfo(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getFeaturedModels(MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getFeaturedModels(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getInstancesStatus(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getInstancesStatus(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getLogsByModel(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getLogsByModel(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getModelBillingSettings(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getModelBillingSettings(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getModelInfo(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getModelInfo(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {ModelListRequest} modelListRequest 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getModelList(account: string, modelListRequest: ModelListRequest, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getModelList(account, modelListRequest, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getModelPublicSettings(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getModelPublicSettings(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {boolean} [onlyMy] 
     * @param {boolean} [withFavorites] 
     * @param {string} [accountField] 
     * @param {string} [modelField] 
     * @param {boolean} [onlyPublic] 
     * @param {number} [groupId] 
     * @param {string} [taskType] 
     * @param {string} [language] 
     * @param {number} [imageId] 
     * @param {number} [dataImageId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getPagedModels(account: string, onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getPagedModels(account, onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {boolean} [onlyMy] 
     * @param {boolean} [withFavorites] 
     * @param {string} [accountField] 
     * @param {string} [modelField] 
     * @param {boolean} [onlyPublic] 
     * @param {number} [groupId] 
     * @param {string} [taskType] 
     * @param {string} [language] 
     * @param {number} [imageId] 
     * @param {number} [dataImageId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getPublicModels(onlyMy?: boolean, withFavorites?: boolean, accountField?: string, modelField?: string, onlyPublic?: boolean, groupId?: number, taskType?: string, language?: string, imageId?: number, dataImageId?: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getPublicModels(onlyMy, withFavorites, accountField, modelField, onlyPublic, groupId, taskType, language, imageId, dataImageId, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getServiceDescriptor(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getServiceDescriptor(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public getSimpleDoc(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).getSimpleDoc(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public lastJob(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).lastJob(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {boolean} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public setFavorite(account: string, model: string, body: boolean, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).setFavorite(account, model, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public setRequestedInstancesCount(account: string, model: string, body: number, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).setRequestedInstancesCount(account, model, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ServiceDescriptorData} serviceDescriptorData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public setServiceDescriptorDoc(account: string, model: string, serviceDescriptorData: ServiceDescriptorData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).setServiceDescriptorDoc(account, model, serviceDescriptorData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public setSimpleDoc(account: string, model: string, body: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).setSimpleDoc(account, model, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public shortModelStatus(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).shortModelStatus(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public startNewInstance(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).startNewInstance(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public startSingleInstance(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).startSingleInstance(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateLastActivity(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateLastActivity(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ModelCreateUpdateData} modelCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateModel(account: string, model: string, modelCreateUpdateData: ModelCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateModel(account, model, modelCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ModelArchiveSettingsData} modelArchiveSettingsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateModelArchiveSettings(account: string, model: string, modelArchiveSettingsData: ModelArchiveSettingsData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateModelArchiveSettings(account, model, modelArchiveSettingsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ModelBillingSettingsData} modelBillingSettingsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateModelBillingSettings(account: string, model: string, modelBillingSettingsData: ModelBillingSettingsData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateModelBillingSettings(account, model, modelBillingSettingsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ModelHttpSettingsData} modelHttpSettingsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateModelHttpSettings(account: string, model: string, modelHttpSettingsData: ModelHttpSettingsData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateModelHttpSettings(account, model, modelHttpSettingsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ModelPublicSettingsData} modelPublicSettingsData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelEndpointApi
     */
    public updateModelPublicSettings(account: string, model: string, modelPublicSettingsData: ModelPublicSettingsData, MLP_API_KEY?: string, options?: any) {
        return ModelEndpointApiFp(this.configuration).updateModelPublicSettings(account, model, modelPublicSettingsData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ModelGroupEndpointApi - axios parameter creator
 * @export
 */
export const ModelGroupEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignModelToGroup(account: string, model: string, groupId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling assignModelToGroup.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling assignModelToGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling assignModelToGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/model-group/{groupId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createGroup.');
            }
            // verify required parameter 'modelGroupData' is not null or undefined
            if (modelGroupData === null || modelGroupData === undefined) {
                throw new RequiredError('modelGroupData','Required parameter modelGroupData was null or undefined when calling createGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model-group`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelGroupData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelGroupData !== undefined ? modelGroupData : {}) : (modelGroupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(account: string, groupId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model-group/{groupId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getGroups.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model-group`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeModelFromGroup(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling removeModelFromGroup.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling removeModelFromGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/model-group`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateGroup.');
            }
            // verify required parameter 'modelGroupData' is not null or undefined
            if (modelGroupData === null || modelGroupData === undefined) {
                throw new RequiredError('modelGroupData','Required parameter modelGroupData was null or undefined when calling updateGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model-group`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof modelGroupData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modelGroupData !== undefined ? modelGroupData : {}) : (modelGroupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelGroupEndpointApi - functional programming interface
 * @export
 */
export const ModelGroupEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignModelToGroup(account: string, model: string, groupId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).assignModelToGroup(account, model, groupId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelGroupData> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).createGroup(account, modelGroupData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(account: string, groupId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).deleteGroup(account, groupId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelGroupData>> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).getGroups(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeModelFromGroup(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).removeModelFromGroup(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelGroupData> {
            const localVarAxiosArgs = ModelGroupEndpointApiAxiosParamCreator(configuration).updateGroup(account, modelGroupData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ModelGroupEndpointApi - factory interface
 * @export
 */
export const ModelGroupEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignModelToGroup(account: string, model: string, groupId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelGroupEndpointApiFp(configuration).assignModelToGroup(account, model, groupId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelGroupData> {
            return ModelGroupEndpointApiFp(configuration).createGroup(account, modelGroupData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} groupId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(account: string, groupId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelGroupEndpointApiFp(configuration).deleteGroup(account, groupId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ModelGroupData>> {
            return ModelGroupEndpointApiFp(configuration).getGroups(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeModelFromGroup(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelGroupEndpointApiFp(configuration).removeModelFromGroup(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {ModelGroupData} modelGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelGroupData> {
            return ModelGroupEndpointApiFp(configuration).updateGroup(account, modelGroupData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ModelGroupEndpointApi - object-oriented interface
 * @export
 * @class ModelGroupEndpointApi
 * @extends {BaseAPI}
 */
export class ModelGroupEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} groupId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public assignModelToGroup(account: string, model: string, groupId: number, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).assignModelToGroup(account, model, groupId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {ModelGroupData} modelGroupData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public createGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).createGroup(account, modelGroupData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} groupId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public deleteGroup(account: string, groupId: number, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).deleteGroup(account, groupId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public getGroups(account: string, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).getGroups(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public removeModelFromGroup(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).removeModelFromGroup(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {ModelGroupData} modelGroupData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelGroupEndpointApi
     */
    public updateGroup(account: string, modelGroupData: ModelGroupData, MLP_API_KEY?: string, options?: any) {
        return ModelGroupEndpointApiFp(this.configuration).updateGroup(account, modelGroupData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ModelInstanceEndpointApi - axios parameter creator
 * @export
 */
export const ModelInstanceEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceEvents(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstanceEvents.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstanceEvents.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceEvents.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/events`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {boolean} [previousContainer] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceLogFile(account: string, model: string, instanceId: number, previousContainer?: boolean, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstanceLogFile.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstanceLogFile.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstanceLogFile.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/file/logs`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (previousContainer !== undefined) {
                localVarQueryParameter['previousContainer'] = previousContainer;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} path 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancePodFile(account: string, model: string, instanceId: number, path: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getInstancePodFile.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getInstancePodFile.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getInstancePodFile.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new RequiredError('path','Required parameter path was null or undefined when calling getInstancePodFile.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/file`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceEnvironment(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelInstanceEnvironment.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelInstanceEnvironment.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getModelInstanceEnvironment.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/environment`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceLastState(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelInstanceLastState.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelInstanceLastState.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getModelInstanceLastState.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/laststate`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceStartTime(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelInstanceStartTime.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelInstanceStartTime.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling getModelInstanceStartTime.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/timing`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstances(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getModelInstances.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getModelInstances.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling stopInstance.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling stopInstance.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling stopInstance.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/stop`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling terminateInstance.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling terminateInstance.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling terminateInstance.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/terminate`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {InstanceMetadata} instanceMetadata 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstanceMetadata(account: string, model: string, instanceId: number, instanceMetadata: InstanceMetadata, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateInstanceMetadata.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updateInstanceMetadata.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling updateInstanceMetadata.');
            }
            // verify required parameter 'instanceMetadata' is not null or undefined
            if (instanceMetadata === null || instanceMetadata === undefined) {
                throw new RequiredError('instanceMetadata','Required parameter instanceMetadata was null or undefined when calling updateInstanceMetadata.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/metadata`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof instanceMetadata !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(instanceMetadata !== undefined ? instanceMetadata : {}) : (instanceMetadata || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastHeartBeats(requestBody: Array<number>, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling updateLastHeartBeats.');
            }
            const localVarPath = `/api/mlpcore/update-heartbeats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelInstanceEndpointApi - functional programming interface
 * @export
 */
export const ModelInstanceEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceEvents(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceEventData> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstanceEvents(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {boolean} [previousContainer] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceLogFile(account: string, model: string, instanceId: number, previousContainer?: boolean, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstanceLogFile(account, model, instanceId, previousContainer, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} path 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancePodFile(account: string, model: string, instanceId: number, path: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getInstancePodFile(account, model, instanceId, path, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceEnvironment(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceEnvironmentData> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstanceEnvironment(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceLastState(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceLastState> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstanceLastState(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceStartTime(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelStartTimeData> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstanceStartTime(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstances(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInstanceListData> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).getModelInstances(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).stopInstance(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).terminateInstance(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {InstanceMetadata} instanceMetadata 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstanceMetadata(account: string, model: string, instanceId: number, instanceMetadata: InstanceMetadata, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).updateInstanceMetadata(account, model, instanceId, instanceMetadata, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastHeartBeats(requestBody: Array<number>, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ModelInstanceEndpointApiAxiosParamCreator(configuration).updateLastHeartBeats(requestBody, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ModelInstanceEndpointApi - factory interface
 * @export
 */
export const ModelInstanceEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceEvents(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<InstanceEventData> {
            return ModelInstanceEndpointApiFp(configuration).getInstanceEvents(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {boolean} [previousContainer] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceLogFile(account: string, model: string, instanceId: number, previousContainer?: boolean, MLP_API_KEY?: string, options?: any): AxiosPromise<any> {
            return ModelInstanceEndpointApiFp(configuration).getInstanceLogFile(account, model, instanceId, previousContainer, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} path 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancePodFile(account: string, model: string, instanceId: number, path: string, MLP_API_KEY?: string, options?: any): AxiosPromise<any> {
            return ModelInstanceEndpointApiFp(configuration).getInstancePodFile(account, model, instanceId, path, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceEnvironment(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<InstanceEnvironmentData> {
            return ModelInstanceEndpointApiFp(configuration).getModelInstanceEnvironment(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceLastState(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<InstanceLastState> {
            return ModelInstanceEndpointApiFp(configuration).getModelInstanceLastState(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstanceStartTime(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelStartTimeData> {
            return ModelInstanceEndpointApiFp(configuration).getModelInstanceStartTime(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInstances(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInstanceListData> {
            return ModelInstanceEndpointApiFp(configuration).getModelInstances(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelInstanceEndpointApiFp(configuration).stopInstance(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelInstanceEndpointApiFp(configuration).terminateInstance(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {InstanceMetadata} instanceMetadata 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstanceMetadata(account: string, model: string, instanceId: number, instanceMetadata: InstanceMetadata, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelInstanceEndpointApiFp(configuration).updateInstanceMetadata(account, model, instanceId, instanceMetadata, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<number>} requestBody 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLastHeartBeats(requestBody: Array<number>, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ModelInstanceEndpointApiFp(configuration).updateLastHeartBeats(requestBody, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ModelInstanceEndpointApi - object-oriented interface
 * @export
 * @class ModelInstanceEndpointApi
 * @extends {BaseAPI}
 */
export class ModelInstanceEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getInstanceEvents(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getInstanceEvents(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {boolean} [previousContainer] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getInstanceLogFile(account: string, model: string, instanceId: number, previousContainer?: boolean, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getInstanceLogFile(account, model, instanceId, previousContainer, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} path 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getInstancePodFile(account: string, model: string, instanceId: number, path: string, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getInstancePodFile(account, model, instanceId, path, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getModelInstanceEnvironment(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getModelInstanceEnvironment(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getModelInstanceLastState(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getModelInstanceLastState(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getModelInstanceStartTime(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getModelInstanceStartTime(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public getModelInstances(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).getModelInstances(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public stopInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).stopInstance(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public terminateInstance(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).terminateInstance(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {InstanceMetadata} instanceMetadata 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public updateInstanceMetadata(account: string, model: string, instanceId: number, instanceMetadata: InstanceMetadata, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).updateInstanceMetadata(account, model, instanceId, instanceMetadata, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} requestBody 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelInstanceEndpointApi
     */
    public updateLastHeartBeats(requestBody: Array<number>, MLP_API_KEY?: string, options?: any) {
        return ModelInstanceEndpointApiFp(this.configuration).updateLastHeartBeats(requestBody, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * PredictConfigEndpointApi - axios parameter creator
 * @export
 */
export const PredictConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPredictConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createPredictConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling createPredictConfig.');
            }
            // verify required parameter 'configCreateUpdateData' is not null or undefined
            if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
                throw new RequiredError('configCreateUpdateData','Required parameter configCreateUpdateData was null or undefined when calling createPredictConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/predict-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof configCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {}) : (configCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deletePredictConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling deletePredictConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling deletePredictConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/predict-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPredictConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling getPredictConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling getPredictConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/predict-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPredictConfigs(account: string, model: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listPredictConfigs.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling listPredictConfigs.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/predict-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredictConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updatePredictConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling updatePredictConfig.');
            }
            // verify required parameter 'configId' is not null or undefined
            if (configId === null || configId === undefined) {
                throw new RequiredError('configId','Required parameter configId was null or undefined when calling updatePredictConfig.');
            }
            // verify required parameter 'configCreateUpdateData' is not null or undefined
            if (configCreateUpdateData === null || configCreateUpdateData === undefined) {
                throw new RequiredError('configCreateUpdateData','Required parameter configCreateUpdateData was null or undefined when calling updatePredictConfig.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/predict-config/{configId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"configId"}}`, encodeURIComponent(String(configId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof configCreateUpdateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(configCreateUpdateData !== undefined ? configCreateUpdateData : {}) : (configCreateUpdateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictConfigEndpointApi - functional programming interface
 * @export
 */
export const PredictConfigEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPredictConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
            const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).createPredictConfig(account, model, configCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).deletePredictConfig(account, model, configId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
            const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).getPredictConfig(account, model, configId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPredictConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredictConfigData>> {
            const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).listPredictConfigs(account, model, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredictConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictConfigData> {
            const localVarAxiosArgs = PredictConfigEndpointApiAxiosParamCreator(configuration).updatePredictConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PredictConfigEndpointApi - factory interface
 * @export
 */
export const PredictConfigEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPredictConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<PredictConfigData> {
            return PredictConfigEndpointApiFp(configuration).createPredictConfig(account, model, configCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return PredictConfigEndpointApiFp(configuration).deletePredictConfig(account, model, configId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<PredictConfigData> {
            return PredictConfigEndpointApiFp(configuration).getPredictConfig(account, model, configId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPredictConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<PredictConfigData>> {
            return PredictConfigEndpointApiFp(configuration).listPredictConfigs(account, model, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} configId 
         * @param {ConfigCreateUpdateData} configCreateUpdateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredictConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any): AxiosPromise<PredictConfigData> {
            return PredictConfigEndpointApiFp(configuration).updatePredictConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * PredictConfigEndpointApi - object-oriented interface
 * @export
 * @class PredictConfigEndpointApi
 * @extends {BaseAPI}
 */
export class PredictConfigEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ConfigCreateUpdateData} configCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictConfigEndpointApi
     */
    public createPredictConfig(account: string, model: string, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return PredictConfigEndpointApiFp(this.configuration).createPredictConfig(account, model, configCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictConfigEndpointApi
     */
    public deletePredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any) {
        return PredictConfigEndpointApiFp(this.configuration).deletePredictConfig(account, model, configId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictConfigEndpointApi
     */
    public getPredictConfig(account: string, model: string, configId: number, MLP_API_KEY?: string, options?: any) {
        return PredictConfigEndpointApiFp(this.configuration).getPredictConfig(account, model, configId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictConfigEndpointApi
     */
    public listPredictConfigs(account: string, model: string, MLP_API_KEY?: string, options?: any) {
        return PredictConfigEndpointApiFp(this.configuration).listPredictConfigs(account, model, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} configId 
     * @param {ConfigCreateUpdateData} configCreateUpdateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictConfigEndpointApi
     */
    public updatePredictConfig(account: string, model: string, configId: number, configCreateUpdateData: ConfigCreateUpdateData, MLP_API_KEY?: string, options?: any) {
        return PredictConfigEndpointApiFp(this.configuration).updatePredictConfig(account, model, configId, configCreateUpdateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ProcessEndpointApi - axios parameter creator
 * @export
 */
export const ProcessEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {CrossValidationRequestData} crossValidationRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crossValidation(account: string, model: string, crossValidationRequestData: CrossValidationRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling crossValidation.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling crossValidation.');
            }
            // verify required parameter 'crossValidationRequestData' is not null or undefined
            if (crossValidationRequestData === null || crossValidationRequestData === undefined) {
                throw new RequiredError('crossValidationRequestData','Required parameter crossValidationRequestData was null or undefined when calling crossValidation.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/cross-validation`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof crossValidationRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(crossValidationRequestData !== undefined ? crossValidationRequestData : {}) : (crossValidationRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ExtendedRequestData} extendedRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ext(account: string, model: string, extendedRequestData: ExtendedRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ext.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling ext.');
            }
            // verify required parameter 'extendedRequestData' is not null or undefined
            if (extendedRequestData === null || extendedRequestData === undefined) {
                throw new RequiredError('extendedRequestData','Required parameter extendedRequestData was null or undefined when calling ext.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/ext`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof extendedRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(extendedRequestData !== undefined ? extendedRequestData : {}) : (extendedRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {FitRequestData} fitRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fit(account: string, model: string, fitRequestData: FitRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling fit.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling fit.');
            }
            // verify required parameter 'fitRequestData' is not null or undefined
            if (fitRequestData === null || fitRequestData === undefined) {
                throw new RequiredError('fitRequestData','Required parameter fitRequestData was null or undefined when calling fit.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fitRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fitRequestData !== undefined ? fitRequestData : {}) : (fitRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Fit2RequestData} fit2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fitV2(account: string, model: string, fit2RequestData: Fit2RequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling fitV2.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling fitV2.');
            }
            // verify required parameter 'fit2RequestData' is not null or undefined
            if (fit2RequestData === null || fit2RequestData === undefined) {
                throw new RequiredError('fit2RequestData','Required parameter fit2RequestData was null or undefined when calling fitV2.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/fit-v2`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof fit2RequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fit2RequestData !== undefined ? fit2RequestData : {}) : (fit2RequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfoOld(accountField: string, modelField: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountField' is not null or undefined
            if (accountField === null || accountField === undefined) {
                throw new RequiredError('accountField','Required parameter accountField was null or undefined when calling getModelInfoOld.');
            }
            // verify required parameter 'modelField' is not null or undefined
            if (modelField === null || modelField === undefined) {
                throw new RequiredError('modelField','Required parameter modelField was null or undefined when calling getModelInfoOld.');
            }
            const localVarPath = `/p/{accountField}/{modelField}`
                .replace(`{${"accountField"}}`, encodeURIComponent(String(accountField)))
                .replace(`{${"modelField"}}`, encodeURIComponent(String(modelField)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {number} [configId] 
         * @param {string} [dataType] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predict(account: string, model: string, body: string, configId?: number, dataType?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling predict.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling predict.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling predict.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/predict`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (configId !== undefined) {
                localVarQueryParameter['configId'] = configId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['dataType'] = dataType;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {PredictRequestData} predictRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfig(account: string, model: string, predictRequestData: PredictRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling predictWithConfig.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling predictWithConfig.');
            }
            // verify required parameter 'predictRequestData' is not null or undefined
            if (predictRequestData === null || predictRequestData === undefined) {
                throw new RequiredError('predictRequestData','Required parameter predictRequestData was null or undefined when calling predictWithConfig.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/predict-with-config`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof predictRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(predictRequestData !== undefined ? predictRequestData : {}) : (predictRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling predictWithConfigV2.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling predictWithConfigV2.');
            }
            // verify required parameter 'predict2RequestData' is not null or undefined
            if (predict2RequestData === null || predict2RequestData === undefined) {
                throw new RequiredError('predict2RequestData','Required parameter predict2RequestData was null or undefined when calling predictWithConfigV2.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/predict-with-config-v2`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof predict2RequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(predict2RequestData !== undefined ? predict2RequestData : {}) : (predict2RequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2Stream(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling predictWithConfigV2Stream.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling predictWithConfigV2Stream.');
            }
            // verify required parameter 'predict2RequestData' is not null or undefined
            if (predict2RequestData === null || predict2RequestData === undefined) {
                throw new RequiredError('predict2RequestData','Required parameter predict2RequestData was null or undefined when calling predictWithConfigV2Stream.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/predict-with-config-v2-stream`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof predict2RequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(predict2RequestData !== undefined ? predict2RequestData : {}) : (predict2RequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servletPredict(accountField: string, modelField: string, body: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'accountField' is not null or undefined
            if (accountField === null || accountField === undefined) {
                throw new RequiredError('accountField','Required parameter accountField was null or undefined when calling servletPredict.');
            }
            // verify required parameter 'modelField' is not null or undefined
            if (modelField === null || modelField === undefined) {
                throw new RequiredError('modelField','Required parameter modelField was null or undefined when calling servletPredict.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling servletPredict.');
            }
            const localVarPath = `/p/{accountField}/{modelField}`
                .replace(`{${"accountField"}}`, encodeURIComponent(String(accountField)))
                .replace(`{${"modelField"}}`, encodeURIComponent(String(modelField)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} text 
         * @param {string} [voice] 
         * @param {'LINEAR16_PCM'} [audioEncoding] 
         * @param {number} [sampleRateHertz] 
         * @param {number} [chunkSizeKb] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamGet(account: string, model: string, text: string, voice?: string, audioEncoding?: 'LINEAR16_PCM', sampleRateHertz?: number, chunkSizeKb?: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ttsStreamGet.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling ttsStreamGet.');
            }
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text','Required parameter text was null or undefined when calling ttsStreamGet.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/tts`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (voice !== undefined) {
                localVarQueryParameter['voice'] = voice;
            }

            if (audioEncoding !== undefined) {
                localVarQueryParameter['audioEncoding'] = audioEncoding;
            }

            if (sampleRateHertz !== undefined) {
                localVarQueryParameter['sampleRateHertz'] = sampleRateHertz;
            }

            if (chunkSizeKb !== undefined) {
                localVarQueryParameter['chunkSizeKb'] = chunkSizeKb;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {TtsRequestData} ttsRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamPost(account: string, model: string, ttsRequestData: TtsRequestData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling ttsStreamPost.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling ttsStreamPost.');
            }
            // verify required parameter 'ttsRequestData' is not null or undefined
            if (ttsRequestData === null || ttsRequestData === undefined) {
                throw new RequiredError('ttsRequestData','Required parameter ttsRequestData was null or undefined when calling ttsStreamPost.');
            }
            const localVarPath = `/api/mlpgateway/account/{account}/model/{model}/tts`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof ttsRequestData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ttsRequestData !== undefined ? ttsRequestData : {}) : (ttsRequestData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessEndpointApi - functional programming interface
 * @export
 */
export const ProcessEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {CrossValidationRequestData} crossValidationRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crossValidation(account: string, model: string, crossValidationRequestData: CrossValidationRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).crossValidation(account, model, crossValidationRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ExtendedRequestData} extendedRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ext(account: string, model: string, extendedRequestData: ExtendedRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).ext(account, model, extendedRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {FitRequestData} fitRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fit(account: string, model: string, fitRequestData: FitRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).fit(account, model, fitRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Fit2RequestData} fit2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fitV2(account: string, model: string, fit2RequestData: Fit2RequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobStatusData> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).fitV2(account, model, fit2RequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfoOld(accountField: string, modelField: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelInfoData> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).getModelInfoOld(accountField, modelField, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {number} [configId] 
         * @param {string} [dataType] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predict(account: string, model: string, body: string, configId?: number, dataType?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predict(account, model, body, configId, dataType, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {PredictRequestData} predictRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfig(account: string, model: string, predictRequestData: PredictRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predictWithConfig(account, model, predictRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predictWithConfigV2(account, model, predict2RequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2Stream(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBodyEmitter> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).predictWithConfigV2Stream(account, model, predict2RequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servletPredict(accountField: string, modelField: string, body: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).servletPredict(accountField, modelField, body, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} text 
         * @param {string} [voice] 
         * @param {'LINEAR16_PCM'} [audioEncoding] 
         * @param {number} [sampleRateHertz] 
         * @param {number} [chunkSizeKb] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamGet(account: string, model: string, text: string, voice?: string, audioEncoding?: 'LINEAR16_PCM', sampleRateHertz?: number, chunkSizeKb?: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBodyEmitter> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).ttsStreamGet(account, model, text, voice, audioEncoding, sampleRateHertz, chunkSizeKb, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {TtsRequestData} ttsRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamPost(account: string, model: string, ttsRequestData: TtsRequestData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBodyEmitter> {
            const localVarAxiosArgs = ProcessEndpointApiAxiosParamCreator(configuration).ttsStreamPost(account, model, ttsRequestData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProcessEndpointApi - factory interface
 * @export
 */
export const ProcessEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {CrossValidationRequestData} crossValidationRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crossValidation(account: string, model: string, crossValidationRequestData: CrossValidationRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
            return ProcessEndpointApiFp(configuration).crossValidation(account, model, crossValidationRequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {ExtendedRequestData} extendedRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ext(account: string, model: string, extendedRequestData: ExtendedRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ProcessEndpointApiFp(configuration).ext(account, model, extendedRequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {FitRequestData} fitRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fit(account: string, model: string, fitRequestData: FitRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
            return ProcessEndpointApiFp(configuration).fit(account, model, fitRequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Fit2RequestData} fit2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fitV2(account: string, model: string, fit2RequestData: Fit2RequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<JobStatusData> {
            return ProcessEndpointApiFp(configuration).fitV2(account, model, fit2RequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelInfoOld(accountField: string, modelField: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ModelInfoData> {
            return ProcessEndpointApiFp(configuration).getModelInfoOld(accountField, modelField, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} body 
         * @param {number} [configId] 
         * @param {string} [dataType] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predict(account: string, model: string, body: string, configId?: number, dataType?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ProcessEndpointApiFp(configuration).predict(account, model, body, configId, dataType, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {PredictRequestData} predictRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfig(account: string, model: string, predictRequestData: PredictRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ProcessEndpointApiFp(configuration).predictWithConfig(account, model, predictRequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ProcessEndpointApiFp(configuration).predictWithConfigV2(account, model, predict2RequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {Predict2RequestData} predict2RequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictWithConfigV2Stream(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<ResponseBodyEmitter> {
            return ProcessEndpointApiFp(configuration).predictWithConfigV2Stream(account, model, predict2RequestData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} accountField 
         * @param {string} modelField 
         * @param {string} body 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servletPredict(accountField: string, modelField: string, body: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return ProcessEndpointApiFp(configuration).servletPredict(accountField, modelField, body, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {string} text 
         * @param {string} [voice] 
         * @param {'LINEAR16_PCM'} [audioEncoding] 
         * @param {number} [sampleRateHertz] 
         * @param {number} [chunkSizeKb] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamGet(account: string, model: string, text: string, voice?: string, audioEncoding?: 'LINEAR16_PCM', sampleRateHertz?: number, chunkSizeKb?: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ResponseBodyEmitter> {
            return ProcessEndpointApiFp(configuration).ttsStreamGet(account, model, text, voice, audioEncoding, sampleRateHertz, chunkSizeKb, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {TtsRequestData} ttsRequestData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ttsStreamPost(account: string, model: string, ttsRequestData: TtsRequestData, MLP_API_KEY?: string, options?: any): AxiosPromise<ResponseBodyEmitter> {
            return ProcessEndpointApiFp(configuration).ttsStreamPost(account, model, ttsRequestData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ProcessEndpointApi - object-oriented interface
 * @export
 * @class ProcessEndpointApi
 * @extends {BaseAPI}
 */
export class ProcessEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {CrossValidationRequestData} crossValidationRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public crossValidation(account: string, model: string, crossValidationRequestData: CrossValidationRequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).crossValidation(account, model, crossValidationRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {ExtendedRequestData} extendedRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public ext(account: string, model: string, extendedRequestData: ExtendedRequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).ext(account, model, extendedRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {FitRequestData} fitRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public fit(account: string, model: string, fitRequestData: FitRequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).fit(account, model, fitRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {Fit2RequestData} fit2RequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public fitV2(account: string, model: string, fit2RequestData: Fit2RequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).fitV2(account, model, fit2RequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} accountField 
     * @param {string} modelField 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public getModelInfoOld(accountField: string, modelField: string, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).getModelInfoOld(accountField, modelField, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} body 
     * @param {number} [configId] 
     * @param {string} [dataType] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public predict(account: string, model: string, body: string, configId?: number, dataType?: string, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).predict(account, model, body, configId, dataType, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {PredictRequestData} predictRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public predictWithConfig(account: string, model: string, predictRequestData: PredictRequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).predictWithConfig(account, model, predictRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {Predict2RequestData} predict2RequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public predictWithConfigV2(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).predictWithConfigV2(account, model, predict2RequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {Predict2RequestData} predict2RequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public predictWithConfigV2Stream(account: string, model: string, predict2RequestData: Predict2RequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).predictWithConfigV2Stream(account, model, predict2RequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} accountField 
     * @param {string} modelField 
     * @param {string} body 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public servletPredict(accountField: string, modelField: string, body: string, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).servletPredict(accountField, modelField, body, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {string} text 
     * @param {string} [voice] 
     * @param {'LINEAR16_PCM'} [audioEncoding] 
     * @param {number} [sampleRateHertz] 
     * @param {number} [chunkSizeKb] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public ttsStreamGet(account: string, model: string, text: string, voice?: string, audioEncoding?: 'LINEAR16_PCM', sampleRateHertz?: number, chunkSizeKb?: number, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).ttsStreamGet(account, model, text, voice, audioEncoding, sampleRateHertz, chunkSizeKb, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {TtsRequestData} ttsRequestData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessEndpointApi
     */
    public ttsStreamPost(account: string, model: string, ttsRequestData: TtsRequestData, MLP_API_KEY?: string, options?: any) {
        return ProcessEndpointApiFp(this.configuration).ttsStreamPost(account, model, ttsRequestData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ResourceGroupEndpointApi - axios parameter creator
 * @export
 */
export const ResourceGroupEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createResourceGroup.');
            }
            // verify required parameter 'createResourceGroupData' is not null or undefined
            if (createResourceGroupData === null || createResourceGroupData === undefined) {
                throw new RequiredError('createResourceGroupData','Required parameter createResourceGroupData was null or undefined when calling createResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createResourceGroupData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createResourceGroupData !== undefined ? createResourceGroupData : {}) : (createResourceGroupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteResourceGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployableResourceGroups(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getDeployableResourceGroups.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/deployable-resource-groups`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getLogsByResourceGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getLogsByResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/logs`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroupCapacity(account: string, groupName: string, serverName?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getLogsByResourceGroupCapacity.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getLogsByResourceGroupCapacity.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/capacity/logs`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serverName !== undefined) {
                localVarQueryParameter['serverName'] = serverName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroups(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getOwnedResourceGroups.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/owned-resource-groups`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroupsShortStatus(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getOwnedResourceGroupsShortStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/owned-resource-groups/short-status`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroup(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPageableLogsByResourceGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getPageableLogsByResourceGroup.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getPageableLogsByResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/logs/page`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroupCapacity(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, serverName?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getPageableLogsByResourceGroupCapacity.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getPageableLogsByResourceGroupCapacity.');
            }
            // verify required parameter 'minutes' is not null or undefined
            if (minutes === null || minutes === undefined) {
                throw new RequiredError('minutes','Required parameter minutes was null or undefined when calling getPageableLogsByResourceGroupCapacity.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/capacity/logs/page`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (minutes !== undefined) {
                localVarQueryParameter['minutes'] = minutes;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (serverName !== undefined) {
                localVarQueryParameter['serverName'] = serverName;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupActiveServices(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupActiveServices.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupActiveServices.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/active-services`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupCapacity(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupCapacity.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupCapacity.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/capacity`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupData(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupData.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupData.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices1(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupServices1.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupServices1.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/all-services`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedGroupTypes(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getSupportedGroupTypes.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-group-types`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {UpdateResourceGroupData} updateResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroup(account: string, groupName: string, updateResourceGroupData: UpdateResourceGroupData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateResourceGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling updateResourceGroup.');
            }
            // verify required parameter 'updateResourceGroupData' is not null or undefined
            if (updateResourceGroupData === null || updateResourceGroupData === undefined) {
                throw new RequiredError('updateResourceGroupData','Required parameter updateResourceGroupData was null or undefined when calling updateResourceGroup.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateResourceGroupData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateResourceGroupData !== undefined ? updateResourceGroupData : {}) : (updateResourceGroupData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceGroupEndpointApi - functional programming interface
 * @export
 */
export const ResourceGroupEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupEssentialData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).createResourceGroup(account, createResourceGroupData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).deleteResourceGroup(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployableResourceGroups(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupEssentialData>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getDeployableResourceGroups(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getLogsByResourceGroup(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroupCapacity(account: string, groupName: string, serverName?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getLogsByResourceGroupCapacity(account, groupName, serverName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroups(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupEssentialData>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getOwnedResourceGroups(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroupsShortStatus(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupShortStatusData>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getOwnedResourceGroupsShortStatus(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroup(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageStatLogData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getPageableLogsByResourceGroup(account, groupName, minutes, page, size, sort, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroupCapacity(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, serverName?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageStatLogData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getPageableLogsByResourceGroupCapacity(account, groupName, minutes, page, size, sort, serverName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupActiveServices(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServicesShortData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getResourceGroupActiveServices(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupCapacity(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupCapacity> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getResourceGroupCapacity(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupData(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupEssentialData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getResourceGroupData(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices1(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServicesData> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getResourceGroupServices1(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedGroupTypes(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).getSupportedGroupTypes(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {UpdateResourceGroupData} updateResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroup(account: string, groupName: string, updateResourceGroupData: UpdateResourceGroupData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourceGroupEndpointApiAxiosParamCreator(configuration).updateResourceGroup(account, groupName, updateResourceGroupData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResourceGroupEndpointApi - factory interface
 * @export
 */
export const ResourceGroupEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateResourceGroupData} createResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupEssentialData> {
            return ResourceGroupEndpointApiFp(configuration).createResourceGroup(account, createResourceGroupData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ResourceGroupEndpointApiFp(configuration).deleteResourceGroup(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployableResourceGroups(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupEssentialData>> {
            return ResourceGroupEndpointApiFp(configuration).getDeployableResourceGroups(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
            return ResourceGroupEndpointApiFp(configuration).getLogsByResourceGroup(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsByResourceGroupCapacity(account: string, groupName: string, serverName?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
            return ResourceGroupEndpointApiFp(configuration).getLogsByResourceGroupCapacity(account, groupName, serverName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroups(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupEssentialData>> {
            return ResourceGroupEndpointApiFp(configuration).getOwnedResourceGroups(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedResourceGroupsShortStatus(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupShortStatusData>> {
            return ResourceGroupEndpointApiFp(configuration).getOwnedResourceGroupsShortStatus(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroup(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PageStatLogData> {
            return ResourceGroupEndpointApiFp(configuration).getPageableLogsByResourceGroup(account, groupName, minutes, page, size, sort, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} minutes 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [serverName] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageableLogsByResourceGroupCapacity(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, serverName?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<PageStatLogData> {
            return ResourceGroupEndpointApiFp(configuration).getPageableLogsByResourceGroupCapacity(account, groupName, minutes, page, size, sort, serverName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupActiveServices(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServicesShortData> {
            return ResourceGroupEndpointApiFp(configuration).getResourceGroupActiveServices(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupCapacity(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupCapacity> {
            return ResourceGroupEndpointApiFp(configuration).getResourceGroupCapacity(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupData(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupEssentialData> {
            return ResourceGroupEndpointApiFp(configuration).getResourceGroupData(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupServices1(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServicesData> {
            return ResourceGroupEndpointApiFp(configuration).getResourceGroupServices1(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportedGroupTypes(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return ResourceGroupEndpointApiFp(configuration).getSupportedGroupTypes(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {UpdateResourceGroupData} updateResourceGroupData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroup(account: string, groupName: string, updateResourceGroupData: UpdateResourceGroupData, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ResourceGroupEndpointApiFp(configuration).updateResourceGroup(account, groupName, updateResourceGroupData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ResourceGroupEndpointApi - object-oriented interface
 * @export
 * @class ResourceGroupEndpointApi
 * @extends {BaseAPI}
 */
export class ResourceGroupEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {CreateResourceGroupData} createResourceGroupData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public createResourceGroup(account: string, createResourceGroupData: CreateResourceGroupData, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).createResourceGroup(account, createResourceGroupData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public deleteResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).deleteResourceGroup(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getDeployableResourceGroups(account: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getDeployableResourceGroups(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getLogsByResourceGroup(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getLogsByResourceGroup(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [serverName] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getLogsByResourceGroupCapacity(account: string, groupName: string, serverName?: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getLogsByResourceGroupCapacity(account, groupName, serverName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getOwnedResourceGroups(account: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getOwnedResourceGroups(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getOwnedResourceGroupsShortStatus(account: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getOwnedResourceGroupsShortStatus(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} minutes 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getPageableLogsByResourceGroup(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getPageableLogsByResourceGroup(account, groupName, minutes, page, size, sort, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} minutes 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [serverName] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getPageableLogsByResourceGroupCapacity(account: string, groupName: string, minutes: number, page?: number, size?: number, sort?: string, serverName?: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getPageableLogsByResourceGroupCapacity(account, groupName, minutes, page, size, sort, serverName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getResourceGroupActiveServices(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getResourceGroupActiveServices(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getResourceGroupCapacity(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getResourceGroupCapacity(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getResourceGroupData(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getResourceGroupData(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getResourceGroupServices1(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getResourceGroupServices1(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public getSupportedGroupTypes(account: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).getSupportedGroupTypes(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {UpdateResourceGroupData} updateResourceGroupData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupEndpointApi
     */
    public updateResourceGroup(account: string, groupName: string, updateResourceGroupData: UpdateResourceGroupData, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupEndpointApiFp(this.configuration).updateResourceGroup(account, groupName, updateResourceGroupData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ResourceGroupQuotaEndpointApi - axios parameter creator
 * @export
 */
export const ResourceGroupQuotaEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupQuota} createResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroupQuota(account: string, groupName: string, createResourceGroupQuota: CreateResourceGroupQuota, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling createResourceGroupQuota.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling createResourceGroupQuota.');
            }
            // verify required parameter 'createResourceGroupQuota' is not null or undefined
            if (createResourceGroupQuota === null || createResourceGroupQuota === undefined) {
                throw new RequiredError('createResourceGroupQuota','Required parameter createResourceGroupQuota was null or undefined when calling createResourceGroupQuota.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/quota`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createResourceGroupQuota !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createResourceGroupQuota !== undefined ? createResourceGroupQuota : {}) : (createResourceGroupQuota || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroupQuota(account: string, groupName: string, quotaId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteResourceGroupQuota.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteResourceGroupQuota.');
            }
            // verify required parameter 'quotaId' is not null or undefined
            if (quotaId === null || quotaId === undefined) {
                throw new RequiredError('quotaId','Required parameter quotaId was null or undefined when calling deleteResourceGroupQuota.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/quota/{quotaId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"quotaId"}}`, encodeURIComponent(String(quotaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupQuotas(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getResourceGroupQuotas.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getResourceGroupQuotas.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/quota`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {UpdateResourceGroupQuota} updateResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroupQuota(account: string, groupName: string, quotaId: number, updateResourceGroupQuota: UpdateResourceGroupQuota, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateResourceGroupQuota.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling updateResourceGroupQuota.');
            }
            // verify required parameter 'quotaId' is not null or undefined
            if (quotaId === null || quotaId === undefined) {
                throw new RequiredError('quotaId','Required parameter quotaId was null or undefined when calling updateResourceGroupQuota.');
            }
            // verify required parameter 'updateResourceGroupQuota' is not null or undefined
            if (updateResourceGroupQuota === null || updateResourceGroupQuota === undefined) {
                throw new RequiredError('updateResourceGroupQuota','Required parameter updateResourceGroupQuota was null or undefined when calling updateResourceGroupQuota.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/quota/{quotaId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"quotaId"}}`, encodeURIComponent(String(quotaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateResourceGroupQuota !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateResourceGroupQuota !== undefined ? updateResourceGroupQuota : {}) : (updateResourceGroupQuota || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceGroupQuotaEndpointApi - functional programming interface
 * @export
 */
export const ResourceGroupQuotaEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupQuota} createResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroupQuota(account: string, groupName: string, createResourceGroupQuota: CreateResourceGroupQuota, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SharedPoolQuota>> {
            const localVarAxiosArgs = ResourceGroupQuotaEndpointApiAxiosParamCreator(configuration).createResourceGroupQuota(account, groupName, createResourceGroupQuota, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroupQuota(account: string, groupName: string, quotaId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SharedPoolQuota>> {
            const localVarAxiosArgs = ResourceGroupQuotaEndpointApiAxiosParamCreator(configuration).deleteResourceGroupQuota(account, groupName, quotaId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupQuotas(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SharedPoolQuota>> {
            const localVarAxiosArgs = ResourceGroupQuotaEndpointApiAxiosParamCreator(configuration).getResourceGroupQuotas(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {UpdateResourceGroupQuota} updateResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroupQuota(account: string, groupName: string, quotaId: number, updateResourceGroupQuota: UpdateResourceGroupQuota, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SharedPoolQuota>> {
            const localVarAxiosArgs = ResourceGroupQuotaEndpointApiAxiosParamCreator(configuration).updateResourceGroupQuota(account, groupName, quotaId, updateResourceGroupQuota, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResourceGroupQuotaEndpointApi - factory interface
 * @export
 */
export const ResourceGroupQuotaEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupQuota} createResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResourceGroupQuota(account: string, groupName: string, createResourceGroupQuota: CreateResourceGroupQuota, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<SharedPoolQuota>> {
            return ResourceGroupQuotaEndpointApiFp(configuration).createResourceGroupQuota(account, groupName, createResourceGroupQuota, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResourceGroupQuota(account: string, groupName: string, quotaId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<SharedPoolQuota>> {
            return ResourceGroupQuotaEndpointApiFp(configuration).deleteResourceGroupQuota(account, groupName, quotaId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupQuotas(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<SharedPoolQuota>> {
            return ResourceGroupQuotaEndpointApiFp(configuration).getResourceGroupQuotas(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} quotaId 
         * @param {UpdateResourceGroupQuota} updateResourceGroupQuota 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResourceGroupQuota(account: string, groupName: string, quotaId: number, updateResourceGroupQuota: UpdateResourceGroupQuota, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<SharedPoolQuota>> {
            return ResourceGroupQuotaEndpointApiFp(configuration).updateResourceGroupQuota(account, groupName, quotaId, updateResourceGroupQuota, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ResourceGroupQuotaEndpointApi - object-oriented interface
 * @export
 * @class ResourceGroupQuotaEndpointApi
 * @extends {BaseAPI}
 */
export class ResourceGroupQuotaEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {CreateResourceGroupQuota} createResourceGroupQuota 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupQuotaEndpointApi
     */
    public createResourceGroupQuota(account: string, groupName: string, createResourceGroupQuota: CreateResourceGroupQuota, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupQuotaEndpointApiFp(this.configuration).createResourceGroupQuota(account, groupName, createResourceGroupQuota, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} quotaId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupQuotaEndpointApi
     */
    public deleteResourceGroupQuota(account: string, groupName: string, quotaId: number, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupQuotaEndpointApiFp(this.configuration).deleteResourceGroupQuota(account, groupName, quotaId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupQuotaEndpointApi
     */
    public getResourceGroupQuotas(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupQuotaEndpointApiFp(this.configuration).getResourceGroupQuotas(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} quotaId 
     * @param {UpdateResourceGroupQuota} updateResourceGroupQuota 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupQuotaEndpointApi
     */
    public updateResourceGroupQuota(account: string, groupName: string, quotaId: number, updateResourceGroupQuota: UpdateResourceGroupQuota, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupQuotaEndpointApiFp(this.configuration).updateResourceGroupQuota(account, groupName, quotaId, updateResourceGroupQuota, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * ResourceGroupServersEndpointApi - axios parameter creator
 * @export
 */
export const ResourceGroupServersEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerConfiguration(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling addServerConfiguration.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling addServerConfiguration.');
            }
            // verify required parameter 'createResourceGroupServer' is not null or undefined
            if (createResourceGroupServer === null || createResourceGroupServer === undefined) {
                throw new RequiredError('createResourceGroupServer','Required parameter createResourceGroupServer was null or undefined when calling addServerConfiguration.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createResourceGroupServer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createResourceGroupServer !== undefined ? createResourceGroupServer : {}) : (createResourceGroupServer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerTemplate(account: string, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling addServerTemplate.');
            }
            // verify required parameter 'createUpdateServerTemplateData' is not null or undefined
            if (createUpdateServerTemplateData === null || createUpdateServerTemplateData === undefined) {
                throw new RequiredError('createUpdateServerTemplateData','Required parameter createUpdateServerTemplateData was null or undefined when calling addServerTemplate.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/server/template`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createUpdateServerTemplateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUpdateServerTemplateData !== undefined ? createUpdateServerTemplateData : {}) : (createUpdateServerTemplateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {CopyResourceGroupServer} copyResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyServerConfiguration(account: string, groupName: string, serverId: number, copyResourceGroupServer: CopyResourceGroupServer, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling copyServerConfiguration.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling copyServerConfiguration.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling copyServerConfiguration.');
            }
            // verify required parameter 'copyResourceGroupServer' is not null or undefined
            if (copyResourceGroupServer === null || copyResourceGroupServer === undefined) {
                throw new RequiredError('copyResourceGroupServer','Required parameter copyResourceGroupServer was null or undefined when calling copyServerConfiguration.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/{serverId}/copy`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof copyResourceGroupServer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(copyResourceGroupServer !== undefined ? copyResourceGroupServer : {}) : (copyResourceGroupServer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerConfiguration(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteServerConfiguration.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteServerConfiguration.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling deleteServerConfiguration.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/{serverId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerTemplate(account: string, templateId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling deleteServerTemplate.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling deleteServerTemplate.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/server/template/{templateId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnTemplates(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getOwnTemplates.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/server/template`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerData(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getServerData.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getServerData.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getServerData.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/{serverId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStatus(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getServerStatus.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getServerStatus.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getServerStatus.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/{serverId}/status`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersData(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getServersData.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getServersData.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersStatutes(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getServersStatutes.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getServersStatutes.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/status`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(account: string, groupName: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getTemplates.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getTemplates.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/resource-groups/{groupName}/server/template`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"groupName"}}`, encodeURIComponent(String(groupName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverHeartbeat(account: string, serverId: number, MLP_API_KEY?: string, body?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling serverHeartbeat.');
            }
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling serverHeartbeat.');
            }
            const localVarPath = `/api/mlpcore/server-heartbeat`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (serverId !== undefined) {
                localVarQueryParameter['serverId'] = serverId;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServerTemplate(account: string, templateId: number, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling updateServerTemplate.');
            }
            // verify required parameter 'templateId' is not null or undefined
            if (templateId === null || templateId === undefined) {
                throw new RequiredError('templateId','Required parameter templateId was null or undefined when calling updateServerTemplate.');
            }
            // verify required parameter 'createUpdateServerTemplateData' is not null or undefined
            if (createUpdateServerTemplateData === null || createUpdateServerTemplateData === undefined) {
                throw new RequiredError('createUpdateServerTemplateData','Required parameter createUpdateServerTemplateData was null or undefined when calling updateServerTemplate.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/server/template/{templateId}`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof createUpdateServerTemplateData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUpdateServerTemplateData !== undefined ? createUpdateServerTemplateData : {}) : (createUpdateServerTemplateData || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourceGroupServersEndpointApi - functional programming interface
 * @export
 */
export const ResourceGroupServersEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerConfiguration(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServerData> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).addServerConfiguration(account, groupName, createResourceGroupServer, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerTemplate(account: string, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerTemplateData> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).addServerTemplate(account, createUpdateServerTemplateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {CopyResourceGroupServer} copyResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyServerConfiguration(account: string, groupName: string, serverId: number, copyResourceGroupServer: CopyResourceGroupServer, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServerData> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).copyServerConfiguration(account, groupName, serverId, copyResourceGroupServer, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerConfiguration(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).deleteServerConfiguration(account, groupName, serverId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerTemplate(account: string, templateId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).deleteServerTemplate(account, templateId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnTemplates(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServerTemplateData>> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getOwnTemplates(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerData(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServerData> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getServerData(account, groupName, serverId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStatus(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceGroupServerDataWithStatus> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getServerStatus(account, groupName, serverId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersData(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupServerData>> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getServersData(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersStatutes(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupServerDataWithStatus>> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getServersStatutes(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(account: string, groupName: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServerTemplateData>> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).getTemplates(account, groupName, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverHeartbeat(account: string, serverId: number, MLP_API_KEY?: string, body?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).serverHeartbeat(account, serverId, MLP_API_KEY, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServerTemplate(account: string, templateId: number, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerTemplateData> {
            const localVarAxiosArgs = ResourceGroupServersEndpointApiAxiosParamCreator(configuration).updateServerTemplate(account, templateId, createUpdateServerTemplateData, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResourceGroupServersEndpointApi - factory interface
 * @export
 */
export const ResourceGroupServersEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {CreateResourceGroupServer} createResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerConfiguration(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServerData> {
            return ResourceGroupServersEndpointApiFp(configuration).addServerConfiguration(account, groupName, createResourceGroupServer, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServerTemplate(account: string, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any): AxiosPromise<ServerTemplateData> {
            return ResourceGroupServersEndpointApiFp(configuration).addServerTemplate(account, createUpdateServerTemplateData, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {CopyResourceGroupServer} copyResourceGroupServer 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyServerConfiguration(account: string, groupName: string, serverId: number, copyResourceGroupServer: CopyResourceGroupServer, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServerData> {
            return ResourceGroupServersEndpointApiFp(configuration).copyServerConfiguration(account, groupName, serverId, copyResourceGroupServer, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerConfiguration(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ResourceGroupServersEndpointApiFp(configuration).deleteServerConfiguration(account, groupName, serverId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerTemplate(account: string, templateId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return ResourceGroupServersEndpointApiFp(configuration).deleteServerTemplate(account, templateId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnTemplates(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ServerTemplateData>> {
            return ResourceGroupServersEndpointApiFp(configuration).getOwnTemplates(account, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerData(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServerData> {
            return ResourceGroupServersEndpointApiFp(configuration).getServerData(account, groupName, serverId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerStatus(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<ResourceGroupServerDataWithStatus> {
            return ResourceGroupServersEndpointApiFp(configuration).getServerStatus(account, groupName, serverId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersData(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupServerData>> {
            return ResourceGroupServersEndpointApiFp(configuration).getServersData(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServersStatutes(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupServerDataWithStatus>> {
            return ResourceGroupServersEndpointApiFp(configuration).getServersStatutes(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} groupName 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(account: string, groupName: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ServerTemplateData>> {
            return ResourceGroupServersEndpointApiFp(configuration).getTemplates(account, groupName, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} serverId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serverHeartbeat(account: string, serverId: number, MLP_API_KEY?: string, body?: string, options?: any): AxiosPromise<void> {
            return ResourceGroupServersEndpointApiFp(configuration).serverHeartbeat(account, serverId, MLP_API_KEY, body, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {number} templateId 
         * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServerTemplate(account: string, templateId: number, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any): AxiosPromise<ServerTemplateData> {
            return ResourceGroupServersEndpointApiFp(configuration).updateServerTemplate(account, templateId, createUpdateServerTemplateData, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * ResourceGroupServersEndpointApi - object-oriented interface
 * @export
 * @class ResourceGroupServersEndpointApi
 * @extends {BaseAPI}
 */
export class ResourceGroupServersEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {CreateResourceGroupServer} createResourceGroupServer 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public addServerConfiguration(account: string, groupName: string, createResourceGroupServer: CreateResourceGroupServer, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).addServerConfiguration(account, groupName, createResourceGroupServer, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public addServerTemplate(account: string, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).addServerTemplate(account, createUpdateServerTemplateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} serverId 
     * @param {CopyResourceGroupServer} copyResourceGroupServer 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public copyServerConfiguration(account: string, groupName: string, serverId: number, copyResourceGroupServer: CopyResourceGroupServer, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).copyServerConfiguration(account, groupName, serverId, copyResourceGroupServer, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} serverId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public deleteServerConfiguration(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).deleteServerConfiguration(account, groupName, serverId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} templateId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public deleteServerTemplate(account: string, templateId: number, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).deleteServerTemplate(account, templateId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getOwnTemplates(account: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getOwnTemplates(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} serverId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getServerData(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getServerData(account, groupName, serverId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {number} serverId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getServerStatus(account: string, groupName: string, serverId: number, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getServerStatus(account, groupName, serverId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getServersData(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getServersData(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getServersStatutes(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getServersStatutes(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} groupName 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public getTemplates(account: string, groupName: string, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).getTemplates(account, groupName, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} serverId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public serverHeartbeat(account: string, serverId: number, MLP_API_KEY?: string, body?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).serverHeartbeat(account, serverId, MLP_API_KEY, body, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {number} templateId 
     * @param {CreateUpdateServerTemplateData} createUpdateServerTemplateData 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourceGroupServersEndpointApi
     */
    public updateServerTemplate(account: string, templateId: number, createUpdateServerTemplateData: CreateUpdateServerTemplateData, MLP_API_KEY?: string, options?: any) {
        return ResourceGroupServersEndpointApiFp(this.configuration).updateServerTemplate(account, templateId, createUpdateServerTemplateData, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * SpecsEndpointApi - axios parameter creator
 * @export
 */
export const SpecsEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs(apiFile: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'apiFile' is not null or undefined
            if (apiFile === null || apiFile === undefined) {
                throw new RequiredError('apiFile','Required parameter apiFile was null or undefined when calling specs.');
            }
            const localVarPath = `/specs/{api-file}`
                .replace(`{${"api-file"}}`, encodeURIComponent(String(apiFile)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecsEndpointApi - functional programming interface
 * @export
 */
export const SpecsEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs(apiFile: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SpecsEndpointApiAxiosParamCreator(configuration).specs(apiFile, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SpecsEndpointApi - factory interface
 * @export
 */
export const SpecsEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs(apiFile: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return SpecsEndpointApiFp(configuration).specs(apiFile, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * SpecsEndpointApi - object-oriented interface
 * @export
 * @class SpecsEndpointApi
 * @extends {BaseAPI}
 */
export class SpecsEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} apiFile 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecsEndpointApi
     */
    public specs(apiFile: string, MLP_API_KEY?: string, options?: any) {
        return SpecsEndpointApiFp(this.configuration).specs(apiFile, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * StatLogEndpointApi - axios parameter creator
 * @export
 */
export const StatLogEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstanceConnected(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling addInstanceConnected.');
            }
            // verify required parameter 'model' is not null or undefined
            if (model === null || model === undefined) {
                throw new RequiredError('model','Required parameter model was null or undefined when calling addInstanceConnected.');
            }
            // verify required parameter 'instanceId' is not null or undefined
            if (instanceId === null || instanceId === undefined) {
                throw new RequiredError('instanceId','Required parameter instanceId was null or undefined when calling addInstanceConnected.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/model/{model}/instance/{instanceId}/stat-log`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)))
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs1(account: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling listJobs1.');
            }
            const localVarPath = `/api/mlpcore/account/{account}/stat-log`
                .replace(`{${"account"}}`, encodeURIComponent(String(account)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatLogEndpointApi - functional programming interface
 * @export
 */
export const StatLogEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstanceConnected(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = StatLogEndpointApiAxiosParamCreator(configuration).addInstanceConnected(account, model, instanceId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs1(account: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatLogData>> {
            const localVarAxiosArgs = StatLogEndpointApiAxiosParamCreator(configuration).listJobs1(account, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StatLogEndpointApi - factory interface
 * @export
 */
export const StatLogEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} model Model id or model name
         * @param {number} instanceId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInstanceConnected(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return StatLogEndpointApiFp(configuration).addInstanceConnected(account, model, instanceId, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} account Account id or account name
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs1(account: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<StatLogData>> {
            return StatLogEndpointApiFp(configuration).listJobs1(account, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * StatLogEndpointApi - object-oriented interface
 * @export
 * @class StatLogEndpointApi
 * @extends {BaseAPI}
 */
export class StatLogEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} model Model id or model name
     * @param {number} instanceId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatLogEndpointApi
     */
    public addInstanceConnected(account: string, model: string, instanceId: number, MLP_API_KEY?: string, options?: any) {
        return StatLogEndpointApiFp(this.configuration).addInstanceConnected(account, model, instanceId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} account Account id or account name
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatLogEndpointApi
     */
    public listJobs1(account: string, MLP_API_KEY?: string, options?: any) {
        return StatLogEndpointApiFp(this.configuration).listJobs1(account, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * SystemConfigEndpointApi - axios parameter creator
 * @export
 */
export const SystemConfigEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptchaConfig(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/captcha`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetDataTypes(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/dataset-data-type`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontendSettings(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/frontend-settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefaults(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/model-defaults`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupsForAccount(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/resource-groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTypes(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/system-config/task-types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemConfigEndpointApi - functional programming interface
 * @export
 */
export const SystemConfigEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptchaConfig(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptchaData> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getCaptchaConfig(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetDataTypes(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getDatasetDataTypes(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontendSettings(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendSettings> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getFrontendSettings(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefaults(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelDefaults> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getModelDefaults(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupsForAccount(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceGroupData>> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getResourceGroupsForAccount(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTypes(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskTypeData>> {
            const localVarAxiosArgs = SystemConfigEndpointApiAxiosParamCreator(configuration).getTaskTypes(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemConfigEndpointApi - factory interface
 * @export
 */
export const SystemConfigEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptchaConfig(MLP_API_KEY?: string, options?: any): AxiosPromise<CaptchaData> {
            return SystemConfigEndpointApiFp(configuration).getCaptchaConfig(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetDataTypes(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<string>> {
            return SystemConfigEndpointApiFp(configuration).getDatasetDataTypes(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontendSettings(MLP_API_KEY?: string, options?: any): AxiosPromise<FrontendSettings> {
            return SystemConfigEndpointApiFp(configuration).getFrontendSettings(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDefaults(MLP_API_KEY?: string, options?: any): AxiosPromise<ModelDefaults> {
            return SystemConfigEndpointApiFp(configuration).getModelDefaults(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourceGroupsForAccount(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ResourceGroupData>> {
            return SystemConfigEndpointApiFp(configuration).getResourceGroupsForAccount(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskTypes(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<TaskTypeData>> {
            return SystemConfigEndpointApiFp(configuration).getTaskTypes(MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * SystemConfigEndpointApi - object-oriented interface
 * @export
 * @class SystemConfigEndpointApi
 * @extends {BaseAPI}
 */
export class SystemConfigEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getCaptchaConfig(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getCaptchaConfig(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getDatasetDataTypes(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getDatasetDataTypes(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getFrontendSettings(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getFrontendSettings(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getModelDefaults(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getModelDefaults(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getResourceGroupsForAccount(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getResourceGroupsForAccount(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemConfigEndpointApi
     */
    public getTaskTypes(MLP_API_KEY?: string, options?: any) {
        return SystemConfigEndpointApiFp(this.configuration).getTaskTypes(MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * SystemEndpointApi - axios parameter creator
 * @export
 */
export const SystemEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckForTests(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/health/test`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHistory(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/health/history`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hzInfo(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/hz`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs1(apiFile: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'apiFile' is not null or undefined
            if (apiFile === null || apiFile === undefined) {
                throw new RequiredError('apiFile','Required parameter apiFile was null or undefined when calling specs1.');
            }
            const localVarPath = `/api/mlpcore/specs/{api-file}`
                .replace(`{${"api-file"}}`, encodeURIComponent(String(apiFile)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemEndpointApi - functional programming interface
 * @export
 */
export const SystemEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).healthCheck(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckForTests(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).healthCheckForTests(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHistory(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckHistoryResult> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).healthCheckHistory(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hzInfo(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<HzClusterInfo> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).hzInfo(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs1(apiFile: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).specs1(apiFile, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = SystemEndpointApiAxiosParamCreator(configuration).version(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemEndpointApi - factory interface
 * @export
 */
export const SystemEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(MLP_API_KEY?: string, options?: any): AxiosPromise<HealthCheckResult> {
            return SystemEndpointApiFp(configuration).healthCheck(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckForTests(MLP_API_KEY?: string, options?: any): AxiosPromise<HealthCheckResult> {
            return SystemEndpointApiFp(configuration).healthCheckForTests(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckHistory(MLP_API_KEY?: string, options?: any): AxiosPromise<HealthCheckHistoryResult> {
            return SystemEndpointApiFp(configuration).healthCheckHistory(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hzInfo(MLP_API_KEY?: string, options?: any): AxiosPromise<HzClusterInfo> {
            return SystemEndpointApiFp(configuration).hzInfo(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} apiFile 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specs1(apiFile: string, MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return SystemEndpointApiFp(configuration).specs1(apiFile, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        version(MLP_API_KEY?: string, options?: any): AxiosPromise<string> {
            return SystemEndpointApiFp(configuration).version(MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * SystemEndpointApi - object-oriented interface
 * @export
 * @class SystemEndpointApi
 * @extends {BaseAPI}
 */
export class SystemEndpointApi extends BaseAPI {
    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public healthCheck(MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).healthCheck(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public healthCheckForTests(MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).healthCheckForTests(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public healthCheckHistory(MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).healthCheckHistory(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public hzInfo(MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).hzInfo(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} apiFile 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public specs1(apiFile: string, MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).specs1(apiFile, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEndpointApi
     */
    public version(MLP_API_KEY?: string, options?: any) {
        return SystemEndpointApiFp(this.configuration).version(MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


/**
 * TaskEndpointApi - axios parameter creator
 * @export
 */
export const TaskEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/task/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }

            if (serverId !== undefined) {
                localVarQueryParameter['serverId'] = serverId;
            }

            if (excludeStatus !== undefined) {
                localVarQueryParameter['excludeStatus'] = excludeStatus;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/task`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (instanceId !== undefined) {
                localVarQueryParameter['instanceId'] = instanceId;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }

            if (serverId !== undefined) {
                localVarQueryParameter['serverId'] = serverId;
            }

            if (excludeStatus !== undefined) {
                localVarQueryParameter['excludeStatus'] = excludeStatus;
            }

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTasksView(MLP_API_KEY?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/mlpcore/task/short`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} taskId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopJob(taskId: string, MLP_API_KEY?: string, options: any = {}): RequestArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling stopJob.');
            }
            const localVarPath = `/api/mlpcore/task/{taskId}/stop`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (MLP_API_KEY !== undefined && MLP_API_KEY !== null) {
                localVarHeaderParameter['MLP-API-KEY'] = String(MLP_API_KEY);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskEndpointApi - functional programming interface
 * @export
 */
export const TaskEndpointApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobStatusDataV2>> {
            const localVarAxiosArgs = TaskEndpointApiAxiosParamCreator(configuration).listAccountJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobStatusDataV2>> {
            const localVarAxiosArgs = TaskEndpointApiAxiosParamCreator(configuration).listJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTasksView(MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShortJobView>> {
            const localVarAxiosArgs = TaskEndpointApiAxiosParamCreator(configuration).shortTasksView(MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} taskId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopJob(taskId: string, MLP_API_KEY?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = TaskEndpointApiAxiosParamCreator(configuration).stopJob(taskId, MLP_API_KEY, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TaskEndpointApi - factory interface
 * @export
 */
export const TaskEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<JobStatusDataV2>> {
            return TaskEndpointApiFp(configuration).listAccountJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} [account] 
         * @param {number} [model] 
         * @param {number} [instanceId] 
         * @param {string} [groupName] 
         * @param {number} [serverId] 
         * @param {string} [excludeStatus] 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any): AxiosPromise<Array<JobStatusDataV2>> {
            return TaskEndpointApiFp(configuration).listJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortTasksView(MLP_API_KEY?: string, options?: any): AxiosPromise<Array<ShortJobView>> {
            return TaskEndpointApiFp(configuration).shortTasksView(MLP_API_KEY, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} taskId 
         * @param {string} [MLP_API_KEY] token to use instead of a session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopJob(taskId: string, MLP_API_KEY?: string, options?: any): AxiosPromise<void> {
            return TaskEndpointApiFp(configuration).stopJob(taskId, MLP_API_KEY, options)(axios, basePath);
        },
    };
};

/**
 * TaskEndpointApi - object-oriented interface
 * @export
 * @class TaskEndpointApi
 * @extends {BaseAPI}
 */
export class TaskEndpointApi extends BaseAPI {
    /**
     * 
     * @param {number} [account] 
     * @param {number} [model] 
     * @param {number} [instanceId] 
     * @param {string} [groupName] 
     * @param {number} [serverId] 
     * @param {string} [excludeStatus] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskEndpointApi
     */
    public listAccountJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any) {
        return TaskEndpointApiFp(this.configuration).listAccountJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} [account] 
     * @param {number} [model] 
     * @param {number} [instanceId] 
     * @param {string} [groupName] 
     * @param {number} [serverId] 
     * @param {string} [excludeStatus] 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskEndpointApi
     */
    public listJobs(account?: number, model?: number, instanceId?: number, groupName?: string, serverId?: number, excludeStatus?: string, MLP_API_KEY?: string, options?: any) {
        return TaskEndpointApiFp(this.configuration).listJobs(account, model, instanceId, groupName, serverId, excludeStatus, MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskEndpointApi
     */
    public shortTasksView(MLP_API_KEY?: string, options?: any) {
        return TaskEndpointApiFp(this.configuration).shortTasksView(MLP_API_KEY, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} taskId 
     * @param {string} [MLP_API_KEY] token to use instead of a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskEndpointApi
     */
    public stopJob(taskId: string, MLP_API_KEY?: string, options?: any) {
        return TaskEndpointApiFp(this.configuration).stopJob(taskId, MLP_API_KEY, options)(this.axios, this.basePath);
    }

}


